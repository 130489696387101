import React from "react";
import { Button, ButtonGroup, InputGroup, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  addOutput,
  deleteOutput,
  updateOutput,
  setActiveOutput,
  duplicateOutput,
  resetActiveOutput,
  updateScreenData,
  splitScreen,
  undoSplit,
} from "../../actions/index"; // Update this path
import { generateUniqueId, defaultOutput } from "../../reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMaximize,
  faCopy,
  faDeleteLeft,
  faRepeat,
  faSquarePlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { updateOutputImage } from "../../actions";

function OutputControls({ isCanvasScaled, toggleCanvasScaling }) {
  const dispatch = useDispatch();
  const outputs = useSelector((state) => state.project.outputs);
  const screens = useSelector((state) => state.project.screens);
  const activeOutputId = useSelector((state) => state.project.activeOutputId);
  const activeOutput = outputs.find((output) => output.id === activeOutputId);

  const [splitDirection, setSplitDirection] = useState("horizontal");
  const [splitPosition, setSplitPosition] = useState("");

  // Event handlers for adding, updating outputs, assigning screens to outputs, etc.
  const handleAddOutput = () => {
    const newOutputId = generateUniqueId();
    // Get the number of existing outputs
    const outputCount = outputs.length;

    // Calculate the new output name (assuming outputCount starts at 0)
    const newOutputName = `Output ${String.fromCharCode(65 + outputCount)}`; // 65 is the char code for 'A'

    //console.log("adding screen with id : ", newScreenId);
    const newOutput = {
      ...defaultOutput,
      id: newOutputId,
      name: newOutputName,
    }; // Ensure defaultScreen structure and generateUniqueId are defined
    dispatch(addOutput(newOutput));
    //console.log("attempting to set this screen to be active : ", newScreenId);
    dispatch(setActiveOutput(newOutputId)); // set newly created screen to be active
  };

  // Example for handleScreenOffsetChange
  const handleScreenOffsetChange = (screenId, offsetType, newValue) => {
    const parsedValue = parseFloat(newValue) || 0; // Ensures a valid number or defaults to 0
    dispatch(updateScreenData(screenId, { [offsetType]: parsedValue }));
    // Optionally trigger redraw of the output raster
  };


  // Example for handleReassignScreen
  const handleReassignScreen = (screenId, name, value) => {
    dispatch(updateScreenData(screenId, { [name]: value }));
    // Optionally trigger update to list of outputs and screens, and redraw the output raster
  };

  // Handle changing the split direction (horizontal or vertical).
  const toggleSplitDirection = () => {
    setSplitDirection((prev) =>
      prev === "horizontal" ? "vertical" : "horizontal"
    );
  };

  // Execute a split operation using the provided direction and position.
  const handleSplitScreen = (screenId) => {
    const position = parseFloat(splitPosition);
    if (!isNaN(position)) {
      dispatch(splitScreen(screenId, splitDirection, position));
    }
  };

  // Undo a specific split based on a screen/sub-screen ID.
  const handleUndoSplit = (screenId) => {
    dispatch(undoSplit(screenId));
  };

  const handleSetActiveOutput = (id) => {
    dispatch(setActiveOutput(id));
  };

  const handleDeleteOutput = (outputId) => {
    dispatch(deleteOutput(outputId));
  };
  const handleDuplicateOutput = (output) => {
    dispatch(duplicateOutput(output));
  };

  const handleResetOutput = (outputId) => {
    dispatch(resetActiveOutput(outputId));
  };

  const handleInputChange = (outputId, event) => {
    const { name, value } = event.target;
    let inputValue;

    // Check if the input name indicates it's a color value, which should always start with '#'
    if (name.startsWith("color")) {
      inputValue = value; // Directly use the value for color inputs
    } else {
      // Attempt to parse the value as a float for numeric inputs
      const parsedValue = parseFloat(value);
      // Use the parsed value if it's a valid number; otherwise, keep the original value for text inputs
      inputValue =
        !isNaN(parsedValue) && value.trim() !== "" ? parsedValue : value;
    }

    // Prepare the data to be updated.
    let updatedData = { [name]: inputValue };

    //console.log(updatedData);
    dispatch(updateOutput(outputId, updatedData)); // Ensure this action creator name matches your implementation
  };

  const handleSubScreenOffsetChange = (
    screenId,
    subScreenId,
    offsetType,
    newValue
  ) => {
    // Find the screen and sub-screen
    const screen = screens.find((screen) => screen.id === screenId);
    if (!screen) return;
    const subScreenIndex = screen.subScreens.findIndex(
      (subScreen) => subScreen.id === subScreenId
    );
    if (subScreenIndex === -1) return;

    // Update the sub-screen's offset
    const updatedSubScreens = [...screen.subScreens];
    updatedSubScreens[subScreenIndex] = {
      ...updatedSubScreens[subScreenIndex],
      [offsetType]: parseFloat(newValue) || 0,
    };
    //console.log("updated sub screens with id : ",subScreenId,updatedSubScreens);
    // Update the state with the new sub-screen data
    dispatch(updateScreenData(screenId, { subScreens: updatedSubScreens }));
  };

  const handleSubScreenAssignOutput = (screenId, subScreenId, outputId) => {
    //console.log("handling sub screen assign output : ",screenId,subScreenId,outputId);
    // Find the screen and sub-screen
    const screen = screens.find((screen) => screen.id === screenId);
    if (!screen) return;
    //console.log("screen : ", screen);
    const subScreenIndex = screen.subScreens.findIndex(
      (subScreen) => subScreen.id === subScreenId
    );
    if (subScreenIndex === -1) return;
    //console.log("subscreen index : ", subScreenIndex);

    // Update the sub-screen's assigned output
    const updatedSubScreens = [...screen.subScreens];
    updatedSubScreens[subScreenIndex] = {
      ...updatedSubScreens[subScreenIndex],
      assignedOutput: outputId,
    };
    //console.log("updated sub screens with id : ",subScreenId, updatedSubScreens);

    // Update the state with the new sub-screen data
    dispatch(updateScreenData(screenId, { subScreens: updatedSubScreens }));
  };

  const handleSubScreenUndoSplit = (screenId, subScreenId) => {
    // Find the screen and sub-screen

    /* const screen = screens.find((screen) => screen.id === screenId);
    if (!screen) return;
    const subScreenIndex = screen.subScreens.findIndex((subScreen) => subScreen.id === subScreenId);
    if (subScreenIndex === -1) return;
    console.log("subscreen index : ", subScreenIndex);
    // Remove the sub-screen from the list of sub-screens
    const updatedSubScreens = screen.subScreens.filter((_, index) => index !== subScreenIndex);
    console.log("updated subscreens : ", updatedSubScreens); */

    // Update the state with the new sub-screen data
    dispatch(undoSplit(subScreenId, screenId));
  };

  const drawScreens = (screenList, ctx, activeOutputId) => {
    return Promise.all(
      screenList.map((screen) => {
        if (screen.subScreens && screen.subScreens.length > 1) {
          return Promise.all(
            screen.subScreens.map((subScreen, i) => {
              if (subScreen.assignedOutput === activeOutputId) {
                let x = subScreen.offsetX;
                let y = subScreen.offsetY;
                let sourceX = 0;
                let sourceY = 0;

                if (i > 0) {
                  if (screen.subScreens[i - 1].direction === "horizontal") {
                    sourceY = screen.subScreens[i - 1].position;
                  } else if (
                    screen.subScreens[i - 1].direction === "vertical"
                  ) {
                    sourceX = screen.subScreens[i - 1].position;
                  }
                }
                return drawImage(
                  ctx,
                  screen.image,
                  subScreen.sourceX,
                  subScreen.sourceY,
                  subScreen.width,
                  subScreen.height,
                  x,
                  y,
                  subScreen.width,
                  subScreen.height
                );
              }
              return Promise.resolve();
            })
          );
        } else {
          return drawImage(
            ctx,
            screen.image,
            0,
            0,
            screen.panelWidth * screen.mapWidth,
            screen.panelHeight * screen.mapHeight,
            screen.offsetX,
            screen.offsetY,
            screen.panelWidth * screen.mapWidth,
            screen.panelHeight * screen.mapHeight
          );
        }
      })
    );
  };
  const drawImage = (
    ctx,
    image,
    sx,
    sy,
    swidth,
    sheight,
    dx,
    dy,
    dwidth,
    dheight
  ) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, sx, sy, swidth, sheight, dx, dy, dwidth, dheight);
        resolve();
      };
      img.src = `data:image/png;base64,${image}`;
    });
  };

  useEffect(() => {
    const renderOutputs = async () => {
      for (const output of outputs) {
        let activeOutput = output;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = activeOutput.width;
        canvas.height = activeOutput.height;

        ctx.fillStyle = activeOutput.bgcolor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const outputScreens = screens.filter(
          (screen) =>
            screen.assignedOutput === activeOutput.id ||
            (screen.subScreens &&
              screen.subScreens.some(
                (subScreen) => subScreen.assignedOutput === activeOutput.id
              ))
        );

        for (const screen of outputScreens) {
          await drawScreens([screen], ctx, activeOutput.id);
        }

        const dataURL = canvas.toDataURL("image/png");
        dispatch(updateOutputImage(activeOutput.id, dataURL));
      }
    };

    renderOutputs();
  }, [screens]);

  return (
    <div>
      {/* Outputs Section */}
      {/* <div className="outputs-section mb-3">
        <div className="row gy-2 gx-3 align-items-center">
          {outputs.map((output) => (
            <div key={output.id} className={`col-4 output-thumbnail`}>
              <img
                src={output.image}
                alt={output.name}
                className={`img-thumbnail ${
                  activeOutputId === output.id ? "bg-primary" : ""
                }`}
              />
              <input
                name="name"
                type="text"
                value={output.name}
                className="form-control form-control-sm mt-1"
                placeholder="Output Name"
                onChange={(e) => handleInputChange(output.id, e)}
              />
              <div className="d-flex justify-content-between mt-1">
                <ButtonGroup className="">
                  <Button
                    variant="outline-primary"
                    className={`btn-sm ${
                      activeOutputId === output.id
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => handleSetActiveOutput(output.id)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    variant="secondary"
                    className="btn-sm"
                    onClick={() => handleDuplicateOutput(output)}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                  <Button
                    variant="outline-danger"
                    className={`btn-sm ${
                      activeOutputId === output.id
                        ? "btn-outline-danger"
                        : "disabled"
                    }`}
                    onClick={() => handleResetOutput(output.id)}
                  >
                    <FontAwesomeIcon icon={faRepeat} />
                  </Button>
                  <Button
                    variant="danger"
                    className={`btn-sm ${
                      activeOutputId === output.id
                        ? "btn-secondary disabled"
                        : ""
                    }`}
                    onClick={() => handleDeleteOutput(output.id)}
                  >
                    <FontAwesomeIcon icon={faDeleteLeft} />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          ))}
          <div className="col-4">
            <button
              className="btn btn-lg btn-success"
              type="button"
              onClick={handleAddOutput}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
            </button>
          </div>
        </div>
      </div> */}

      <div id="row">
        <div id="col">
          <FontAwesomeIcon icon={faMaximize} />
          &nbsp;Output Raster Size
        </div>
      </div>
      <form className="row gy-2 gx-3 mb-3 align-items-center">
        <div className="col-sm-6">
          <label className="visually-hidden" htmlFor="autoSizingInputGroup">
            Output Width
          </label>
          <div className="input-group input-group-sm">
            <div className="input-group-text">Width</div>
            <input
              className="form-control form-control-sm"
              type="number"
              name="width"
              min="1"
              step="1"
              value={activeOutput ? activeOutput.width : ""}
              onChange={(e) => handleInputChange(activeOutputId, e)}
              placeholder="in pixels"
            />
          </div>
        </div>
        <div className="col-sm-6">
          <label className="visually-hidden" htmlFor="autoSizingInputGroup">
            Output Height
          </label>
          <div className="input-group input-group-sm">
            <div className="input-group-text">Height</div>
            <input
              className="form-control form-control-sm"
              type="number"
              name="height"
              min="1"
              step="1"
              value={activeOutput ? activeOutput.height : ""}
              onChange={(e) => handleInputChange(activeOutputId, e)}
              placeholder="in pixels"
            />
          </div>
        </div>
      </form>

      {outputs.map((output) => (
        <div key={output.id}>
          <div className="input-group input-group-sm mb-3">
            <button
              className={`btn ${
                activeOutputId === output.id
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              type="button"
              key={output.id}
              onClick={() => handleSetActiveOutput(output.id)}
            >
              Activate
            </button>
            <input
              name="name"
              type="text"
              value={output.name}
              className="form-control"
              placeholder=""
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              onChange={(e) => handleInputChange(output.id, e)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => handleDuplicateOutput(output)}
            >
              Duplicate
            </button>
            <button
              className={`btn ${
                activeOutputId === output.id
                  ? "btn-outline-danger"
                  : "btn-secondary disabled"
              }`}
              type="button"
              onClick={() => handleResetOutput(output.id)}
            >
              Reset
            </button>
            <button
              className={`btn ${
                activeOutputId === output.id
                  ? "btn-secondary disabled"
                  : "btn-danger"
              }`}
              type="button"
              onClick={() => handleDeleteOutput(output.id)}
            >
              Delete
            </button>
          </div>
          {/* List each screen assigned to this output */}
          {screens
            .filter(
              (screen) =>
                screen.assignedOutput === output.id ||
                (screen.subScreens &&
                  screen.subScreens.some(
                    (subScreen) => subScreen.assignedOutput === output.id
                  ))
            )
            .map((screen) => (
              <div key={screen.id} className="screen-info">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-10">Screen: {screen.name}</div>
                </div>
                {/* hide single screen controls if subScreens exist */}
                {screen.subScreens && screen.subScreens.length < 1 && (
                  <>
                    <form className="row gy-2 gx-3 mb-1 align-items-center">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          Screen Width
                        </label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-text">Width</div>
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            name="width"
                            value={screen.mapWidth * screen.panelWidth}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          Screen Height
                        </label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-text">Height</div>
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            name="height"
                            value={
                              screen.mapHeight * screen.panelHeight +
                              (screen.halfRow ? screen.panelHeight / 2 : 0)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </form>
                    <form className="row gy-2 gx-3 mb-1 align-items-center">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          X Offset
                        </label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-text">X Offset</div>
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            name="offsetX"
                            min="0"
                            step="1"
                            value={screen.offsetX}
                            onChange={(e) =>
                              handleScreenOffsetChange(
                                screen.id,
                                "offsetX",
                                e.target.value
                              )
                            }
                            placeholder="Offset X"
                          />
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingInputGroup"
                        >
                          Y Offset
                        </label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-text">Y Offset</div>
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            name="offsetY"
                            min="0"
                            step="1"
                            value={screen.offsetY}
                            onChange={(e) =>
                              handleScreenOffsetChange(
                                screen.id,
                                "offsetY",
                                e.target.value
                              )
                            }
                            placeholder="Offset Y"
                          />
                        </div>
                      </div>
                    </form>
                    <form className="row gy-2 gx-3 mb-3 align-items-center">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-10">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">
                            Assigned Output
                          </span>

                          <select
                            className="form-select form-select-sm"
                            aria-label="Small select example"
                            value={screen.assignedOutput}
                            onChange={(e) =>
                              handleReassignScreen(
                                screen.id,
                                "assignedOutput",
                                e.target.value
                              )
                            }
                          >
                            {outputs.map((optionOutput) => (
                              <option
                                key={optionOutput.id}
                                value={optionOutput.id}
                              >
                                {optionOutput.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {/* Split Direction Toggle & Split Position */}
                {/* split controls for parent screen */}
                {screen.subScreens && screen.subScreens.length === 0 && (
                  <form className="row gy-2 gx-3 mb-3 align-items-center">
                  <div className="col-sm-2"></div>
                  <div className="col">
                    <InputGroup>
                      <Button
                        variant={`${
                          splitDirection === "horizontal"
                            ? "warning"
                            : "outline-warning"
                        }`}
                        className={`btn-sm`}
                        onClick={toggleSplitDirection}
                      >
                        {splitDirection === "horizontal"
                          ? "Horizontal Split"
                          : "Vertical Split"}
                      </Button>
                      <InputGroup.Text id="basic-addon2">panel count</InputGroup.Text>
                      <Form.Control
                        type="number"
                        name="splitPosition"
                        min="0"
                        max={
                          (splitDirection === "horizontal"
                            ? screen.mapHeight-1
                            : screen.mapWidth-1)}
                        placeholder={`Split Position (${
                          splitDirection === "horizontal" ? "Y" : "X"
                        })`}
                        className="form-control form-control-sm"
                        value={
                          splitPosition /
                          (splitDirection === "horizontal"
                            ? screen.panelHeight
                            : screen.panelWidth)
                        }
                        onChange={(e) =>
                          setSplitPosition(
                            e.target.value *
                              (splitDirection === "horizontal"
                                ? screen.panelHeight
                                : screen.panelWidth)
                          )
                        }
                      />

                      <button
                        className="btn btn-success btn-sm"
                        type="button"
                        onClick={() => handleSplitScreen(screen.id)}
                      >
                        Split
                      </button>
                    </InputGroup>
                  </div>
                </form>
                )}
                

                {/* Additional controls for sub-screens */}
                {screen.subScreens
                  .filter((subScreen) => subScreen.assignedOutput === output.id)
                  .map((subScreen, index) => (
                    <div key={subScreen.id} className="sub-screen-info">
                      <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                          {screen.name} - Section {subScreen.number}
                        </div>
                      </div>

                      {/* Render controls for the sub-screen */}
                      <form className="row gy-2 gx-3 mb-1 align-items-center">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-5">
                          <label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Section Width
                          </label>
                          <div className="input-group input-group-sm">
                            <div className="input-group-text">
                              Section Width
                            </div>
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              name="offsetX"
                              min="0"
                              step="1"
                              value={subScreen.width}
                              readOnly
                              placeholder="Section Width"
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Section Height
                          </label>
                          <div className="input-group input-group-sm">
                            <div className="input-group-text">
                              Section Height
                            </div>
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              name="offsetY"
                              min="0"
                              step="1"
                              value={subScreen.height}
                              readOnly
                              placeholder="Section Height"
                            />
                          </div>
                        </div>
                      </form>
                      <form className="row gy-2 gx-3 mb-1 align-items-center">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-5">
                          <label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            X Offset
                          </label>
                          <div className="input-group input-group-sm">
                            <div className="input-group-text">X Offset</div>
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              name="offsetX"
                              min="0"
                              step="1"
                              value={subScreen.offsetX}
                              onChange={(e) =>
                                handleSubScreenOffsetChange(
                                  screen.id,
                                  subScreen.id,
                                  "offsetX",
                                  e.target.value
                                )
                              }
                              placeholder="Offset X"
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Y Offset
                          </label>
                          <div className="input-group input-group-sm">
                            <div className="input-group-text">Y Offset</div>
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              name="offsetY"
                              min="0"
                              step="1"
                              value={subScreen.offsetY}
                              onChange={(e) =>
                                handleSubScreenOffsetChange(
                                  screen.id,
                                  subScreen.id,
                                  "offsetY",
                                  e.target.value
                                )
                              }
                              placeholder="Offset Y"
                            />
                          </div>
                        </div>
                      </form>
                      <form className="row gy-2 gx-3 mb-3 align-items-center">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                          <div className="input-group input-group-sm">
                            <span className="input-group-text">
                              Assigned Output
                            </span>

                            <select
                              className="form-select form-select-sm"
                              aria-label="Output selection"
                              value={subScreen.assignedOutput}
                              onChange={(e) =>
                                handleSubScreenAssignOutput(
                                  screen.id,
                                  subScreen.id,
                                  e.target.value
                                )
                              }
                            >
                              {outputs.map((output) => (
                                <option key={output.id} value={output.id}>
                                  {output.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </form>
                      {subScreen.latest && (
                        <>
                        <form className="row gy-2 gx-3 mb-3 align-items-center">
                        <div className="col-sm-2"></div>
                        <div className="col">
                          <InputGroup>
                            <Button
                              variant={`${
                                splitDirection === "horizontal"
                                  ? "warning"
                                  : "outline-warning"
                              }`}
                              className={`btn-sm`}
                              onClick={toggleSplitDirection}
                            >
                              {splitDirection === "horizontal"
                                ? "Horizontal Split"
                                : "Vertical Split"}
                            </Button>
                            <InputGroup.Text id="basic-addon2">panel count</InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="splitPosition"
                              min="0"
                              max={
                                (splitDirection === "horizontal"
                                  ? screen.mapHeight-1
                                  : screen.mapWidth-1)}
                              placeholder={`Split Position (${
                                splitDirection === "horizontal" ? "Y" : "X"
                              })`}
                              className="form-control form-control-sm"
                              value={
                                splitPosition /
                                (splitDirection === "horizontal"
                                  ? screen.panelHeight
                                  : screen.panelWidth)
                              }
                              onChange={(e) =>
                                setSplitPosition(
                                  e.target.value *
                                    (splitDirection === "horizontal"
                                      ? screen.panelHeight
                                      : screen.panelWidth)
                                )
                              }
                            />
      
                            <button
                              className="btn btn-success btn-sm"
                              type="button"
                              onClick={() => handleSplitScreen(screen.id)}
                            >
                              Split
                            </button>
                          </InputGroup>
                        </div>
                      </form>
                        <form className="row gy-2 gx-3 mb-3 align-items-center">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-10">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default form submission
                                handleSubScreenUndoSplit(
                                  screen.id,
                                  subScreen.id
                                );
                              }}
                            >
                              Undo Split
                            </button>
                          </div>
                        </form>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}

      <div className="d-grid gap-2 mb-3">
        <button
          className="btn btn-success"
          type="button"
          onClick={handleAddOutput}
        >
          Add Output
        </button>
      </div>
      <div className="d-grid gap-2 mb-3">
        <button
          className="btn btn-primary"
          type="button"
          onClick={toggleCanvasScaling}
        >
          {isCanvasScaled ? "Scaled to Fit" : "Actual Size"}
        </button>
      </div>
    </div>
  );
}

export default OutputControls;

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOutputImage } from "../actions";

function ProjectView({ isCanvasScaled }) {
  const dispatch = useDispatch();
  const outputs = useSelector((state) => state.project.outputs);
  const screens = useSelector((state) => state.project.screens);
  const imagesGeneratedRef = useRef(false); // Ref to track if images have been generated

  const drawScreens = (screenList, ctx, activeOutputId) => {
    return Promise.all(screenList.map((screen) => {
      if (screen.subScreens && screen.subScreens.length > 1) {
        return Promise.all(screen.subScreens.map((subScreen, i) => {
          if (subScreen.assignedOutput === activeOutputId) {
            let x = subScreen.offsetX;
            let y = subScreen.offsetY;
            let sourceX = 0;
            let sourceY = 0;

            if (i > 0) {
              if (screen.subScreens[i - 1].direction === "horizontal") {
                sourceY = screen.subScreens[i - 1].position;
              } else if (screen.subScreens[i - 1].direction === "vertical") {
                sourceX = screen.subScreens[i - 1].position;
              }
            }
            return drawImage(ctx, screen.image, subScreen.sourceX, subScreen.sourceY, subScreen.width, subScreen.height, x, y, subScreen.width, subScreen.height);//todo fix bug with halfRow causing bug in height when also using split screens with a horizontal split
          }
          return Promise.resolve();
        }));
      } else {
        return drawImage(ctx, screen.image, 0, 0, screen.panelWidth * screen.mapWidth, screen.halfRow ? screen.panelHeight * screen.mapHeight + (screen.panelHeight / 2) : screen.panelHeight * screen.mapHeight, screen.offsetX, screen.offsetY, screen.panelWidth * screen.mapWidth, screen.halfRow ? screen.panelHeight * screen.mapHeight + (screen.panelHeight / 2) : screen.panelHeight * screen.mapHeight);
      }
    }));
  };

  const drawImage = (ctx, image, sx, sy, swidth, sheight, dx, dy, dwidth, dheight) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, sx, sy, swidth, sheight, dx, dy, dwidth, dheight);
        resolve();
      };
      img.src = `data:image/png;base64,${image}`;
    });
  };

  useEffect(() => {
    const renderOutputs = async () => {
      for (const output of outputs) {
        let activeOutput = output;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = activeOutput.width;
        canvas.height = activeOutput.height;

        ctx.fillStyle = activeOutput.bgcolor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const outputScreens = screens.filter((screen) => 
          screen.assignedOutput === activeOutput.id ||
          (screen.subScreens && screen.subScreens.some((subScreen) => subScreen.assignedOutput === activeOutput.id))
        );

        for (const screen of outputScreens) {
          await drawScreens([screen], ctx, activeOutput.id);
        }

        const dataURL = canvas.toDataURL("image/png");
        dispatch(updateOutputImage(activeOutput.id, dataURL));
      }
    };

    renderOutputs();
  }, [screens]);

  return (
    <div>
      {/* images */}
      <div className="container">
        <div className="row mt-2">
          <div className="col">
            <h4>Screens</h4>
          </div>
        </div>
        <div className="row">
          {screens.map((screen) => (
            <div className="col-6 col-md-3 col-lg-2 mb-3" key={screen.id}>
              <img
                src={`data:image/png;base64,${screen.image}`}
                alt={screen.name}
                className="img-thumbnail"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <h4>Outputs</h4>
          </div>
        </div>
        <div className="row">
          {outputs.map((output) => (
            <div className="col-6 col-md-4 mb-3" key={output.id}>
              <img
                src={output.image}
                alt={output.name}
                className="img-thumbnail"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectView;

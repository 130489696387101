import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk'; // Ensure this import is correct
import rootReducer from './reducers';
import { defaultScreen } from './reducers';

const CURRENT_VERSION = 2;

const migrateState = (state) => {
  // Replace screens with default ones
  state.project.screens = state.project.screens.map((screen) => ({
    ...defaultScreen,
    id: screen.id,
    assignedOutput: screen.assignedOutput,
    // Add any additional properties here that you want to preserve from the original screen
  }));
  // Update version number
  state.version = CURRENT_VERSION;
  return state;
};

// Utility functions for localStorage
const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('projectState', serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
};

const loadStateFromLocalStorage = () => {
  try {
    //console.log("loading project from local storage");
    const serializedState = localStorage.getItem('projectState');
    if (serializedState === null) {
      return undefined; // No state in localStorage
    }
    let loadedState = JSON.parse(serializedState);

    // Handle the case where version is undefined
    if (loadedState.version === undefined) {
      //console.log('State version undefined, assuming old version and migrating.');
      loadedState = migrateState(loadedState);
      saveStateToLocalStorage(loadedState);
      return loadedState;
    }

    if (loadedState.version !== CURRENT_VERSION) {
      // State version mismatch, migrate state
      loadedState = migrateState(loadedState);
      // Save migrated state back to localStorage
      saveStateToLocalStorage(loadedState);
    }

    // Validate each screen in the 'screens' array
    if (Array.isArray(loadedState.project.screens)) {
      loadedState.project.screens = loadedState.project.screens.map(screen => {
        let isValid = true;

        // Check if panelWidth * mapWidth exceeds 32767
        if (screen.panelWidth * screen.mapWidth > 32767) {
          console.log("local screen did not pass validation, resetting to default screen")
          isValid = false;
        }

        // Check if panelHeight * mapHeight exceeds 32767
        if (screen.panelHeight * screen.mapHeight > 32767) {
          console.log("local screen did not pass validation, resetting to default screen")
          isValid = false;
        }

        // If either condition is not met, replace the screen with the defaultScreen
        // while preserving the screen's id and any other properties you want to keep
        if (!isValid) {
          return {
            ...defaultScreen, // Apply default values
            id: screen.id, // Keep the original screen ID
            assignedOutput: screen.assignedOutput, // Preserving the assignedOutput or any other properties you want to keep
            // Add any additional properties here that you want to preserve from the original screen
          };
        }

        // If the screen is valid, return it unchanged
        return screen;
      });
    }

    return loadedState;
  } catch (err) {
    return undefined; // Errors could occur while reading state
  }
};

const persistedState = loadStateFromLocalStorage();

const localStorageMiddleware = store => next => action => {
  const result = next(action);
  saveStateToLocalStorage(store.getState());
  return result;
};

const middleware = [thunk, localStorageMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    persistedState, // Use persisted state as initial state
    composeEnhancers(
        applyMiddleware(...middleware)
    )
);

export default store;

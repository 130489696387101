import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOutputImage } from "../actions"; // Adjust the import path as necessary


function OutputView({ isCanvasScaled }) {
  const dispatch = useDispatch();
  const activeOutputId = useSelector((state) => state.project.activeOutputId);
  const activeOutput = useSelector((state) =>
    state.project.outputs.find((output) => output.id === activeOutputId)
  );
  /* const screens = useSelector(state =>
        state.project.screens.filter(screen => screen.assignedOutput === activeOutputId)
    ); */
  /* filter screens and sub-screens that are assigned to the active output */
  const screens = useSelector((state) =>
    state.project.screens.filter(
      (screen) =>
        screen.assignedOutput === activeOutputId ||
        (screen.subScreens &&
          screen.subScreens.some(
            (subScreen) => subScreen.assignedOutput === activeOutputId
          ))
    )
  );
  const canvasRef = useRef(null);
  //store a reference to the previous dataURL to avoid unnecessary redraws
  const prevDataURLRef = useRef(null);

  //console.log(screens);

  const drawScreens = (screenList, ctx) => {
    screenList.forEach((screen) => {
      /* sub screen rendering */
      if (screen.subScreens && screen.subScreens.length > 1) {
        for (let i = 0; i < screen.subScreens.length; i++) {
          if (screen.subScreens[i].assignedOutput === activeOutputId) {
            let subScreen = screen.subScreens[i];
            let x = subScreen.offsetX;
            let y = subScreen.offsetY;
            let width = screen.panelWidth * screen.mapWidth;
            let height = screen.panelHeight * screen.mapHeight;
            let sourceX = 0;
            let sourceY = 0;

            if (i > 0) {
              if (screen.subScreens[i - 1].direction === "horizontal") {
                sourceY = screen.subScreens[i - 1].position;
              } else if (screen.subScreens[i - 1].direction === "vertical") {
                sourceX = screen.subScreens[i - 1].position;
              }
            }
            //console.log("drawing image : ", x, y, width, height);
            drawImage(
              ctx,
              screen.image,
              subScreen.sourceX,
              subScreen.sourceY,
              subScreen.width,
              subScreen.height,//todo fix bug with halfRow causing bug in height when also using split screens with a horizontal split
              x,
              y,
              subScreen.width,
              subScreen.height, //todo fix bug with halfRow causing bug in height when also using split screens with a horizontal split
            );
          }
        }
      } else {
        /* full screen rendering */
        /* console.log(
          "drawing image : ",
          ctx,
          screen.image,
          screen.offsetX,
          screen.offsetY,
          screen.panelWidth * screen.mapWidth,
          screen.halfRow ? screen.panelHeight * screen.mapHeight + (screen.panelHeight/2) : screen.panelHeight * screen.mapHeight
        ); */
        
        drawImage(
          ctx,
          screen.image,
          0,
          0,
          screen.panelWidth * screen.mapWidth,
          screen.halfRow ? screen.panelHeight * screen.mapHeight + (screen.panelHeight/2) : screen.panelHeight * screen.mapHeight,
          screen.offsetX,
          screen.offsetY,
          screen.panelWidth * screen.mapWidth,
          screen.halfRow ? screen.panelHeight * screen.mapHeight + (screen.panelHeight/2) : screen.panelHeight * screen.mapHeight
        );
      }
    });
  };

  const drawImage = (
    ctx,
    image,
    sx,
    sy,
    swidth,
    sheight,
    dx,
    dy,
    dwidth,
    dheight
  ) => {
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, sx, sy, swidth, sheight, dx, dy, dwidth, dheight);
    };
    img.src = `data:image/png;base64,${image}`;
  };

  useEffect(() => {
    if (!activeOutput || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = activeOutput.width;
    canvas.height = activeOutput.height;

    ctx.fillStyle = activeOutput.bgcolor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    drawScreens(screens, ctx);
    // Save the output image after drawing
    const dataURL = canvas.toDataURL("image/png");
    if (prevDataURLRef.current !== dataURL) {
      dispatch(updateOutputImage(activeOutputId, dataURL));
      prevDataURLRef.current = dataURL;
    }
  }, [activeOutput, screens]);

  return (
    <div>
      <canvas
        id="outputCanvas"
        ref={canvasRef}
        style={{ width: isCanvasScaled ? "100%" : "auto" }}
      ></canvas>
    </div>
  );
}

export default OutputView;

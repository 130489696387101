import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addScreen,
  setActiveScreen,
  updateScreenData,
  deleteScreen,
  duplicateScreen,
  resetActiveScreen,
  resetProject,
  updateOutputImage,
  setPixelMapImage,
  addAndSetActiveScreen,
} from "../../actions/index"; // Update this path
import { generateUniqueId, defaultScreen } from "../../reducers";
import {
  Row,
  Col,
  Accordion,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { updateImage } from "../utilities/PixelMapUtils"; // Import the utility functions
import { auth } from "../../firebaseConfig";
import axios from "axios";
import { useSubscriber } from "../../contexts/SubscriptionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPalette,
  faLayerGroup,
  faMaximize,
  faArrowTrendUp,
  faLocationCrosshairs,
  faMoneyBill,
  faFont,
  faPanorama,
  faTableCells,
  faTableCellsLarge,
  faCircleInfo,
  faVectorSquare,
  faServer,
  faBook,
  faGauge,
  faVideo,
  faNetworkWired,
  faEthernet
} from "@fortawesome/free-solid-svg-icons";
import zigzagArrows from "../../assets/data_zigzag_120px_justarrows.png";
import returnArrows from "../../assets/data_return_120px_justarrows.png";
import { Link } from "react-router-dom";

function ScreenControls({ isCanvasScaled, toggleCanvasScaling }) {
  const dispatch = useDispatch();

  const screens = useSelector((state) => state.project.screens);
  const projectState = useSelector((state) => state.project);

  const activeScreenId = useSelector((state) => state.project.activeScreenId);
  const activeScreen = screens.find((screen) => screen.id === activeScreenId);
  //console.log("active screen : ", activeScreen);

  //project
  const saveUserProjectUri = process.env.REACT_APP_PROJECTS;

  //modal management
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //upgrade modal management
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const handleShowUpgradeModal = () => {
    setShowUpgradeModal(true);
  };

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  //validation modal
  const [showValidationModal, setShowValidationModal] = useState(false);

  const handleShowValidationModal = () => {
    setShowValidationModal(true);
  };

  const handleCloseValidationModal = () => {
    setShowValidationModal(false);
  };

  //panel library
  const panelTypeApiUri = process.env.REACT_APP_PANEL_API;
  const [panelTypes, setPanelTypes] = useState([]);
  const [selectedPanelType, setSelectedPanelType] = useState("");
  const [filterText, setFilterText] = useState("");

  //subscription check
  const { isSubscriber, subscriptionDetails } = useSubscriber();

  //state for half row and screen height
  const [screenHeight, setScreenHeight] = useState(activeScreen.mapHeight);
  const [halfRow, setHalfRow] = useState("");

  //coordinate style
  const getCoordinateStyleLabel = (index) => {
    switch (index) {
      case 0:
        return "(A,1)";
      case 1:
        return "(1,A)";
      case 2:
        return "(1,1)";
      case 3:
        return "(A,A)";
    }
  };

  //data arrows
  const getArrowImage = (index) => {
    // Determine whether to use zigzagArrows or returnArrows
    const useZigzag = index < 8;
    const imageUrl = useZigzag ? zigzagArrows : returnArrows;

    // Based on index, determine the class to apply for the transformation
    // This is an example logic, you need to adapt the class names and logic based on actual CSS classes you define
    let transformationClass = "";
    switch (index) {
      case 0:
        transformationClass = "widthOnly";
        break; // no transformation needed
      case 1:
        transformationClass = "flipHorizontal";
        break;
      case 2:
        transformationClass = "flipVertical";
        break;
      case 3:
        transformationClass = "rotate180";
        break;
      case 4:
        transformationClass = "rotate270FlipHorizontal";
        break;
      case 5:
        transformationClass = "rotate90FlipHorizontal";
        break;
      case 6:
        transformationClass = "rotate270";
        break;
      case 7:
        transformationClass = "rotate90";
        break;
      case 13:
        transformationClass = "widthOnly";
        break; // no transformation needed
      case 15:
        transformationClass = "flipHorizontal";
        break;
      case 12:
        transformationClass = "flipVertical";
        break;
      case 14:
        transformationClass = "rotate180";
        break;
      case 10:
        transformationClass = "rotate270FlipHorizontal";
        break;
      case 11:
        transformationClass = "rotate90";
        break;
      case 8:
        transformationClass = "rotate270";
        break;
      case 9:
        transformationClass = "rotate90FlipHorizontal";
        break;
      // Add cases for all indices and corresponding transformations
      // ...
      default:
        break; // default case if needed
    }

    return <img src={imageUrl} alt="arrow" className={transformationClass} />;
  };

  useEffect(() => {
    const fetchPanelTypes = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        );
        const response = await axios.get(panelTypeApiUri, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        //console.log("fetched panels: ", response.data);
        setPanelTypes(response.data);
      } catch (error) {
        console.error("Error fetching panel types:", error);
        return [];
      }
    };

    fetchPanelTypes();
  }, [panelTypeApiUri]);

  //validate inputs
  const [validity, setValidity] = useState({
    mapWidth: true,
    panelWidth: true,
    mapHeight: true,
    panelHeight: true,
  });

  useEffect(() => {
    const checkValidity = () => {
      const totalWidth = activeScreen.mapWidth * activeScreen.panelWidth;
      const totalHeight = activeScreen.mapHeight * activeScreen.panelHeight;
      return {
        mapWidth: totalWidth <= 32767,
        panelWidth: totalWidth <= 32767,
        mapHeight: totalHeight <= 32767,
        panelHeight: totalHeight <= 32767,
      };
    };

    setValidity(checkValidity());
  }, [
    activeScreen.mapWidth,
    activeScreen.panelWidth,
    activeScreen.mapHeight,
    activeScreen.panelHeight,
  ]);

  //limits to respect node-canvas's max width and height limit of 32767
  const [maxMapWidth, setMaxMapWidth] = useState(
    Math.floor(32767 / activeScreen.panelWidth)
  );
  const [maxPanelWidth, setMaxPanelWidth] = useState(
    Math.floor(32767 / activeScreen.mapWidth)
  );
  const [maxMapHeight, setMaxMapHeight] = useState(
    Math.floor(32767 / activeScreen.panelHeight)
  );
  const [maxPanelHeight, setMaxPanelHeight] = useState(
    Math.floor(32767 / activeScreen.mapHeight)
  );

  useEffect(() => {
    const newMaxMapWidth = Math.floor(32767 / activeScreen.panelWidth);
    const newMaxPanelWidth = Math.floor(32767 / activeScreen.mapWidth);
    const newMaxMapHeight = Math.floor(32767 / activeScreen.panelHeight);
    const newMaxPanelHeight = Math.floor(32767 / activeScreen.mapHeight);

    setMaxMapWidth(newMaxMapWidth);
    setMaxPanelWidth(newMaxPanelWidth);
    setMaxMapHeight(newMaxMapHeight);
    setMaxPanelHeight(newMaxPanelHeight);
  }, [
    activeScreen.mapWidth,
    activeScreen.panelWidth,
    activeScreen.mapHeight,
    activeScreen.panelHeight,
  ]);

  const handlePanelTypeChange = (screenId, e) => {
    const panelTypeId = e.target.value;
    const panelType = panelTypes.find((pt) => pt.id === parseInt(panelTypeId));
    if (panelType) {
      setSelectedPanelType(panelTypeId);
      dispatch(
        updateScreenData(screenId, {
          panelWidth: panelType.panel_width,
          panelHeight: panelType.panel_height,
          panelLibraryId: panelType.id,
        })
      );
    }
  };
  /* 
  const filteredPanelTypes = panelTypes.filter((panelType) =>
    `${panelType.manufacturer} - ${panelType.name}`
      .toLowerCase()
      .includes(filterText.toLowerCase())

      
  ); */

  const filteredPanelTypes = panelTypes
    //filte rbased on input
    .filter((panelType) =>
      `${panelType.manufacturer} - ${panelType.name}`
        .toLowerCase()
        .includes(filterText.toLowerCase())
    )
    //sort by manufacturer, then panel type
    .sort((a, b) => {
      // Compare by manufacturer first
      const manufacturerComparison = a.manufacturer.localeCompare(
        b.manufacturer
      );
      if (manufacturerComparison !== 0) {
        return manufacturerComparison;
      }
      // If manufacturers are the same, compare by panel type (assuming 'name' is the property to compare)
      return a.name.localeCompare(b.name);
    });

  //base64 helper for img download
  function base64ToBlob(base64, mime) {
    // Check if base64 string contains the Data URI scheme. If not, prepend it.
    const dataURI =
      base64.indexOf("data:image/png;base64,") === 0
        ? base64
        : `data:image/png;base64,${base64}`;

    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  }

  //handlers
  const handleScreenExport = async () => {
    // Assuming projectState.screen[0].image contains your Base64 encoded image data
    const imageData = projectState.screens[0].image;

    // Convert Base64 image to a Blob
    const imageBlob = base64ToBlob(imageData, "image/png");

    // Create a URL for the Blob
    const imageURL = URL.createObjectURL(imageBlob);

    // Create a temporary anchor element and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = imageURL;
    downloadLink.download = "blinkingthings-pixel-map.png"; // Name of the file to be downloaded
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Clone the project state to avoid mutating the original state
    const clonedProjectState = JSON.parse(JSON.stringify(projectState));

    // Remove image data from screens
    clonedProjectState.screens.forEach((screen) => {
      delete screen.image;
    });

    // Remove image data from outputs
    clonedProjectState.outputs.forEach((output) => {
      delete output.image;
    });

    const projectDataStr = JSON.stringify(clonedProjectState, null, 2);

    //save user-project to db on export
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        saveUserProjectUri,
        {
          userEmail: auth.currentUser.email,
          projectJson: clonedProjectState, // project without image data
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        //console.log('Project saved successfully');
      }
    } catch (error) {
      console.error("Error saving project:", error);
    }
  };

  const parseInputValue = (name, value) => {
    // Check if the input name indicates an opacity value
    if (name.startsWith("opacity")) {
      return parseFloat(value);
    } else {
      // Default parsing logic for other inputs
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? value : parsedValue;
    }
  };

  function setNestedPropertyValue(obj, path, value) {
    // Split the path by both dots and brackets
    if (typeof path === "string") {
      path = path.split(/\.|\[|\]/).filter((p) => p); // Filter out empty strings from result
    }

    if (path.length > 1) {
      const [first, ...rest] = path;
      // Check if the next path segment is numeric and treat as an array index
      const next = rest[0];
      const isArrayIndex = !isNaN(next);

      if (isArrayIndex) {
        if (!obj[first]) obj[first] = []; // Ensure an array exists here
      } else {
        if (!obj[first]) obj[first] = {}; // Ensure an object exists here
      }

      setNestedPropertyValue(obj[first], rest, value);
    } else {
      // Direct assignment
      if (Array.isArray(obj)) {
        // If the object is an array, ensure the key is treated as an index
        const index = parseInt(path[0], 10);
        obj[index] = value;
      } else {
        // Standard object property assignment
        obj[path[0]] = value;
      }
    }
  }

  const handleInputChange = (screenId, event) => {
    const { name, value, type, checked } = event.target;
    let inputValue;

    const actualValue = value === "" ? null : value; // Convert empty string to null value

    // Determine if the input is a checkbox to use `checked` instead of `value`
    if (type === "checkbox") {
      inputValue = checked;
    } else {
      inputValue = parseInputValue(name, actualValue);
    }

    // Inline validation

    if (
      name === "panelWidth" ||
      name === "panelHeight" ||
      name === "mapWidth" ||
      name === "mapHeight" ||
      name === "portSize" ||
      name === "portWidthLimit" ||
      name === "portHeightLimit"
    ) {
      const isValid = validateInput(name, inputValue, activeScreen);
      if (!isValid) {
        // You can also provide user feedback here (e.g., showing an error message)
        setShowValidationModal(true); // Show modal on invalid input
        return; // Stop the function if the value is not valid
      }
    }
    let updatedData = {};
    setNestedPropertyValue(updatedData, name, inputValue);

    // If the user is manually entering panelWidth or panelHeight, clear the panelLibraryId, set panel type to custom
    if (name === "panelWidth" || name === "panelHeight") {
      // Assuming the structure of your state, adjust if necessary
      setNestedPropertyValue(updatedData, "panelLibraryId", null);
      setSelectedPanelType("custom");
    }

    //console.log(updatedData);
    dispatch(updateScreenData(screenId, updatedData));
  };

  // Validation function
  function validateInput(inputName, inputValue, activeScreen) {
    // Implement your validation logic here, similar to what you have in useEffect
    // For simplicity, here's an example based on the previous discussion:
    const { mapWidth, panelWidth, mapHeight, panelHeight, portSize } =
      activeScreen;
    const newValues = { ...activeScreen, [inputName]: inputValue };
    if (inputName === "portSize") {
      return newValues.portSize >= 1;
    } else {
      const totalWidth = newValues.mapWidth * newValues.panelWidth;
      const totalHeight = newValues.mapHeight * newValues.panelHeight;

      return totalWidth <= 32767 && totalHeight <= 32767;
    }
  }

  const handleAddScreen = () => {
    const newScreenId = generateUniqueId();
    //console.log("adding screen with id : ", newScreenId);
    const newScreen = { ...defaultScreen, id: newScreenId }; // Ensure defaultScreen structure and generateUniqueId are defined
    // Dispatch the thunk to handle adding and setting the active screen
    dispatch(addAndSetActiveScreen(newScreen));
  };

  const handleSetActiveScreen = (id) => {
    dispatch(setActiveScreen(id));
  };

  const handleDeleteScreen = (screenId) => {
    dispatch(deleteScreen(screenId));
  };

  const handleDuplicateScreen = (screen) => {
    dispatch(duplicateScreen(screen));
  };

  const handleResetScreen = (screenId) => {
    dispatch(resetActiveScreen(screenId));
  };

  const handleResetProject = () => {
    dispatch(resetProject());
    handleClose(); // Close the modal after confirming
  };

  const handleLogoUpload = (screenId, event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Once the file is read, `reader.result` contains the base64 data URL
        const logoData = reader.result;
        dispatch(updateScreenData(screenId, { logoData })); // Update the screen data with the logo
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const processorData = {
    "Brompton SX40": {
      bitDepthOptions: [8, 10, 12],
      frameRateOptions: [
        24, 25, 30, 48, 50, 60, 72, 100, 120, 144, 150, 180, 192, 200, 240, 250,
      ],
      dataRateOptions: [1],
      portSizes: {
        "8_24_1": 1312500,
        "10_24_1": 1050000,
        "12_24_1": 875000,
        "8_25_1": 1260000,
        "10_25_1": 1008000,
        "12_25_1": 840000,
        "8_30_1": 1050000,
        "10_30_1": 840000,
        "12_30_1": 700000,
        "8_48_1": 656250,
        "10_48_1": 525000,
        "12_48_1": 437500,
        "8_50_1": 630000,
        "10_50_1": 504000,
        "12_50_1": 420000,
        "8_60_1": 525000,
        "10_60_1": 420000,
        "12_60_1": 350000,
        "8_72_1": 437500,
        "10_72_1": 350000,
        "12_72_1": 291667,
        "8_100_1": 315000,
        "10_100_1": 252000,
        "12_100_1": 210000,
        "8_120_1": 262500,
        "10_120_1": 210000,
        "12_120_1": 175000,
        "8_144_1": 218750,
        "10_144_1": 175000,
        "12_144_1": 145833,
        "8_150_1": 210000,
        "10_150_1": 168000,
        "12_150_1": 140000,
        "8_180_1": 175000,
        "10_180_1": 140000,
        "12_180_1": 116667,
        "8_192_1": 164063,
        "10_192_1": 131250,
        "12_192_1": 109375,
        "8_200_1": 157500,
        "10_200_1": 126000,
        "12_200_1": 105000,
        "8_240_1": 131250,
        "10_240_1": 105000,
        "12_240_1": 87500,
        "8_250_1": 126000,
        "10_250_1": 100800,
        "12_250_1": 84000,
        // Add more combinations as needed
      },
    },
    "Megapixel Helios": {
      bitDepthOptions: [10, 12],
      frameRateOptions: [24, 25, 30, 48, 50, 60, 120, 144, 240],
      dataRateOptions: [1, 2.5],
      portSizes: {
        "10_24_1": 1275000,
        "12_24_1": 1062500,
        "10_24_2.5": 3187500,
        "12_24_2.5": 2656250,
        "10_25_1": 1225000,
        "12_25_1": 1020000,
        "10_25_2.5": 3062500,
        "12_25_2.5": 2550000,
        "10_30_1": 1020000,
        "12_30_1": 850000,
        "10_30_2.5": 2595000,
        "12_30_2.5": 2125000,
        "10_48_1": 655000,
        "12_48_1": 531000,
        "10_48_2.5": 1587500,
        "12_48_2.5": 1328125,
        "10_50_1": 610000,
        "12_50_1": 510000,
        "10_50_2.5": 1525000,
        "12_50_2.5": 1275000,
        "10_60_1": 510000,
        "12_60_1": 425000,
        "10_60_2.5": 1275000,
        "12_60_2.5": 1062500,
        "10_120_1": 240000,
        "12_120_1": 200000,
        "10_120_2.5": 600000,
        "12_120_2.5": 500000,
        "10_144_1": 195000,
        "12_144_1": 160000,
        "10_144_2.5": 487500,
        "12_144_2.5": 400000,
        "10_240_1": 100000,
        "12_240_1": 90000,
        "10_240_2.5": 250000,
        "12_240_2.5": 225000,

        // Add more combinations as needed
      },
    },
  };

  const [selectedProcessor, setSelectedProcessor] = useState(
    activeScreen.processorType
  );
  const [selectedBitDepth, setSelectedBitDepth] = useState(
    activeScreen.processorBitDepth
  );
  const [selectedFrameRate, setSelectedFrameRate] = useState(
    activeScreen.processorFrameRate
  );
  const [selectedDataRate, setSelectedDataRate] = useState(
    activeScreen.processorDataRate
  );
  const [selectedPortSize, setSelectedPortSize] = useState(
    activeScreen.portSize
  );

  const handleProcessorChange = (screenId, event) => {
    const { name, value, type, checked } = event.target;
    const selectedProcessor = event.target.value;
    setSelectedProcessor(selectedProcessor);
    let inputValue;

    const actualValue = value === "" ? null : value; // Convert empty string to null value
    inputValue = parseInputValue(name, actualValue);
    let updatedData = {};
    setNestedPropertyValue(updatedData, name, inputValue);
    dispatch(updateScreenData(screenId, updatedData));
  };

  const calculatePortSize = (bitDepth, frameRate, dataRate) => {
    const key = `${bitDepth}_${frameRate}_${dataRate}`;
    if (processorData[selectedProcessor].portSizes[key]) {
      return processorData[selectedProcessor].portSizes[key];
    }
    return 0; // Default port size if combination not found
  };

  const handleBitDepthChange = (screenId, event) => {
    const { name, value, type, checked } = event.target;
    const selectedBitDepth = event.target.value;
    setSelectedBitDepth(selectedBitDepth);
    const newPortSize = calculatePortSize(
      selectedBitDepth,
      selectedFrameRate,
      selectedDataRate
    );
    setSelectedPortSize(newPortSize);
    let inputValue;

    const actualValue = value === "" ? null : value; // Convert empty string to null value
    inputValue = parseInputValue(name, actualValue);
    let updatedData = {};
    setNestedPropertyValue(updatedData, name, inputValue);
    dispatch(updateScreenData(screenId, updatedData));
    setNestedPropertyValue(updatedData, "portSize", newPortSize);
    dispatch(updateScreenData(screenId, updatedData));
  };

  const handleFrameRateChange = (screenId, event) => {
    const { name, value, type, checked } = event.target;
    const selectedFrameRate = event.target.value;
    setSelectedFrameRate(selectedFrameRate);
    const newPortSize = calculatePortSize(
      selectedBitDepth,
      selectedFrameRate,
      selectedDataRate
    );
    setSelectedPortSize(newPortSize);
    let inputValue;

    const actualValue = value === "" ? null : value; // Convert empty string to null value
    inputValue = parseInputValue(name, actualValue);
    let updatedData = {};
    setNestedPropertyValue(updatedData, name, inputValue);
    dispatch(updateScreenData(screenId, updatedData));
    setNestedPropertyValue(updatedData, "portSize", newPortSize);
    dispatch(updateScreenData(screenId, updatedData));
  };

  const handleDataRateChange = (screenId, event) => {
    const { name, value, type, checked } = event.target;
    const selectedDataRate = event.target.value;
    setSelectedDataRate(selectedDataRate);
    const newPortSize = calculatePortSize(
      selectedBitDepth,
      selectedFrameRate,
      selectedDataRate
    );
    setSelectedPortSize(newPortSize);
    let inputValue;

    const actualValue = value === "" ? null : value; // Convert empty string to null value
    inputValue = parseInputValue(name, actualValue);
    let updatedData = {};
    setNestedPropertyValue(updatedData, name, inputValue);
    dispatch(updateScreenData(screenId, updatedData));
    setNestedPropertyValue(updatedData, "portSize", newPortSize);
    dispatch(updateScreenData(screenId, updatedData));
  };
  return (
    <>
      <div>
        <Accordion defaultActiveKey="0" className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <FontAwesomeIcon icon={faMaximize} />
              &nbsp;Size Options
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <FontAwesomeIcon icon={faTableCells} /> Screen
                </Col>
              </Row>
              <Form className={`row gy-2 gx-3 mb-1 align-items-center`}>
                <Col sm={6}>
                  <label
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  >
                    Screen Width
                  </label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-text">Width</div>
                    <input
                      className={`form-control form-control-sm`}
                      type="number"
                      name="mapWidth"
                      min="1"
                      step="1"
                      max={maxMapWidth} //avoids generating a canvas more than 32767 pixels wide
                      value={activeScreen ? activeScreen.mapWidth : ""}
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in panels"
                    />
                  </div>
                </Col>
                <div className="col-sm-6">
                  <label
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  >
                    Screen Height
                  </label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-text">Height</div>
                    <input
                      className={`form-control form-control-sm`}
                      type="number"
                      name="mapHeight"
                      min="1"
                      step="1"
                      max={maxMapHeight}
                      value={activeScreen ? activeScreen.mapHeight : ""}
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in panels"
                    />
                  </div>
                </div>
              </Form>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faBook} /> Add half panel?
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="halfRow"
                    value={activeScreen.halfRow}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  >
                    <option value="">No</option>
                    <option value="top">Top</option>
                    <option value="bottom">Bottom</option>
                  </Form.Control>
                </Form.Group>
              </Form>
              {/*panel*/}
              <Row>
                <Col>
                  <FontAwesomeIcon icon={faTableCellsLarge} /> Panel
                </Col>
              </Row>
              <Form className="row gy-2 gx-3 mb-1 align-items-center">
                <div className="col-sm-6">
                  <label
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  >
                    Panel Width
                  </label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-text">Width</div>
                    <input
                      className={`form-control form-control-sm`}
                      type="number"
                      name="panelWidth"
                      min="1"
                      step="1"
                      max={maxPanelWidth}
                      value={activeScreen ? activeScreen.panelWidth : ""}
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in pixels"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  >
                    Panel Height
                  </label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-text">Height</div>
                    <input
                      className={`form-control form-control-sm`}
                      type="number"
                      name="panelHeight"
                      min="1"
                      step="1"
                      max={maxPanelHeight}
                      value={activeScreen ? activeScreen.panelHeight : ""}
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in pixels"
                    />
                  </div>
                </div>
              </Form>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faBook} /> Panel Type
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Filter Panel Library"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <Form.Control
                    as="select"
                    value={selectedPanelType}
                    onChange={(e) => handlePanelTypeChange(activeScreenId, e)}
                  >
                    <option value="custom">Custom Panel Type</option>
                    {filteredPanelTypes.map((panelType) => (
                      <option key={panelType.id} value={panelType.id}>
                        {panelType.manufacturer} - {panelType.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
              {/*port limit*/}
              {subscriptionDetails.active ? (<></>):(
                <>
              <Row>
                <Col>
                  <FontAwesomeIcon icon={faGauge} /> Port Limit
                </Col>
              </Row>
              <Form>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Rectangular Ports?"
                    name="rectangularPorts"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.rectangularPorts}
                  />
                </Form.Group>
              </Form>
              {activeScreen.rectangularPorts ? (
                <Row>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Port Width Limit
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Port Width Limit"
                        type="number"
                        min="1"
                        step="1"
                        value={activeScreen ? activeScreen.portWidthLimit : 2}
                        name="portWidthLimit"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Port Height Limit
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Port Height Limit"
                        type="number"
                        min="1"
                        step="1"
                        value={activeScreen ? activeScreen.portHeightLimit : 2}
                        name="portHeightLimit"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              ) : (
                <form className="row gy-2 gx-3 mb-1 align-items-center">
                  <div className="col-sm-12">
                    <label
                      className="visually-hidden"
                      htmlFor="autoSizingInputGroup"
                    >
                      Port Limit
                    </label>
                    <div className="input-group input-group-sm">
                      <div className="input-group-text">Port Limit</div>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        name="portSize"
                        min="0"
                        step="1"
                        value={activeScreen ? activeScreen.portSize : ""}
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                        placeholder="in pixels"
                      />
                    </div>
                  </div>
                </form>
              )}
              </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/*show processor options if subscribed */}
          {subscriptionDetails.active ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <FontAwesomeIcon icon={faServer} />
              &nbsp;Processor Options
            </Accordion.Header>
            <Accordion.Body>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faServer} /> Processor Type
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="processorType"
                    value={activeScreen.processorType}
                    onChange={(e) => handleProcessorChange(activeScreenId, e)}
                  >
                    
                    {Object.keys(processorData).map((processor) => (
                      <option key={processor} value={processor}>
                        {processor}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faBook} /> Video Frame Rate
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="processorFrameRate"
                    value={activeScreen.processorFrameRate}
                    onChange={(e) => handleFrameRateChange(activeScreenId, e)}
                  >
                    
                    {processorData[selectedProcessor]?.frameRateOptions.map(
                      (frameRate) => (
                        <option key={frameRate} value={frameRate}>
                          {frameRate}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Form>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faNetworkWired} /> Network Bit Depth
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="processorBitDepth"
                    value={activeScreen.processorBitDepth}
                    onChange={(e) => handleBitDepthChange(activeScreenId, e)}
                  >
                    
                    {processorData[selectedProcessor]?.bitDepthOptions.map(
                      (bitDepth) => (
                        <option key={bitDepth} value={bitDepth}>
                          {bitDepth}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Form>
              <Form className="mb-1">
                <Form.Group>
                  <Form.Label>
                    <FontAwesomeIcon icon={faEthernet} /> Data Rate
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="processorDataRate"
                    value={activeScreen.processorDataRate}
                    onChange={(e) => handleDataRateChange(activeScreenId, e)}
                  >
                    
                    {processorData[selectedProcessor]?.dataRateOptions.map(
                      (dataRate) => (
                        <option key={dataRate} value={dataRate}>
                          {dataRate}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Form>
              {/*port limit*/}
              <Row>
                <Col>
                  <FontAwesomeIcon icon={faGauge} /> Port Limit
                </Col>
              </Row>
              <Form>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Rectangular Ports?"
                    name="rectangularPorts"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.rectangularPorts}
                  />
                </Form.Group>
              </Form>
              {activeScreen.rectangularPorts ? (
                <Row>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Port Width Limit
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Port Width Limit"
                        type="number"
                        min="1"
                        step="1"
                        value={activeScreen ? activeScreen.portWidthLimit : 2}
                        name="portWidthLimit"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Port Height Limit
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Port Height Limit"
                        type="number"
                        min="1"
                        step="1"
                        value={activeScreen ? activeScreen.portHeightLimit : 2}
                        name="portHeightLimit"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              ) : (
                <form className="row gy-2 gx-3 mb-1 align-items-center">
                  <div className="col-sm-12">
                    <label
                      className="visually-hidden"
                      htmlFor="autoSizingInputGroup"
                    >
                      Port Limit
                    </label>
                    <div className="input-group input-group-sm">
                      <div className="input-group-text">Port Limit</div>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        name="portSize"
                        min="0"
                        step="1"
                        value={activeScreen ? activeScreen.portSize : ""}
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                        placeholder="in pixels"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Accordion.Body>
          </Accordion.Item>
          ) :(<> </>)}
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <FontAwesomeIcon icon={faLayerGroup} />
              &nbsp;Overlays
            </Accordion.Header>
            <Accordion.Body>
              <Form>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Port Index Start
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Screen port start"
                    type="number"
                    min="0"
                    step="1"
                    value={activeScreen ? activeScreen.portStart : 1}
                    name="portStart"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
              </Form>
              <Form className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faArrowTrendUp} /> Data Flow
                </Form.Label>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Data Flow"
                    name="showDataFlow"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showDataFlow}
                  />
                </Form.Group>

                <Form.Group size="sm">
                  <Row>
                    {Array.from({ length: 16 }, (_, i) => i).map((value) => (
                      <Col xs="3" key={value}>
                        <Form.Check
                          key={value} // Add a unique key prop
                          inline
                          //label={(value + 1).toString()}
                          label={getArrowImage(value)}
                          name="dataFlow"
                          type="radio"
                          value={value}
                          checked={activeScreen.dataFlow === value}
                          onChange={(e) => handleInputChange(activeScreenId, e)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Form>

              <Form className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> Panel
                  Coordinates
                </Form.Label>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Panel Coordinates"
                    name="showCoordinates"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showCoordinates}
                  />
                </Form.Group>
                <Row>
                  {Array.from({ length: 4 }, (_, i) => i).map((value) => (
                    <Col xs="3" key={value}>
                      <Form.Check
                        key={value} // Add a unique key prop
                        inline
                        //label={(value + 1).toString()}
                        label={getCoordinateStyleLabel(value)}
                        name="coordinateStyle"
                        type="radio"
                        value={value}
                        checked={activeScreen.coordinateStyle === value}
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </Col>
                  ))}
                </Row>
              </Form>

              <Form className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faMoneyBill} /> Test Patterns
                </Form.Label>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Scaling Card"
                    name="showScalingCard"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showScalingCard}
                  />
                </Form.Group>
              </Form>
              <Form className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faFont} /> Text Overlays
                </Form.Label>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Screen Name"
                    name="showScreenName"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showScreenName}
                  />
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Specifications"
                    name="showScreenSpecs"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showScreenSpecs}
                  />
                </Form.Group>
              </Form>
              <Form className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faPanorama} /> User Logo
                </Form.Label>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show User Logo"
                    name="showLogo"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    checked={activeScreen.showLogo}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Logo Offset X
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Logo Offset X"
                        type="number"
                        min="0"
                        step="1"
                        value={activeScreen ? activeScreen.logoOffsetX : 1}
                        name="logoOffsetX"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        Logo Offset Y
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Logo Offset Y"
                        type="number"
                        min="0"
                        step="1"
                        value={activeScreen ? activeScreen.logoOffsetY : 1}
                        name="logoOffsetY"
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Form.Label>Upload User Logo</Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  accept="image/*"
                  onChange={(e) => handleLogoUpload(activeScreenId, e)}
                />
              </Form>
              {/*borders*/}
              <div id="row">
                <div id="col">
                  <FontAwesomeIcon icon={faVectorSquare} /> Borders
                </div>
              </div>
              <form className="row gy-2 gx-3 mb-3 align-items-center">
                <div className="col-sm">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">Panel Border Width</span>
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="showPanelBorder"
                        checked={
                          activeScreen ? activeScreen.showPanelBorder : false
                        }
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                        aria-label="Checkbox for following text input"
                      />
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      name="borders.panel.width"
                      min="0"
                      step="1"
                      value={
                        activeScreen ? activeScreen.borders.panel.width : ""
                      }
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in pixels"
                      aria-label="Text input with checkbox"
                    />
                    <input
                      type="color"
                      className="form-control form-control-sm form-control-color"
                      name="borders.panel.color"
                      id="exampleColorInput"
                      value={
                        activeScreen ? activeScreen.borders.panel.color : ""
                      }
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      title="Choose your color"
                    ></input>
                  </div>
                </div>
              </form>
              <form className="row gy-2 gx-3 mb-3 align-items-center">
                <div className="col-sm">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">
                      Screen Border Width
                    </span>
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="showScreenBorder"
                        checked={
                          activeScreen ? activeScreen.showScreenBorder : false
                        }
                        onChange={(e) => handleInputChange(activeScreenId, e)}
                        aria-label="Checkbox for following text input"
                      />
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      name="borders.screen.width"
                      min="0"
                      step="1"
                      value={
                        activeScreen ? activeScreen.borders.screen.width : ""
                      }
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      placeholder="in pixels"
                      aria-label="Text input with checkbox"
                    />
                    <input
                      type="color"
                      className="form-control form-control-sm form-control-color"
                      name="borders.screen.color"
                      id="exampleColorInput"
                      value={
                        activeScreen ? activeScreen.borders.screen.color : ""
                      }
                      onChange={(e) => handleInputChange(activeScreenId, e)}
                      title="Choose your color"
                    ></input>
                  </div>
                </div>
              </form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <FontAwesomeIcon icon={faPalette} />
              &nbsp;Color Options
            </Accordion.Header>
            <Accordion.Body>
              {/* panel colors */}
              <Form>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Panel Color Method
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Panel Coloring select"
                    value={activeScreen ? activeScreen.coloringMode : "default"}
                    name="coloringMode"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  >
                    <option value="default">default</option>
                    <option value="port-based">port based</option>
                    <option value="port-based-gradient">
                      port based gradient
                    </option>
                  </Form.Select>
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">Panel 1</InputGroup.Text>
                  <Form.Control
                    type="color"
                    className="form-control form-control-sm form-control-color"
                    name="colors[0]"
                    id="exampleColorInput"
                    value={activeScreen ? activeScreen.colors[0] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    title="Choose your color"
                  />
                  <Form.Control
                    className="form-control form-control-sm"
                    name="opacity[0]"
                    min="0"
                    max="1"
                    step="0.1"
                    type="range"
                    value={activeScreen ? activeScreen.opacity[0] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">Panel 2</InputGroup.Text>
                  <Form.Control
                    type="color"
                    className="form-control form-control-sm form-control-color"
                    name="colors[1]"
                    id="exampleColorInput"
                    value={activeScreen ? activeScreen.colors[1] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    title="Choose your color"
                  />
                  <Form.Control
                    className="form-control form-control-sm"
                    name="opacity[1]"
                    min="0"
                    max="1"
                    step="0.1"
                    type="range"
                    value={activeScreen ? activeScreen.opacity[1] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">Panel 3</InputGroup.Text>
                  <Form.Control
                    type="color"
                    className="form-control form-control-sm form-control-color"
                    name="colors[2]"
                    id="exampleColorInput"
                    value={activeScreen ? activeScreen.colors[2] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    title="Choose your color"
                  />
                  <Form.Control
                    className="form-control form-control-sm"
                    name="opacity[2]"
                    min="0"
                    max="1"
                    step="0.1"
                    type="range"
                    value={activeScreen ? activeScreen.opacity[2] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">Panel 4</InputGroup.Text>
                  <Form.Control
                    type="color"
                    className="form-control form-control-sm form-control-color"
                    name="colors[3]"
                    id="exampleColorInput"
                    value={activeScreen ? activeScreen.colors[3] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    title="Choose your color"
                  />
                  <Form.Control
                    className="form-control form-control-sm"
                    name="opacity[3]"
                    min="0"
                    max="1"
                    step="0.1"
                    type="range"
                    value={activeScreen ? activeScreen.opacity[3] : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
              </Form>
              <Form>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Data Arrow Color
                  </InputGroup.Text>
                  <Form.Control
                    type="color"
                    className="form-control form-control-sm form-control-color"
                    name="dataColor"
                    id="exampleColorInput"
                    value={activeScreen ? activeScreen.dataColor : ""}
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                    title="Choose your color"
                  />
                </InputGroup>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* show single screen name input if not subscribed*/}
        {subscriptionDetails.active ? (
          <></>
        ) : (
          <>
            <Row>
              <Col>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Screen Name
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Screen Name"
                    type="text"
                    value={activeScreen ? activeScreen.name : "default screen"}
                    name="name"
                    onChange={(e) => handleInputChange(activeScreenId, e)}
                  />
                </InputGroup>
              </Col>
            </Row>

            <div className="d-grid gap-2 mb-3">
              <button
                className="btn btn-success"
                type="button"
                onClick={handleShowUpgradeModal}
              >
                Add a Screen
              </button>
            </div>
          </>
        )}

        {/*console.log(screens)*/}

        {subscriptionDetails.active ? (
          screens.map((screen) => (
            <div className="input-group input-group-sm mb-3" key={screen.id}>
              <button
                className={`btn ${
                  activeScreenId === screen.id
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                type="button"
                key={screen.id}
                onClick={() => handleSetActiveScreen(screen.id)}
              >
                Activate
              </button>
              <input
                name="name"
                type="text"
                value={screen.name}
                className="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                onChange={(e) => handleInputChange(screen.id, e)}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => handleDuplicateScreen(screen)}
              >
                Duplicate
              </button>
              <button
                className={`btn ${
                  activeScreenId === screen.id
                    ? "btn-outline-danger"
                    : "btn-secondary disabled"
                }`}
                type="button"
                onClick={() => handleResetScreen(screen.id)}
              >
                Reset
              </button>
              <button
                className={`btn ${
                  activeScreenId === screen.id
                    ? "btn-secondary disabled"
                    : "btn-danger"
                }`}
                type="button"
                onClick={() => handleDeleteScreen(screen.id)}
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <></>
        )}
        {subscriptionDetails && subscriptionDetails.active && (
          <div className="d-grid gap-2 mb-3">
            <button
              className="btn btn-success"
              type="button"
              onClick={handleAddScreen}
            >
              Add Screen
            </button>
          </div>
        )}
        <div className="d-grid gap-2 mb-3">
          <button className="btn btn-danger" type="button" onClick={handleShow}>
            Reset Project
          </button>
        </div>
        <div className="d-grid gap-2 mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={toggleCanvasScaling}
          >
            {isCanvasScaled ? "Scaled to Fit" : "Actual Size"}
          </button>
        </div>
        {subscriptionDetails && !subscriptionDetails.active && (
          <div className="d-grid gap-2 mb-3">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handleScreenExport}
            >
              Export Screen PNG
            </button>
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to reset the project? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleResetProject}>
            Reset Project
          </Button>
        </Modal.Footer>
      </Modal>
      {/* upgrade modal*/}
      <Modal show={showUpgradeModal} onHide={handleCloseUpgradeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Unlock multiple screen management features by upgrading to a paid
          subscription.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpgradeModal}>
            Close
          </Button>
          <Button variant="primary" as={Link} to="/upgrade">
            Upgrade Now
          </Button>
        </Modal.Footer>
      </Modal>
      {/* validation modal*/}
      <Modal show={showValidationModal} onHide={handleCloseValidationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Maximum Canvas Size Exceeded!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have entered a combination of values that exceed the width or
          height limitation of <b>32,767</b> pixels. Please reduce the screens
          width and height, or panel width and height to continue.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseValidationModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ScreenControls;

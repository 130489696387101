import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Form,
  Offcanvas,
  Modal,
  Alert,
} from "react-bootstrap";
import { useTheme } from "../contexts/ThemeContext";
import LogoDark from "../assets/blinkingthigns-logo-horizontal-color-dark-mode.png";
import LogoLight from "../assets/blinkingthigns-logo-horizontal-color.png";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import SignInForm from "./auth/SignInForm";
import SignUp from "./auth/SignUpForm";
import { useModal } from "../contexts/ModalContext";
import { useSubscriber } from "../contexts/SubscriptionContext";

function Header() {
  const { theme, setTheme } = useTheme();
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    theme === "light" ? setLogo(LogoDark) : setLogo(LogoLight);
  };
  const [logo, setLogo] = useState(LogoLight);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { currentUser, signOut, isEmailVerified, sendPasswordResetLink } =
    useAuth();
  const {
    handleCloseSignInModal,
    handleCloseSignUpModal,
    handleShowSignInModal,
    handleShowSignUpModal,
    showSignInModal,
    showSignUpModal,
    showModal,
    setModalContent,
    hideModal,
  } = useModal();
  //subscription check
  const { subscriptionDetails } = useSubscriber();

  useEffect(() => {
    if (currentUser && isEmailVerified) {
      handleCloseSignInModal(); // Close sign-in modal if user is logged in and email is verified
      handleCloseSignUpModal(); // Optionally, close sign-up modal too
    }
  }, [currentUser]); // Depend on currentUser to re-check whenever the current user changes

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    // Optionally, set a timeout to automatically hide the alert after a few seconds
    setTimeout(() => setShowAlert(false), 5000); // Hides the alert after 5 seconds
  };

  const handleSendPasswordReset = async () => {
    if (currentUser?.email) {
      try {
        await sendPasswordResetLink(currentUser.email);
        setModalContent(
          <>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              A password reset link has been sent to your email addres. Please
              check your inbox.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </>
        );
        showModal();
      } catch (error) {
        console.error("Error sending password reset email:", error);
        // Adjust this to use your modal or alert system for error messages
        setModalContent(
          <>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Failed to send password reset email. Please try again later.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </>
        );
        showModal();
      }
    } else {
      console.error("No user email found.");
      setModalContent(
        <>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Could not find your email address. Please ensure you're logged in.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </>
      );
      showModal();
    }
  };
  
  return (
    <>
      <Navbar expand="lg" className="bg-body-secondary">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              className="navLogo mx-1"
              alt="blinking things logo"
              src={theme === "light" ? LogoLight : LogoDark}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <img
                  className="navLogo mx-1"
                  alt="pixel mapulator logo"
                  src={theme === "light" ? LogoLight : LogoDark}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="align-items-center">
              {isEmailVerified ? (
                <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/pixel-map-generator">
                    pixel map generator
                  </Nav.Link>
                  <Nav.Link as={Link} to="/scanline-calculator">
                    scanline calculator
                  </Nav.Link>
                  <Nav.Link as={Link} to="/curving-calculator">
                    curving calculator
                  </Nav.Link>
                  {/* Add more navigation links as needed */}
                </Nav>
              ) : (
                <>
                {/* <Nav.Link as={Link} to="/pricing">
                    pricing
                  </Nav.Link> */}
                </>
              )}
              {/* Right aligned items */}
              <Nav className="justify-content-end flex-grow-1 pe-2">
                {isEmailVerified ? (
                  <>
                    {subscriptionDetails.active ? (
                      <></>
                    ) : (
                      <Link to="/upgrade">
                        <Button className="btn btn mx-1" variant="primary">
                          upgrade
                        </Button>
                      </Link>
                    )}
                    <Nav.Link as={Link} to="/help">
                      help
                    </Nav.Link>
                    <Nav.Link
                      href="https://discord.com/invite/VHabWRVBzU"
                      target="_blank"
                    >
                      discord
                    </Nav.Link>
                    <Nav.Link as={Link} to="/panel-library">
                      panel library
                    </Nav.Link>
                    <NavDropdown title={currentUser.email} id="user-dropdown">
                      <NavDropdown.Item as={Link} to="/user">
                        User Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="#resetPassword"
                        onClick={handleSendPasswordReset}
                      >
                        Change Password
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/" onClick={signOut}>
                        Sign Out
                      </NavDropdown.Item>
                    </NavDropdown>
                    {showAlert && (
                      <Alert
                        variant="info"
                        onClose={() => setShowAlert(false)}
                        dismissible
                      >
                        {alertMessage}
                      </Alert>
                    )}
                    <Form className="d-flex">
                    <Button
                      className="btn-sm mx-1"
                      variant={
                        theme === "light"
                          ? "outline-secondary"
                          : "outline-light"
                      }
                      onClick={toggleTheme}
                    >
                      {theme === "light" ? "Dark Mode" : "Light Mode"}
                    </Button>
                    </Form>
                  </>
                ) : (
                  <>
                    {/* <Nav className="justify-content-end pe-2">
                    <Nav.Link as={Link} to="/features">
                      Features
                    </Nav.Link>
                  </Nav> */}
                    {/* <Nav.Link as={Link} to="/pricing">
                        contact
                      </Nav.Link> */}
                    <Button
                      className="btn btn mx-1"
                      variant="outline-secondary"
                      onClick={handleShowSignInModal}
                    >
                      Log In
                    </Button>
                    <Button
                      className="btn btn mx-1 btn-primary"
                      onClick={handleShowSignUpModal}
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/* SignInForm Modal */}
      <Modal show={showSignInModal} onHide={handleCloseSignInModal}>
        <SignInForm
          handleShowSignUpModal={handleShowSignUpModal}
          hideSignInModal={handleCloseSignInModal}
        />
      </Modal>
      <Modal show={showSignUpModal} onHide={handleCloseSignUpModal}>
        <SignUp
          handleShowSignInModal={handleShowSignInModal}
          hideSignUpModal={handleCloseSignUpModal}
        />
      </Modal>
    </>
  );
}

export default Header;

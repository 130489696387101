import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProjectState } from "../../actions/index"; // Update this path
import { generateUniqueId, defaultScreen } from "../../reducers";
import { updateImage } from "../utilities/PixelMapUtils";
import { updateOutputImage } from "../../actions/index";
import store from "../../store";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { auth } from "../../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileArrowDown,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";

function ProjectControls({ isCanvasScaled, toggleCanvasScaling }) {
  const dispatch = useDispatch();
  const outputs = useSelector((state) => state.project.outputs);
  const screens = useSelector((state) => state.project.screens);
  const projectName = useSelector((state) => state.project.projectName);
  const projectState = useSelector((state) => state.project); // Assuming your project state is stored here

  const saveUserProjectUri = process.env.REACT_APP_PROJECTS;

  const [exportOptions, setExportOptions] = useState({
    projectJson: true,
    outputImages: false,
    screenImages: false,
    textDescription: false,
    csvScreenSchedule: false,
  });

  const regenerateScreenImages = (screens, dispatch) => {
    // Create a promise that resolves when all screens are processed
    return Promise.all(
      screens.map((screen) => {
        // Assuming updateImage returns a promise
        return updateImage(screen, dispatch);
      })
    );
  };

  const regenerateOutputImages = (outputs, screens, dispatch) => {
    //console.log("reggen output images");
    //console.log("screens checkk : ", screens);
    //console.log("outputs check :", outputs);
    outputs.forEach((output) => {
      const outputScreens = screens.filter(
        (screen) => screen.assignedOutput === output.id
      );
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = output.width;
      canvas.height = output.height;

      // Set background color
      ctx.fillStyle = output.bgcolor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw screens on the output canvas
      outputScreens.forEach((screen) => {
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(image, screen.offsetX, screen.offsetY);
          // After all screens are drawn, update the Redux state with the output image
          if (screen === outputScreens[outputScreens.length - 1]) {
            const imageData = canvas.toDataURL("image/png");
            dispatch(updateOutputImage(output.id, imageData));
          }
        };
        image.src = `data:image/png;base64,${screen.image}`;
      });
    });
  };

  const generateScreenScheduleCSV = (screens, outputs) => {
    // CSV Header
    let csvContent = "Screen Name,Assigned Output,Resolution Width,Resolution Height,Offset X,Offset Y,SubScreen\n";

    // Populate CSV with screen and subscreen data
    screens.forEach(screen => {
        const assignedOutputName = outputs.find(output => output.id === screen.assignedOutput)?.name || 'N/A';
        const resolutionWidth = screen.mapWidth * screen.panelWidth;
        const resolutionHeight = screen.mapHeight * screen.panelHeight;
        
        if (screen.subScreens.length === 0) {
            // Screen without subscreens
            const row = [
                screen.name,
                assignedOutputName,
                resolutionWidth,
                resolutionHeight,
                screen.offsetX,
                screen.offsetY,
                'n/a'
            ].join(',');
            csvContent += row + "\n";
        } else {
            // Screen with subscreens, list the main screen with n/a for offsets and subscreen number
            const mainScreenRow = [
                screen.name,
                'n/a',
                resolutionWidth,
                resolutionHeight,
                'n/a',
                'n/a',
                'n/a'
            ].join(',');
            csvContent += mainScreenRow + "\n";

            // List each subscreen
            screen.subScreens.forEach((subScreen, index) => {
                const subScreenOutputName = outputs.find(output => output.id === subScreen.assignedOutput)?.name || 'N/A';
                const subScreenRow = [
                    screen.name,
                    subScreenOutputName,
                    subScreen.width,
                    subScreen.height,
                    subScreen.offsetX,
                    subScreen.offsetY,
                    index + 1 // SubScreen number
                ].join(',');
                csvContent += subScreenRow + "\n";
            });
        }
    });

    return csvContent;
};

  // Inside ProjectControls component

  const handleProjectChange = (event) => {
    const { name, value } = event.target;
    dispatch(setProjectState({ ...projectState, [name]: value }));
  };

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;
    setExportOptions({ ...exportOptions, [name]: checked });
  };

  function base64ToBlob(base64, mime) {
    // Check if base64 string contains the Data URI scheme. If not, prepend it.
    const dataURI = base64.indexOf('data:image/png;base64,') === 0 ? base64 : `data:image/png;base64,${base64}`;
    
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mime});
}



  const handleExportProject = async () => {
    const zip = new JSZip();
    const folderNames = {
      outputImages: "output_images",
      screenImages: "screen_images",
    };

    
    // Clone the project state to avoid mutating the original state
    const clonedProjectState = JSON.parse(JSON.stringify(projectState));

    // Remove image data from screens
    clonedProjectState.screens.forEach(screen => {
        delete screen.image;
    });

    // Remove image data from outputs
    clonedProjectState.outputs.forEach(output => {
        delete output.image;
    });

    const projectDataStr = JSON.stringify(clonedProjectState, null, 2);
    

    //save user-project to db on export
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(saveUserProjectUri, {
        userEmail: auth.currentUser.email,
        projectJson: clonedProjectState // project without image data
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        //console.log('Project saved successfully');
      }
    } catch (error) {
      console.error('Error saving project:', error);
    }
    
    // Add project JSON if selected
    if (exportOptions.projectJson) {
      zip.file("project.json", projectDataStr);
    }

    // Export output images if selected
    if (exportOptions.outputImages) {
        const outputImagesFolder = zip.folder("output_images");
        projectState.outputs.forEach(output => {
            if (output.image) {
                const blob = base64ToBlob(output.image, "image/png");
                outputImagesFolder.file(`${output.name}.png`, blob);
            }
        });
    }
    // Export screen images if selected
    if (exportOptions.screenImages) {
        const screenImagesFolder = zip.folder("screen_images");
        projectState.screens.forEach(screen => {
            if (screen.image) {
                const blob = base64ToBlob(screen.image, "image/png");
                screenImagesFolder.file(`${screen.name}.png`, blob);
            }
        });
    }
    // Export text descriptions
    if (exportOptions.textDescription) {
      let textDescription = `Project: ${projectState.projectName}\n\n`;
  
      // Section for listing all screens before splits
      textDescription += `All Screens:\n`;
      projectState.screens.forEach(screen => {
          textDescription += `  - Screen Name: ${screen.name}\n    Resolution: ${screen.mapWidth * screen.panelWidth} x ${screen.mapHeight * screen.panelHeight + (screen.halfRow ? (screen.panelHeight / 2) : 0)}\n`;
          if (screen.subScreens.length === 0) {
              textDescription += `    Offset: x=${screen.offsetX}, y=${screen.offsetY}\n    SubScreens: n/a\n`;
          } else {
              textDescription += `    Offset: n/a\n    SubScreens: ${screen.subScreens.length}\n`;
          }
          textDescription += '\n';
      });
  
      // Section for listing all outputs and their corresponding screens and subscreens
      textDescription += `Outputs and Assigned Screens/SubScreens:\n`;
      projectState.outputs.forEach(output => {
          textDescription += `Output Name: ${output.name}\nResolution: ${output.width} x ${output.height}\nScreens:\n`;
  
          // List screens without subscreens assigned to the current output
          projectState.screens
              .filter(screen => screen.assignedOutput === output.id && screen.subScreens.length === 0)
              .forEach(screen => {
                  textDescription += `  - Screen Name: ${screen.name}\n    Resolution: ${screen.mapWidth * screen.panelWidth} x ${screen.mapHeight * screen.panelHeight + (screen.halfRow ? (screen.panelHeight / 2) : 0)}\n    Offset: x=${screen.offsetX}, y=${screen.offsetY}\n`;
              });
  
          // List subscreens assigned to the current output
          projectState.screens.forEach(screen => {
              screen.subScreens
                  .filter(subScreen => subScreen.assignedOutput === output.id)
                  .forEach((subScreen, index) => {
                      textDescription += `  - SubScreen Name: ${screen.name} - SubScreen ${screen.subScreens.indexOf(subScreen) + 1}\n    Resolution: ${subScreen.width} x ${subScreen.height}\n    Offset: x=${subScreen.offsetX}, y=${subScreen.offsetY}\n`;
                  });
          });
  
          textDescription += '\n';
      });
  
      zip.file("text_description.txt", textDescription);
  }
  
  
    if (exportOptions.csvScreenSchedule){
        const csvContent = generateScreenScheduleCSV(screens, outputs);
        zip.file("screen_schedule.csv", csvContent)
    }

    // Add other elements based on selection...
    // You would need to implement logic for each type of content being exported,
    // similar to how you'd handle project JSON but adjusted for images, CSVs, etc.

    // Generate zip file and save
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${projectState.projectName || "project"}.zip`);
    });
  };

  const handleImportProject = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      try {
        const importedProject = JSON.parse(content);

        // Immediately update the state with the imported project
        dispatch(setProjectState(importedProject));

        // Wait for the screen images to regenerate
        regenerateScreenImages(importedProject.screens, dispatch).then(() => {
          // After screen images are updated, proceed with output images regeneration
          // Fetch the possibly updated state directly from the store if necessary
          const updatedProjectState = store.getState().project; // Accessing state directly from the store
          //console.log("screens for output regen : ",projectState.screens)
          //console.log("store value : ",updatedProjectState.screens)
          regenerateOutputImages(
            importedProject.outputs,
            updatedProjectState.screens,
            dispatch
          );
        });
      } catch (error) {
        console.error("Error importing project:", error);
        alert(
          "Failed to import project. Please ensure the file is a valid JSON."
        );
      }
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <form className="row gy-2 gx-3 mb-5 align-items-center">
        <div className="col-sm">
          <label className="visually-hidden" htmlFor="autoSizingInputGroup">
            Project Name
          </label>
          <div className="input-group input-group-sm">
            <div className="input-group-text">Project Name</div>
            <input
              className="form-control form-control-sm"
              type="text"
              name="projectName"
              value={projectState.projectName}
              onChange={handleProjectChange}
              placeholder="projectName"
            />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col">
          <label><FontAwesomeIcon icon={faFileArrowDown} />&nbsp;Export Project</label>
        </div>
      </div>
      <div className="row">
        <div className="col">
        <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="projectJson"
              checked={exportOptions.projectJson}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Project JSON
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="screenImages"
              checked={exportOptions.screenImages}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Screen Images
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="outputImages"
              checked={exportOptions.outputImages}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Output Images
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="textDescription"
              checked={exportOptions.textDescription}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Text Description
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="csvScreenSchedule"
              checked={exportOptions.csvScreenSchedule}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Screen Schedule Spreadsheet
            </label>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleExportProject}
          >
            Export Selected
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <label><FontAwesomeIcon icon={faFileImport} />&nbsp;Import Project</label>
          <input
            type="file"
            onChange={handleImportProject}
            className="form-control form-control-sm"
          />
        </div>
      </div>
      {/* text output */}
      <div className="row mb-3">
  <div className="col">
    <pre className="themed-pre">
      <code>
        Project: <var>{projectName}</var>
        <br />
        <br />
        Screens:
        {screens
          //.filter((screen) => screen.subScreens.length > 0)
          .map((screen, screenIndex) => (
            <React.Fragment key={screenIndex}>
              <br />
              &nbsp;Screen: <var>{screen.name}</var>
              <br />
              &nbsp;&nbsp;Resolution:{" "}
              <var>
                {screen.mapWidth * screen.panelWidth} px x{" "}
                {screen.mapHeight * screen.panelHeight + (screen.halfRow ? screen.panelHeight / 2 : 0)} px
              </var>
              
            </React.Fragment>
          ))}
          <br />
          <br />
        Outputs:
        {outputs.map((output, outputIndex) => (
          <React.Fragment key={outputIndex}>
            <br />
            &nbsp;Output: <var>{output.name}</var>
            <br />
            &nbsp;&nbsp;Resolution:{" "}
            <var>
              {output.width} px x {output.height} px
            </var>
            <br />
            &nbsp;&nbsp;Screens:
            {screens
              .filter(screen => screen.assignedOutput === output.id || screen.subScreens.some(sub => sub.assignedOutput === output.id))
              .map((screen, screenIndex) => (
                <React.Fragment key={screenIndex}>
                  {screen.subScreens && screen.subScreens.length > 0 ? (
                    <>
                      {screen.subScreens
                        .filter(subScreen => subScreen.assignedOutput === output.id)
                        .map((subScreen, subScreenIndex) => (
                          <React.Fragment key={subScreenIndex}>
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Sub Screen: <var>{screen.name}.{subScreen.number}</var>
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resolution:{" "}
                            <var>
                              {subScreen.width} px x {subScreen.height} px
                            </var>
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Offset (x,y): (
                            <var>{subScreen.offsetX}</var>, 
                            <var>{subScreen.offsetY}</var>)
                          </React.Fragment>
                        ))}
                        </>
                  ) : (
                    <React.Fragment>
                      {screen.assignedOutput === output.id && (
                        <React.Fragment>
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;Screen: <var>{screen.name}</var>
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resolution:{" "}
                          <var>
                            {screen.mapWidth * screen.panelWidth} px x{" "}
                            {screen.mapHeight * screen.panelHeight + (screen.halfRow ? screen.panelHeight / 2 : 0)} px
                          </var>
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Offset (x,y): (
                          <var>{screen.offsetX}</var>,
                          <var>{screen.offsetY}</var>)
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </code>
    </pre>
  </div>
</div>

    </div>
  );
}

export default ProjectControls;

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "./AuthContext";

const SubscriberContext = createContext();

export const useSubscriber = () => useContext(SubscriberContext);

const stripeApiUri = process.env.REACT_APP_STRIPE_API;

export const SubscriberProvider = ({ children }) => {
    const { currentUser, isEmailVerified } = useAuth();
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  

  const fetchSubscriptionStatus = async () => {
    //console.log("Attempting to fetch subscription status for", currentUser.email);
  
    try {
      const response = await axios.get(`${stripeApiUri}?email=${encodeURIComponent(currentUser.email)}`);
      const subscription = response.data;
      
      if (subscription.subscribed) {
        //console.log("Subscription is active. Subscription ends on ", subscription.details[0].current_period_end, " today is ", Math.floor(Date.now() / 1000));
        const isSubscriptionActive =
          subscription.details[0].status === "active" &&
          subscription.details[0].current_period_end > Math.floor(Date.now() / 1000);
        
        setIsSubscriber(true);
        setSubscriptionDetails({
          exists: true,
          active: isSubscriptionActive,
          details: subscription,
        });
      } else {
        setIsSubscriber(false);
        setSubscriptionDetails({
          exists: false,
          active: false,
          details: null,
        });
      }
    } catch (error) {
      console.error("Error fetching subsrciption status:", error);
      // Here you can handle errors more specifically based on the error response from Axios
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
    }
  };


  // Placeholder for checking subscription status
  // In a real app, replace this with an API call to your backend
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
        // Assume we have a function to check subscription status from the backend
        await fetchSubscriptionStatus();
        //console.log("subscription details : ",subscriptionDetails);
        //console.log("is subscriber from sub context after it should be set : ",isSubscriber)
        setLoading(false);
      };
    //console.log("use effect for subscription status running")
    if (currentUser && currentUser.email){
        //console.log("user is :",currentUser.email," checking sub status")
        checkSubscriptionStatus();    
    } else {
        //user not logged in so can't be subscribed
        //console.log("no user logged in")
        setIsSubscriber(false);
        setSubscriptionDetails({
            exists: false,
            active: false,
            details: null,
        });
        setLoading(false);
    }
    
    
  }, [isEmailVerified, currentUser]);

  return (
    <SubscriberContext.Provider value={{ isSubscriber,subscriptionDetails,loading }}>
      {!loading && children}
    </SubscriberContext.Provider>
  );
};

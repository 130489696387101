import React, { useState, useEffect } from "react";

import { Modal, Form, Button, Alert, ButtonGroup, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";


const SignUp = ({
  onToggle,
  authUser,
  handleShowSignInModal,
  hideSignUpModal,
}) => {
  
  const [agreementStatus, setAgreementStatus] = useState(false);
  const { signUp, setShowError,setErrorMessage, showError, errorMessage,setShowAlert,setEmail,setPassword,showAlert,email,password  } = useAuth();

  const userAgreementApiUri = process.env.REACT_APP_USER_AGREEMENT_API;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(email, password, agreementStatus);
      // Redirect to email verification page or main content
    } catch (error) {
      console.error(error);
      // Handle sign-up errors (e.g., email already in use)
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Sign up for blinking things</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Check
              className="mt-2"
              type="checkbox"
              label="I agree to the Privacy Policy"
              checked={agreementStatus}
              onChange={(e) => setAgreementStatus(e.target.checked)}
            />
            <Form.Text className="text-muted">
              By signing up, you agree to our{" "}
              <Link to="/privacy-policy" onClick={hideSignUpModal}>
                Privacy Policy
              </Link>
              .
            </Form.Text>
          </Form.Group>
          <ButtonGroup className="mb-3">
            <Button
              variant="primary"
              type="submit"
              disabled={!agreementStatus}
            >
              Create an Account
            </Button>
          </ButtonGroup>
          <br></br>
          <p>
            Already Registered?{" "}
            <a href="#" onClick={handleShowSignInModal}>
              Sign In
            </a>
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {showAlert && ( // Conditionally render the alert
          <Alert
            variant="success"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            A verification email has been sent to {email}. Please check your
            inbox to proceed with the signup process.
          </Alert>
        )}
        {showError && ( // Conditionally render the alert
          <Alert
            variant="danger"
            onClose={() => setShowError(false)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
      </Modal.Footer>
    </>
  );
};

export default SignUp;

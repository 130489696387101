import React, { useRef, useEffect } from 'react';

const ShutterGraphic = ({ shutterAngle,theme }) => {
  const canvasRef = useRef(null);
  const radius = 20;
  const padding = 60

  const drawShutter = (ctx, angle, radius, padding) => {
    
    const radians = (angle * Math.PI) / 180;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //colors
 

    ctx.fillStyle = `${theme === "dark" ? "white" : "grey"}`;
    ctx.strokeStyle = `${theme === "dark" ? "white" : "grey"}`;
    // Draw full circle
    ctx.beginPath();
    ctx.arc(padding/2, padding/2, radius, 0, Math.PI * 2);
    //ctx.fillStyle = '#ccc';
    //ctx.fill();
    //ctx.strokeStyle= 'white';
    ctx.stroke();

    // Draw the 'cut-out' slice
    ctx.beginPath();
    ctx.moveTo(padding/2, padding/2);
    ctx.arc(padding/2, padding/2, radius, 0, radians);
    ctx.closePath();
    //ctx.fillStyle = 'white';
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    drawShutter(context, shutterAngle, radius, padding);
  }, [shutterAngle, theme]);

  return <canvas ref={canvasRef} width={padding} height={padding} />;
};

export default ShutterGraphic;

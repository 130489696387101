import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import PrivacyPolicyText from "./PrivacyPolicyText";

const PrivacyPolicy = () => {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-sm-center">
          <Col md="6" sm="8">
            <PrivacyPolicyText />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicy;

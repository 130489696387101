import { ADD_SCREEN, UPDATE_SCREEN_DATA, SET_ACTIVE_SCREEN, DELETE_SCREEN, DUPLICATE_SCREEN, RESET_ACTIVE_SCREEN, RESET_PROJECT, ADD_OUTPUT, UPDATE_OUTPUT, DELETE_OUTPUT, SET_ACTIVE_OUTPUT, DUPLICATE_OUTPUT, RESET_ACTIVE_OUTPUT, SET_PROJECT_STATE, SPLIT_SCREEN, ASSIGN_SUB_SCREEN, UNDO_SPLIT } from './actionTypes';
import { generateUniqueId } from '../reducers';
import { updateImage } from '../components/utilities/PixelMapUtils';

export const setInputValue = (name, value) => ({
    type: 'SET_INPUT_VALUE',
    payload: { name, value }
});

export const setPixelMapImage = (screenId, imageData) => ({
    type: 'SET_PIXEL_MAP_IMAGE',
    payload: { screenId, imageData }
});


// Action creators
export const addScreen = (screenData) => {
    return { type: ADD_SCREEN, screenData };
};

export const updateScreenData = (screenId, newData) => {
    return { type: UPDATE_SCREEN_DATA, screenId, newData };
};

export const setActiveScreen = (screenId) => {
    return { type: SET_ACTIVE_SCREEN, screenId };
};

export const deleteScreen = (screenId) => ({
    type: DELETE_SCREEN,
    payload: screenId,
});

export const duplicateScreen = (screen) => ({
    type: DUPLICATE_SCREEN,
    payload: { ...screen, id: generateUniqueId() }, // Ensure a new ID is generated for the duplicate
});

export const resetActiveScreen = (screenId) => ({
    type: RESET_ACTIVE_SCREEN,
    payload: screenId,
});

export const resetProject = () => ({
    type: RESET_PROJECT,
});

export const addOutput = (outputData) => {
    return { type: ADD_OUTPUT, outputData };
};

export const updateOutput = (outputId, newData) => {
    return { type: UPDATE_OUTPUT, outputId, newData };
};

export const deleteOutput = (outputId) => ({
    type: DELETE_OUTPUT,
    payload: outputId,
});

export const duplicateOutput = (output) => ({
    type: DUPLICATE_OUTPUT,
    payload: { ...output, id: generateUniqueId() }, // Ensure a new ID is generated for the duplicate
});

export const resetActiveOutput = (outputId) => ({
    type: RESET_ACTIVE_OUTPUT,
    payload: outputId,
});

export const setActiveOutput = (outputId) => {
    return { type: SET_ACTIVE_OUTPUT, outputId };
};

export const updateOutputImage = (outputId, imageData) => ({
    type: 'UPDATE_OUTPUT_IMAGE',
    payload: { outputId, imageData },
  });
  
export const setProjectState = (projectState) => ({
  type: SET_PROJECT_STATE,
  payload: projectState,
});

export const splitScreen = (screenId, direction, position) => ({
  type: SPLIT_SCREEN,
  payload: { screenId, direction, position },
});

export const assignSubScreen = (subScreenId, outputId) => ({
  type: ASSIGN_SUB_SCREEN,
  payload: { subScreenId, outputId },
});

export const undoSplit = (subScreenId, parentScreenId) => ({
  type: UNDO_SPLIT,
  payload: { subScreenId, parentScreenId },
});

// Action creator thunk
export const addAndSetActiveScreen = (newScreen) => async (dispatch, getState) => {
    // Dispatch action to add the new screen
    dispatch(addScreen(newScreen));
  
    // Dispatch action to set this new screen as active
    dispatch(setActiveScreen(newScreen.id));
  
    // Now, wait for the state to be updated
    // Since we don't have a direct callback, we simulate waiting by dispatching a thunk that checks the state
    await dispatch(waitForActiveScreen(newScreen.id));
  
    // Once the active screen is confirmed to be the new screen, update its image
    // Fetch the newly added screen from state to ensure we have the latest version
    const updatedState = getState();
    const activeScreen = updatedState.project.screens.find(screen => screen.id === newScreen.id);
    if (activeScreen) {
      updateImage(activeScreen, dispatch);
    }
  };
  
  // Helper thunk to wait for the active screen to be updated in the state
  const waitForActiveScreen = (screenId) => (dispatch, getState) => {
    return new Promise((resolve) => {
      const checkActiveScreen = () => {
        const { activeScreenId } = getState().project;
        if (activeScreenId === screenId) {
          resolve();
        } else {
          setTimeout(checkActiveScreen, 10); // Check again after a short delay
        }
      };
      checkActiveScreen();
    });
  };
  
import { combineReducers } from "redux";
import _ from "lodash";

import {
  ADD_SCREEN,
  UPDATE_SCREEN_DATA,
  SET_ACTIVE_SCREEN,
  DELETE_SCREEN,
  DUPLICATE_SCREEN,
  RESET_ACTIVE_SCREEN,
  RESET_PROJECT,
  ADD_OUTPUT,
  UPDATE_OUTPUT,
  DELETE_OUTPUT,
  SET_ACTIVE_OUTPUT,
  RESET_ACTIVE_OUTPUT,
  DUPLICATE_OUTPUT,
  SET_PROJECT_STATE,
  SPLIT_SCREEN,
  ASSIGN_SUB_SCREEN,
  UNDO_SPLIT,
} from "../actions/actionTypes";

export function generateUniqueId() {
  // Generate a timestamp
  const timestamp = Date.now().toString(36);

  // Generate a random number (between 0 and 1) and convert it to base 36
  const random = Math.random().toString(36).substr(2);

  // Combine the timestamp and random number to create a unique ID
  const uniqueId = timestamp + random;

  return uniqueId;
}

const defaultOutputId = generateUniqueId();

export const defaultScreen = {
  name: "Screen Name",
  mapWidth: 16,
  mapHeight: 9,
  panelWidth: 126,
  panelHeight: 126,
  panelManufacturer: "Custom",
  panelName: "Custom",
  panelWidthInMm: 500,
  panelHeightInMm: 500,
  panelWeightInKg: 20,
  panelWattage: 190,
  offsetX: 0,
  offsetY: 0,
  assignedOutput: defaultOutputId,
  panelyType: null,
  colors: ["#C95B54", "#428BCA", "#FFD97D", "#60D394"],
  opacity: [1, 1, 1, 1],
  rgbacolors: [],
  borders: {
    panel: {
      color: "#FFFFFF",
      width: 1,
    },
    screen: {
      color: "#FFFFFF",
      width: 1,
    },
  },
  dataFlow: 4,
  powerFlow: 0,
  coordinateStyle: 0,
  dataColor: "#000000",
  showCoordinates: true,
  showPanelBorder: true,
  showScreenBorder: false,
  showDataFlow: true,
  showEveryOtherPixel: false,
  showScalingCard: false,
  showScreenName: false,
  showScreenSpecs: false,
  portSize: 300000,
  portStart: 1,
  showLogo: false,
  logoData: null,
  logoOffsetX: 0,
  logoOffsetY: 0,
  coloringMode: "default",
  panelLibraryId: null,
  halfRow: null,
  rectangularPorts: false,
  portWidthLimit : 2,
  portHeightLimit : 2,
  processorType: "Brompton SX40",
  processorFrameRate : 60, //60fps
  processorBitDepth : 8, //8bpc
  processorDatarate : 1, // 1 gbps
  image: null,
  subScreens: [],
  splitHistory:{},
  splitDirection: null,
  splitPosition: null,
};

export const defaultOutput = {
  id: defaultOutputId,
  width: 3840,
  height: 2160,
  color: "#000000",
  name: "Output A",
  image: null,
};

let initialScreenId = generateUniqueId();
const initialState = {
  projectName: "My LED Project",
  screens: [{ ...defaultScreen, id: initialScreenId/* , subScreens:[{id: generateUniqueId(),
    parentId: initialScreenId,
    direction: null,
    position: 0,
    offsetX: 0,
    offsetY: 0,
    assignedOutput: defaultOutputId}] */}],
  outputs: [{ ...defaultOutput }],
  activeScreenId: null,
  activeOutputId: null,
};
initialState.activeScreenId = initialState.screens[0].id;
initialState.activeOutputId = initialState.outputs[0].id;

function projectReducer(state = initialState, action) {
  //console.log("Current state:", state);
  //console.log("Action:", action);
  switch (action.type) {
    case ADD_SCREEN: {
      const newScreen = {
        ...action.screenData,
        //id: generateUniqueId(), // Ensure each screen has a unique ID
        assignedOutput: state.outputs.length > 0 ? state.outputs[0].id : null, // Assign to the first output's ID
      };

      return {
        ...state,
        screens: [...state.screens, newScreen],
      };
    }

    case UPDATE_SCREEN_DATA: {
      const updatedScreens = state.screens.map((screen) => {
        if (screen.id === action.screenId) {
          // Deeply merge the existing screen data with the update
          //console.log("updating screen ", action.screenId, " with data ", action.newData);
          return _.merge({}, screen, action.newData);
        }
        return screen;
      });
      return { ...state, screens: updatedScreens };
    }
    case SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreenId: action.screenId,
      };
    case "SET_PIXEL_MAP_IMAGE":
      const { screenId, imageData } = action.payload;
      //console.log("updating state for screen ",screenId," with image data ",imageData)
      return {
        ...state,
        screens: state.screens.map((screen) =>
          screen.id === screenId ? { ...screen, image: imageData } : screen
        ),
      };
    // Handle deleting a screen
    case DELETE_SCREEN:
      return {
        ...state,
        screens: state.screens.filter((screen) => screen.id !== action.payload),
      };

    // Handle duplicating a screen
    case DUPLICATE_SCREEN:
      return {
        ...state,
        screens: [...state.screens, action.payload],
      };
      case RESET_ACTIVE_SCREEN: {
        // Find the current assignment of the screen being reset to maintain it
        const currentScreenAssignment = state.screens.find(screen => screen.id === action.payload)?.assignedOutput;
      
        // Check if the current assignment exists in the outputs, if not, assign to the first available output
        const existingOutputId = state.outputs.find(output => output.id === currentScreenAssignment) ? currentScreenAssignment : state.outputs[0]?.id;
      
        return {
          ...state,
          screens: state.screens.map((screen) => {
            if (screen.id === action.payload) {
              return {
                ...defaultScreen,
                id: screen.id, // Retain the original ID
                assignedOutput: existingOutputId, // Use the existing assignment if valid, or default to the first output
              };
            }
            return screen;
          }),
        };
      }
      
    case RESET_PROJECT:
      return initialState; // Reset to initial default state
    case ADD_OUTPUT:
      return {
        ...state,

        outputs: [...state.outputs, action.outputData],
      };
    case UPDATE_OUTPUT: {
      const updatedOutputs = state.outputs.map((output) => {
        if (output.id === action.outputId) {
          // Deeply merge the existing screen data with the update
          return _.merge({}, output, action.newData);
        }
        return output;
      });
      return { ...state, outputs: updatedOutputs };
    }
    case DELETE_OUTPUT: {
      const { payload: deletedOutputId } = action;
      // Filter out the deleted output
      const updatedOutputs = state.outputs.filter(
        (output) => output.id !== deletedOutputId
      );
      let updatedScreens = [...state.screens];
      const defaultOutputId =
        updatedOutputs.length > 0 ? updatedOutputs[0].id : null;

      // If an output is being deleted, reassign it's screens to the first existing output
      updatedScreens = state.screens.map((screen) => {
        if (screen.assignedOutput === deletedOutputId) {
          return { ...screen, assignedOutput: defaultOutputId };
        }
        return screen;
      });

      // Determine the new active output ID
      const newActiveOutputId =
        state.activeOutputId === deletedOutputId
          ? defaultOutputId
          : state.activeOutputId;

      return {
        ...state,
        outputs: updatedOutputs,
        screens: updatedScreens,
        activeOutputId: newActiveOutputId,
      };
    }
    case SET_ACTIVE_OUTPUT:
      return {
        ...state,
        activeOutputId: action.outputId,
      };
    case DUPLICATE_OUTPUT:
      return {
        ...state,
        outputs: [...state.outputs, action.payload],
      };
    case RESET_ACTIVE_OUTPUT: {
      // Find the output to reset
      const outputIndex = state.outputs.findIndex(
        (output) => output.id === action.payload
      );

      if (outputIndex === -1) return state; // Output not found, return current state

      // Create a new output object that retains the id and any other properties you don't want to reset
      const resetOutput = {
        ...state.outputs[outputIndex],
        ...defaultOutput,
        id: state.outputs[outputIndex].id, // Retain the original ID
        // Add any other properties here that should be retained after reset
      };

      // Replace the output in the array with the reset output
      const updatedOutputs = [...state.outputs];
      updatedOutputs[outputIndex] = resetOutput;

      return {
        ...state,
        outputs: updatedOutputs,
        activeOutputId: action.payload, // Ensure the reset output remains active
      };
    }
    case "UPDATE_OUTPUT_IMAGE":
      return {
        ...state,
        outputs: state.outputs.map((output) =>
          output.id === action.payload.outputId
            ? { ...output, image: action.payload.imageData }
            : output
        ),
      };
    case SET_PROJECT_STATE:
      return {
        ...action.payload, // Replace the entire state with the imported project state
      };
      case SPLIT_SCREEN: {
        const { screenId, direction, position } = action.payload;
        const parentScreen = state.screens.find((screen) => screen.id === screenId);
        let newSubScreens = [];
        let defaultSplitOffset = 20;

        // Function to reset the 'latest' property for all sub-screens
    const resetLatestProperty = (subScreens) => {
      return subScreens.map(subScreen => ({ ...subScreen, latest: false }));
  };

        if (parentScreen.subScreens.length === 0) {
            // Initial split, create two subScreens
            const newSubScreenId1 = generateUniqueId();
            const newSubScreen1 = {
                id: newSubScreenId1,
                parentId: screenId,
                direction,
                position,
                offsetX: 0,
                offsetY: 0,
                assignedOutput: parentScreen.assignedOutput,
                width: direction === 'horizontal' ? parentScreen.mapWidth*parentScreen.panelWidth : position,
                height: direction === 'vertical' ? parentScreen.mapHeight*parentScreen.panelHeight : position,
                sourceX: 0,
                sourceY: 0,
                latest: false,
                number: 1,
            };
            newSubScreens.push(newSubScreen1);
    
            const newSubScreenId2 = generateUniqueId();
            const newSubScreen2 = {
                id: newSubScreenId2,
                parentId: screenId,
                direction,
                position: direction === 'horizontal' ? parentScreen.mapHeight*parentScreen.panelHeight - position : parentScreen.mapWidth*parentScreen.panelWidth - position,
                offsetX: direction === 'horizontal' ? 0 : position + defaultSplitOffset,
                offsetY: direction === 'vertical' ? 0 : position + defaultSplitOffset,
                assignedOutput: parentScreen.assignedOutput,
                width: direction === 'horizontal' ? parentScreen.mapWidth*parentScreen.panelWidth : parentScreen.mapWidth*parentScreen.panelWidth - position,
                height: direction === 'vertical' ? parentScreen.mapHeight*parentScreen.panelHeight : parentScreen.mapHeight*parentScreen.panelHeight - position,
                sourceX: direction === 'horizontal' ? 0 : position,
                sourceY: direction === 'vertical' ? 0 : position,
                latest: true,
                number: 2,
            };
            newSubScreens.push(newSubScreen2);
        } else {
            
             // Subsequent split, split the last subScreen
        const lastSubScreen = parentScreen.subScreens[parentScreen.subScreens.length - 1];
        const newSubScreenId = generateUniqueId();

        // Calculate the width and height for the last subscreen after split
        const newSubScreenWidth = direction === 'horizontal' ? lastSubScreen.width : position;
        const newSubScreenHeight = direction === 'vertical' ? lastSubScreen.height : position;

        const newSubScreen = {
            id: newSubScreenId,
            parentId: screenId,
            direction,
            position,
            offsetX: lastSubScreen.offsetX,
            offsetY: lastSubScreen.offsetY,
            assignedOutput: parentScreen.assignedOutput,
            width: newSubScreenWidth,
            height: newSubScreenHeight,
            sourceX: lastSubScreen.sourceX,
            sourceY: lastSubScreen.sourceY,
            latest: false,
            number: lastSubScreen.number,
        };

        // Calculate the width and height for the new subscreen after split
        const remainingWidth = direction === 'horizontal' ? lastSubScreen.width : lastSubScreen.width - position;
        
        const remainingHeight = direction === 'vertical' ? lastSubScreen.height : lastSubScreen.height - position;
        
        const newSubScreenId2 = generateUniqueId();
        const newSubScreen2 = {
            id: newSubScreenId2,
            parentId: screenId,
            direction,
            position: direction === 'horizontal' ? lastSubScreen.height - position : lastSubScreen.width - position,
            offsetX: direction === 'horizontal' ? lastSubScreen.offsetX : lastSubScreen.offsetX + position + defaultSplitOffset,
            offsetY: direction === 'vertical' ? lastSubScreen.offsetY : lastSubScreen.offsetY + position + defaultSplitOffset,
            assignedOutput: parentScreen.assignedOutput,
            width: remainingWidth,
            height: remainingHeight,
            sourceX: direction === 'horizontal' ? lastSubScreen.sourceX : lastSubScreen.sourceX + position,
            sourceY: direction === 'vertical' ? lastSubScreen.sourceY : lastSubScreen.sourceY + position,
            latest: true,
            number: lastSubScreen.number + 1,
        };

        newSubScreens = [...parentScreen.subScreens.slice(0, -1), newSubScreen, newSubScreen2];

        // Adjust the position of the last subScreen
        lastSubScreen.position = position;
        }
    
        // Create a new copy of the parent screen with the updated sub-screens
        const updatedParentScreen = {
            ...parentScreen,
            subScreens: newSubScreens,
        };
    
        // Create a new copy of the state with the updated parent screen
        const updatedScreens = state.screens.map((screen) =>
            screen.id === screenId ? updatedParentScreen : screen
        );
    
        return { ...state, screens: updatedScreens };
    }
    
  
      case ASSIGN_SUB_SCREEN: {
        const { subScreenId, outputId } = action.payload;
        const subScreen = state.screens.reduce((foundSubScreen, screen) => {
          return foundSubScreen || screen.subScreens.find((sub) => sub.id === subScreenId);
        }, null);
        if (subScreen) {
          subScreen.assignedOutput = outputId;
        }
        return { ...state };
      }
  
      case UNDO_SPLIT: {
        const { subScreenId, parentScreenId } = action.payload;
    
        // Find the parent screen
        const parentScreen = state.screens.find((screen) => screen.id === parentScreenId);
        if (!parentScreen) {
            // Parent screen not found, return current state
            return state;
        }
    
        const subScreens = parentScreen.subScreens;
        const subScreenIndex = subScreens.findIndex((sub) => sub.id === subScreenId);
        if (subScreenIndex === -1) {
            // Subscreen not found, return current state
            return state;
        }
    
        // If there are only two subscreens, remove both and revert to parent screen
        if (subScreens.length === 2) {
            parentScreen.subScreens = [];
            return { 
                ...state, 
                screens: state.screens.map((screen) => (screen.id === parentScreenId ? parentScreen : screen)) 
            };
        }
    
        // If the subscreen is the first one, we can't merge it with a previous one
        if (subScreenIndex === 0) {
            return state;
        }
    
        // Get the latest subscreen and the one immediately preceding it
        const latestSubScreen = subScreens[subScreenIndex];
        const previousSubScreen = subScreens[subScreenIndex - 1];
    
        // Merge the latest subscreen with the one immediately preceding it
        const mergedSubScreen = {
            ...previousSubScreen,
            width: latestSubScreen.direction === 'horizontal' ? previousSubScreen.width : previousSubScreen.width + latestSubScreen.width,
            height: latestSubScreen.direction === 'vertical' ? previousSubScreen.height : previousSubScreen.height + latestSubScreen.height,
            position: previousSubScreen.position,  // Inherit position from the previous subscreen
            direction: previousSubScreen.direction,  // Inherit direction from the previous subscreen
            latest: true, // Inherit latest status from the latest subscreen
            number: previousSubScreen.number,
        };
    
        // Replace the previous subscreen with the merged subscreen and remove the latest subscreen
        parentScreen.subScreens = [
            ...subScreens.slice(0, subScreenIndex - 1),
            mergedSubScreen,
            ...subScreens.slice(subScreenIndex + 1)
        ];
    
        // Return a new state with the updated parent screen
        return {
            ...state,
            screens: state.screens.map((screen) => (screen.id === parentScreenId ? parentScreen : screen)),
        };
    }
    
      
      
    default:
      return state;
  }
}

export default combineReducers({
  project: projectReducer,
});

import React, { useState, useEffect } from "react";
import { Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSubscriber } from "../contexts/SubscriptionContext";
import { useAuth } from "../contexts/AuthContext";

const manageSubscriptionLink =
  process.env.REACT_APP_STRIPE_MANAGE_SUBSCRIPTION_URL;

const SubscriptionStatus = () => {
  const { isSubscriber, subscriptionDetails } = useSubscriber();
  const { currentUser } = useAuth();

  let content;
  //console.log("isSubscriber from within sub status component :",isSubscriber)
//console.log("subs details from within sub status component : ",subscriptionDetails)
  if (isSubscriber && subscriptionDetails) {
    const subscription = subscriptionDetails.details.details[0];
    const plan = subscription.items.data[0].plan;
    const currentPeriodEnd = new Date(
      subscription.current_period_end * 1000
    ).toLocaleDateString();
    content = (
      <>
        <p>
          Your subscription is <strong>active</strong>.
        </p>
        <p>
          Plan: <strong>{plan.interval}ly subscription</strong> at{" "}
          <strong>${(plan.amount / 100).toFixed(2)}</strong>
        </p>
        <p>
          Expires on: <strong>{currentPeriodEnd}</strong>
        </p>
        <Button
          href={`${manageSubscriptionLink}${currentUser.email}`}
          target="_blank"
          variant="primary"
        >
          Manage Subscription
        </Button>
      </>
    );
  } else {
    content =(
        <> <p>Your subscription is not active.</p> 
        <Button as={Link} to="/upgrade" variant="success">
            Upgrade Now
        </Button>
        </>
    );
  }

  return <>{content}</>;
};

export default SubscriptionStatus;

import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RasterGenerator from "./containers/RasterGenerator";
import Features from "./containers/Features";
import Pricing from "./containers/Pricing";
import PanelManager from "./containers/PanelManager";
import Help from "./containers/Help";
import CurvingCalculator from "./containers/CurvingCalculator";
import ScanlineCalculator from "./containers/ScanlineCalculator";
import PrivacyPolicy from "./components/PrivacyPolicy";

import PrivacyPolicyModal from "./components/PrivacyPolicyModal";
import Landing from "./components/Landing";
import ProtectedRoute from "./components/ProtectedRoute";
import { ModalProvider } from "./contexts/ModalContext";
import User from "./components/User";
import { SubscriberProvider } from "./contexts/SubscriptionContext";
import Upgrade from "./components/Upgrade";

function App() {
  return (
    <AuthProvider>
      <SubscriberProvider>
        <ThemeProvider>
          <ModalProvider>
            <Router>
              <div className="d-flex flex-column min-vh-100">
                <Header />
                <Routes>
                  {/* public routes */}
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/" element={<Landing />} />
                  <Route path="/features" element={<Landing />} />
                  <Route path="/pricing" element={<Pricing />} />
                  {/* private routes */}
                  <Route
                    path="/pixel-map-generator"
                    element={
                      <ProtectedRoute>
                        <RasterGenerator />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/scanline-calculator"
                    element={
                      <ProtectedRoute>
                        <ScanlineCalculator />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/curving-calculator"
                    element={
                      <ProtectedRoute>
                        <CurvingCalculator />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/help"
                    element={
                      <ProtectedRoute>
                        <Help />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/panel-library"
                    element={
                      <ProtectedRoute>
                        <PanelManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/user"
                    element={
                      <ProtectedRoute>
                        <User />
                      </ProtectedRoute>
                    }
                  />
                   <Route
                    path="/upgrade"
                    element={
                      <ProtectedRoute>
                        <Upgrade />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
                <Footer />
              </div>
              <PrivacyPolicyModal />
            </Router>
          </ModalProvider>
        </ThemeProvider>
      </SubscriberProvider>
    </AuthProvider>
  );
}

export default App;

import React, { useRef } from "react";
import Tab from "react-bootstrap/Tab";
import { Row, Col, Nav } from "react-bootstrap";
import ScreenControls from "./ScreenControls";
import OutputControls from "./OutputControls";
import ProjectControls from "./ProjectControls";
import { useSubscriber } from "../../contexts/SubscriptionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDisplay,
  faFolderOpen,
  faTableCells, faTableCellsLarge
} from "@fortawesome/free-solid-svg-icons";


function ViewControls({
  currentView,
  setCurrentView,
  isCanvasScaled,
  toggleCanvasScaling,
}) {

//subscription check
const {isSubscribed, subscriptionDetails} = useSubscriber();

  //console.log(currentView)
  return (
    <Tab.Container
    
      id="viewport-tabs"
      defaultActiveKey={currentView}
      onSelect={(e) => setCurrentView(e)}
      className={`mb-3`}
    >
      <h4 className="mt-3 mb-3"><FontAwesomeIcon icon={faTableCells} />&nbsp;Pixel Map Generator</h4>
      {subscriptionDetails && subscriptionDetails.details &&(
      <Nav fill variant="pills" className="mb-3"> 
        <Nav.Item>
          <Nav.Link eventKey="Screens" title="Screens">
          <FontAwesomeIcon icon={faTableCellsLarge} />&nbsp;Screens
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="Outputs" title="Outputs">
          <FontAwesomeIcon icon={faDisplay} />&nbsp;Outputs
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="Project" title="Project">
          <FontAwesomeIcon icon={faFolderOpen} />&nbsp;Project
          </Nav.Link>
        </Nav.Item>
      </Nav>
      )}
      <Row>
        <Col>
          <Tab.Content>
            <Tab.Pane eventKey="Screens">
              <ScreenControls
                isCanvasScaled={isCanvasScaled}
                toggleCanvasScaling={toggleCanvasScaling}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="Outputs">
              <OutputControls
                isCanvasScaled={isCanvasScaled}
                toggleCanvasScaling={toggleCanvasScaling}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="Project">
              <ProjectControls />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
export default ViewControls;

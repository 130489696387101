import React, { useState, useEffect } from "react";
import CanvasGrid from "./CanvasGrid";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Accordion,
} from "react-bootstrap";
import InfoBox from "./InfoBox";
import ShutterGraphic from "./ShutterGraphic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDisplay,
  faVideo,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";

const ScanlineCalculator = () => {
  const [scanRate, setScanRate] = useState(16);
  const [camFps, setCamFps] = useState(24);
  const [scrambles, setScrambles] = useState(64);
  const [scrambleRepetitions, setScrambleRepetitions] = useState(2);
  const [ledFps, setLedFps] = useState(24);
  const [refreshRate, setRefreshRate] = useState(ledFps * scrambles);
  const [shutterMode, setShutterMode] = useState("angle"); // "angle" or "speed"
  const [shutterAngle, setShutterAngle] = useState(180);
  const [shutterSpeed, setShutterSpeed] = useState(50);

  const [timeUnit, setTimeUnit] = useState("seconds"); // Default to seconds

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { theme, setTheme } = useTheme();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const desiredDotRadius = 5;
  const padding = 2 * desiredDotRadius;

  const gridWidth = Math.floor(
    (windowWidth / (desiredDotRadius + padding)) * (6 / 12)
  );
  const gridHeight =
    Math.ceil(windowHeight / (desiredDotRadius + padding) / scanRate) *
    scanRate;

  const [showScanGroup, setShowScanGroup] = useState(false); // State to toggle the box visibility

  const [scanStyle, setScanStyle] = useState("vertical"); // Default to horizontal

  const toggleScanStyle = () => {
    setScanStyle((prevStyle) =>
      prevStyle === "horizontal" ? "vertical" : "horizontal"
    );
  };

  useEffect(() => {
    setRefreshRate(ledFps * scrambles * scrambleRepetitions); // Update refresh rate when ledFps changes
  }, [ledFps, scrambles, scrambleRepetitions]);

  // Render method for the Shutter Control based on the mode
  const renderShutterControl = () => {
    if (shutterMode === "angle") {
      return (
        <InputGroup className="mb-3">
          <InputGroup.Text>Shutter Angle</InputGroup.Text>
          <Form.Control
            type="number"
            value={shutterAngle}
            onChange={(e) => setShutterAngle(e.target.value)}
          />
        </InputGroup>
      );
    } else {
      return (
        <InputGroup className="mb-3">
          <InputGroup.Text>Shutter Speed</InputGroup.Text>
          <Form.Select
            onChange={(e) => setShutterSpeed(e.target.value)}
            value={shutterSpeed}
          >
            <option value="50">1/50</option>
            <option value="100">1/100</option>
            <option value="250">1/250</option>
            <option value="500">1/500</option>
            <option value="1000">1/1000</option>
            {/* Add other options as needed */}
          </Form.Select>
        </InputGroup>
      );
    }
  };

  const renderShutterGraphic = () => {
    if (shutterMode === "angle") {
      return <ShutterGraphic shutterAngle={shutterAngle} theme={theme} />;
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Form className="mt-3">
              <h4>
                <FontAwesomeIcon icon={faBars} /> Scanline Calculator
              </h4>
              <Accordion defaultActiveKey="0" className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <FontAwesomeIcon icon={faTableCells} />
                    &nbsp;LED Screen Settings
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col xxl="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Frame Rate</InputGroup.Text>
                          <Form.Select
                            onChange={(e) =>
                              setLedFps(parseFloat(e.target.value))
                            }
                            value={ledFps}
                          >
                            <option value="23.976">23.98</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="29.97">29.97</option>
                            <option value="30">30</option>
                            <option value="47.952">47.952</option>
                            <option value="48">48</option>
                            <option value="50">50</option>
                            <option value="59.9401">59.94</option>
                            <option value="60">60</option>
                            <option value="120">120</option>
                          </Form.Select>
                        </InputGroup>
                      </Col>
                      <Col xxl="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Scan Rate</InputGroup.Text>
                          <Form.Control
                            type="number"
                            value={scanRate}
                            min="1"
                            onChange={(e) => setScanRate(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Driver Sub-Frames</InputGroup.Text>
                          <Form.Select
                            onChange={(e) =>
                              setScrambles(parseFloat(e.target.value))
                            }
                            value={scrambles}
                          >
                            <option value="32">32</option>
                            <option value="64">64</option>
                          </Form.Select>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>
                            Sub-Frame Repetitions
                          </InputGroup.Text>
                          <Form.Select
                            onChange={(e) =>
                              setScrambleRepetitions(parseFloat(e.target.value))
                            }
                            value={scrambleRepetitions}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </Form.Select>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Refresh Rate</InputGroup.Text>
                          <Form.Control value={refreshRate} readOnly disabled />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FontAwesomeIcon icon={faVideo} />
                    &nbsp;Camera Settings
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col xxl="6">
                        <Row>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>Frame Rate</InputGroup.Text>
                            <Form.Select
                              onChange={(e) =>
                                setCamFps(parseInt(e.target.value))
                              }
                              value={camFps}
                            >
                              <option value="23.976">23.98</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="29.97">29.97</option>
                              <option value="30">30</option>
                              <option value="47.952">47.952</option>
                              <option value="48">48</option>
                              <option value="50">50</option>
                              <option value="59.9401">59.94</option>
                              <option value="60">60</option>
                              <option value="120">120</option>
                              <option value="240">240</option>
                              <option value="600">600</option>
                            </Form.Select>
                          </InputGroup>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Check
                              type="switch"
                              id="shutter-mode-switch"
                              label="Shutter Mode"
                              checked={shutterMode === "speed"}
                              onChange={() =>
                                setShutterMode(
                                  shutterMode === "angle" ? "speed" : "angle"
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>{renderShutterControl()}</Col>
                        </Row>
                      </Col>
                      <Col xxl="6">{renderShutterGraphic()}</Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FontAwesomeIcon icon={faDisplay} />
                    &nbsp;Visualizer Settings
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col xxl="8">
                        <InputGroup className="mb-3">
                          <InputGroup.Checkbox
                            type="checkbox"
                            label="Highlight scan group"
                            checked={showScanGroup}
                            onChange={() => setShowScanGroup(!showScanGroup)}
                          />
                          <InputGroup.Text>
                            Highlight Scan Group
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Row>
                {/*             <h5><FontAwesomeIcon icon={faDisplay} /> Results</h5> */}
                <Col xxl="12">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Time Units</InputGroup.Text>
                    <Form.Select
                      onChange={(e) => setTimeUnit(e.target.value)}
                      value={timeUnit}
                    >
                      <option value="seconds">Seconds</option>
                      <option value="milliseconds">Milliseconds</option>
                      <option value="microseconds">Microseconds</option>
                    </Form.Select>
                  </InputGroup>

                  <InfoBox
                    ledFps={ledFps}
                    camFps={camFps}
                    shutterAngle={shutterAngle}
                    refreshRate={refreshRate}
                    scanRate={scanRate}
                    timeUnit={timeUnit}
                    theme={theme}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={9}>
            <CanvasGrid
              width={windowWidth}
              height={windowHeight}
              gridWidth={gridWidth}
              gridHeight={gridHeight}
              theme={theme}
              scanStyle={scanStyle}
              ledFps={ledFps}
              camFps={camFps}
              shutterAngle={shutterAngle}
              scanRate={scanRate}
              refreshRate={refreshRate}
              showScanGroup={showScanGroup}
              shutterSpeed={shutterSpeed}
              shutterMode={shutterMode}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ScanlineCalculator;

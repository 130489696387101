import React from "react"

function Features() {
    return(
        <>
        features
        </>
    )
};

export default Features
// Define action types
export const ADD_SCREEN = 'ADD_SCREEN';
export const UPDATE_SCREEN_DATA = 'UPDATE_SCREEN_DATA';
export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN';
export const DELETE_SCREEN = 'DELETE_SCREEN';
export const DUPLICATE_SCREEN = 'DUPLICATE_SCREEN';
export const RESET_ACTIVE_SCREEN = 'RESET_ACTIVE_SCREEN';
export const RESET_PROJECT = 'RESET_PROJECT';
export const ADD_OUTPUT = 'ADD_OUTPUT';
export const SET_ACTIVE_OUTPUT = 'SET_ACTIVE_OUTPUT';
export const UPDATE_OUTPUT = 'UPDATE_OUTPUT';
export const DELETE_OUTPUT = 'DELETE_OUTPUT';
export const DUPLICATE_OUTPUT = 'DUPLICATE_OUTPUT';
export const RESET_ACTIVE_OUTPUT = 'RESET_ACTIVE_OUTPUT';
export const SET_PROJECT_STATE = 'SET_PROJECT_STATE';
export const SPLIT_SCREEN = 'SPLIT_SCREEN';
export const ASSIGN_SUB_SCREEN = 'ASSIGN_SUB_SCREEN';
export const UNDO_SPLIT = 'UNDO_SPLIT';
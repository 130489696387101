import React, { useEffect, useRef } from "react";

const CompoundVisualizer = ({
  panelWidth,
  panelDepth,
  screenWidth,
  curveDefinitions,
  pivotOnOutside,
  theme,
  boundingBoxVisible,
  onPolygonAreaChange,
  onLongestDistanceChange,
  widestPointVisible
}) => {
  const canvasRef = useRef(null);

  //calculate area of polygon using Gauss's area formula / shoelace formula
  const calculatePolygonArea = (points) => {
    let area = 0;
    const n = points.length;
    for (let i = 0; i < n - 1; i++) {
      area += points[i].x * points[i + 1].y - points[i + 1].x * points[i].y;
    }
    // Close the polygon
    area += points[n - 1].x * points[0].y - points[0].x * points[n - 1].y;
    return Math.abs(area / 2);
  };

  //calculate distance between points
  const distanceBetweenPoints = (point1, point2) => {
    return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
    );
};

//find longest distance between points
let longestDistancePoints = { point1: null, point2: null };

const findLongestDistance = (points) => {
    let maxDistance = 0;

    for (let i = 0; i < points.length; i++) {
        for (let j = i + 1; j < points.length; j++) {
            const distance = distanceBetweenPoints(points[i], points[j]);
            if (distance > maxDistance) {
                maxDistance = distance;
                longestDistancePoints = { point1: points[i], point2: points[j] };
            }
        }
    }
    return maxDistance;
};



  // Function to draw a single panel
  const drawPanel = (ctx, x, y, width, height, angle, theme) => {
    ctx.save();
    ctx.strokeStyle = `${theme === "dark" ? "white" : "black"}`;
    ctx.fillStyle = `${theme === "dark" ? "#999" : "#777"}`;
    // Translate to the pivot point
    ctx.translate(x, y);
    ctx.rotate(angle);

    // Adjust position based on pivot
    const pivotX = width;
    const pivotY = pivotOnOutside ? height - panelDepth : height;

    // Draw the rectangle (panel)
    ctx.strokeRect(-pivotX, -pivotY, width, height);
    ctx.fillRect(-pivotX, -pivotY, width, height);

    // Draw a red circle at the pivot point
    ctx.fillStyle = "red";
    ctx.beginPath();
    ctx.arc(0, 0, 5, 0, 2 * Math.PI); // Small circle at the pivot point
    ctx.fill();

    ctx.restore();
  };

  // Function to determine the angle for a specific panel
  const getPanelAngle = (panelIndex) => {
    // Find the curve definition that applies to the junction at this panel index
    for (const definition of curveDefinitions) {
      if (panelIndex >= definition.start - 1 && panelIndex < definition.end) {
        return definition.curve;
      }
    }
    return 0; // Default angle if not defined
  };

  // Function to draw the entire visualization
  const draw = (ctx, theme) => {
    // Array to store min/max coordinates for each panel
    let panelBounds = [];
    let currentX = 0,
      currentY = 0; // Start at origin
    let currentAngle = 0;

    // Calculate the position and bounds of each panel
    for (let i = 0; i < screenWidth; i++) {
      const angle = getPanelAngle(i) * (Math.PI / 180);
      currentAngle += angle;

      // Assume pivot at the corner
      let minX = currentX,
        maxX = currentX;
      let minY = currentY,
        maxY = currentY;

      panelBounds.push({ minX, maxX, minY, maxY });

      // Update position for next panel's pivot
      currentX += Math.cos(currentAngle) * panelWidth;
      currentY += Math.sin(currentAngle) * panelWidth;
    }

    // Collect all points
    let allPoints = [];
    allPoints.push({ x: -panelWidth, y: 0 }); //bandaid to get first point of inner area to be on the leading corner of the screen
    panelBounds.forEach((bounds) => {
      allPoints.push({ x: bounds.minX, y: bounds.minY });
      allPoints.push({ x: bounds.maxX, y: bounds.minY });
      allPoints.push({ x: bounds.maxX, y: bounds.maxY });
      allPoints.push({ x: bounds.minX, y: bounds.maxY });
    });

    // Calculate the area of the polygon
    const polygonArea = calculatePolygonArea(allPoints);
    onPolygonAreaChange(polygonArea); // Call the callback function with the new area

    const longestDistance = findLongestDistance(allPoints);
    // Call a callback function passed from the parent to update the longest distance
    onLongestDistanceChange(longestDistance);

    

    // Calculate global min/max coordinates
    let globalMinX = Math.min(...panelBounds.map((b) => b.minX));
    let globalMaxX = Math.max(...panelBounds.map((b) => b.maxX));
    let globalMinY = Math.min(...panelBounds.map((b) => b.minY));
    let globalMaxY = Math.max(...panelBounds.map((b) => b.maxY));

    const shapeWidth = globalMaxX - globalMinX;
    const shapeHeight = globalMaxY - globalMinY;
    //console.log(shapeWidth, shapeHeight);

    // Calculate scaling and centering
    const scaleX = ctx.canvas.width / shapeWidth;
    const scaleY = ctx.canvas.height / shapeHeight;
    const scale = Math.min(scaleX, scaleY) * 0.8; // Scale down a bit for padding

    const offsetX =
      (ctx.canvas.width - shapeWidth * scale) / 2 - globalMinX * scale;
    const offsetY =
      (ctx.canvas.height - shapeHeight * scale) / 2 - globalMinY * scale;

    // Clear the canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Apply scale and translation once for both bounding box and panels
    ctx.save();
    ctx.translate(offsetX, offsetY);
    ctx.scale(scale, scale);

    // Draw the bounding box if enabled
    if (boundingBoxVisible) {
      ctx.beginPath();
      ctx.moveTo(allPoints[0].x, allPoints[0].y);
      allPoints.forEach((point) => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.closePath();
      ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
      ctx.fill();
    }

    // Draw the dashed line
    if (widestPointVisible && longestDistancePoints.point1 && longestDistancePoints.point2) {
        ctx.save();
        ctx.lineWidth = 10
        
        ctx.setLineDash([5, 5]); // Set dashed line style
        ctx.beginPath();
        ctx.moveTo(longestDistancePoints.point1.x, longestDistancePoints.point1.y);
        ctx.lineTo(longestDistancePoints.point2.x, longestDistancePoints.point2.y);
        ctx.strokeStyle = 'white'; // Choose a color for the dashed line
        ctx.stroke();
        ctx.restore();
    }

    // Reset currentX, currentY, and currentAngle for drawing panels
    currentX = 0;
    currentY = 0;
    currentAngle = 0;

    // Draw the panels
    for (let i = 0; i < screenWidth; i++) {
      const angle = getPanelAngle(i) * (Math.PI / 180);
      drawPanel(
        ctx,
        currentX,
        currentY,
        panelWidth,
        panelDepth,
        currentAngle,
        theme
      );

      currentAngle += angle;
      currentX += Math.cos(currentAngle) * panelWidth;
      currentY += Math.sin(currentAngle) * panelWidth;
    }

    ctx.restore();
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    draw(ctx, theme);
  }, [
    panelWidth,
    panelDepth,
    screenWidth,
    curveDefinitions,
    pivotOnOutside,
    theme,
    boundingBoxVisible,
    widestPointVisible
  ]);

  return (
    <div>
      <canvas ref={canvasRef} width={1000} height={1000} />
    </div>
  );
};

export default CompoundVisualizer;

// src/contexts/ModalContext.js
import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [modalContent, setModalContent] = useState(null); // Stores JSX or null
    const [showGenericModal, setShowGenericModal] = useState(false);

    const showModal = () => {
        setShowGenericModal(true);
    };

    const hideModal = () => {
        setShowGenericModal(false);
    };

    const handleShowSignInModal = () => {
      setShowSignInModal(true);
      setShowSignUpModal(false);
    };
    const handleCloseSignInModal = () => setShowSignInModal(false);
  
    const [showSignUpModal, setShowSignUpModal] = useState(false);
  
    const handleShowSignUpModal = () => {
      setShowSignUpModal(true);
      setShowSignInModal(false);
    };
    const handleCloseSignUpModal = () => setShowSignUpModal(false);

  const value = {
    showSignUpModal,
    setShowSignUpModal,
    handleShowSignInModal,
    showSignInModal,
    setShowSignInModal,
    handleShowSignUpModal,
    handleCloseSignInModal,
    handleCloseSignUpModal,
    showModal,
    hideModal,
    modalContent,
    setModalContent
  };

  return <ModalContext.Provider value={value}>{children}{modalContent && (
    <Modal show={showGenericModal} onHide={hideModal}>
        {modalContent}
    </Modal>
)}</ModalContext.Provider>;
};

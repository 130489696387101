import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ViewControls from "../components/controls/ViewControls";
import ScreenView from "./ScreenView";
import OutputView from "./OuputView";
import ProjectView from "./ProjectView";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";


function RasterGenerator() {
  const { isEmailVerified } = useAuth();
  let navigate = useNavigate();

  const [currentView, setCurrentView] = useState("Screens");
  // In App.js
  const [isCanvasScaled, setIsCanvasScaled] = useState(true);

  //redirect if unauthenticated
  useEffect(() => {
    if (!isEmailVerified) {
      return navigate("/");
    }
  },[isEmailVerified]);

  
  

  // Function to toggle canvas scaling
  const toggleCanvasScaling = () => setIsCanvasScaled(!isCanvasScaled);

  return (
    <main>
      <Container fluid className="bg-body">
        <Row>
          {/* Left Column (Controls) */}
          
          <Col lg={3}>
            <ViewControls
              currentView={currentView}
              setCurrentView={setCurrentView}
              isCanvasScaled={isCanvasScaled}
              toggleCanvasScaling={toggleCanvasScaling}
            />
          </Col>
          
          {/* Right Column (Canvas) */}
          <Col lg={9} className="g-0 bg-secondary-subtle">
            {currentView === "Screens" && (
              <ScreenView isCanvasScaled={isCanvasScaled} />
            )}
            {currentView === "Outputs" && (
              <OutputView isCanvasScaled={isCanvasScaled} />
            )}
            {currentView === "Project" && <ProjectView />}
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default RasterGenerator;

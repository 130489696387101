import React, { useEffect, useState, useRef } from 'react';

// Debounce function to limit the rate of resize event handling
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}


const Visualizer = ({ panelWidth, chordAngle, panelDepth, pivotOnOutside, theme }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 500, height: 500 }); // Default size

  // Converts degrees to radians
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  // Function to draw a single panel
  const drawPanel = (ctx, radius, angle, panelWidth, panelDepth, scale, pivotOnOutside, theme) => {
    const pivotOffset = pivotOnOutside ? 0 : panelDepth;
    ctx.save();
    // Centering the circle in the canvas
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
    ctx.rotate(toRadians(angle));
    ctx.strokeStyle = `${theme === "dark" ? "white" : "black"}`;
    ctx.strokeRect(-panelWidth * scale / 2, -radius * scale - pivotOffset * scale, panelWidth * scale, panelDepth * scale);
    ctx.restore();
  };

  // Function to calculate the scaling factor
  const calculateScale = (radius, canvasSize, padding = 1000) => {
    const maxDiameter = radius * 2 + padding;
    return canvasSize / maxDiameter;
  };

  // Function to draw the entire visualization
  const draw = (ctx, panelWidth, chordAngle, theme) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    
    // Validation to prevent issues with zero or negative angle
    if (chordAngle <= 0 || chordAngle > 180) {
      ctx.fillStyle = 'red';
      ctx.fillText('Invalid angle', 10, 20);
      return;
    }
    
    const totalAngle = 360 / chordAngle;
    const panelCount = Math.round(totalAngle);
    const radius = (panelWidth / 2) / Math.tan(toRadians(chordAngle / 2));
    const scale = calculateScale(radius, Math.min(ctx.canvas.width, ctx.canvas.height));

    for (let i = 0; i < panelCount; i++) {
      drawPanel(ctx, radius, i * chordAngle, panelWidth, panelDepth, scale, pivotOnOutside, theme);
    }

    // Check if a complete circle is formed
    const completeCircle = totalAngle === panelCount;
    ctx.fillStyle = completeCircle ? 'green' : 'red';
    ctx.fillText(completeCircle ? 'Complete Circle Formed' : 'Incomplete Circle', 10, 20);
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setCanvasSize({
          width: offsetWidth,
          height: Math.min(offsetHeight, window.innerHeight) // Limit height to parent's height
        });
      }
    };

    // Debounce the resize handler
    const debouncedHandleResize = debounce(handleResize, 100);

    // Set initial size
    handleResize();

     // Add event listener for window resize
     window.addEventListener('resize', debouncedHandleResize);

     // Clean up
     return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    draw(ctx, parseFloat(panelWidth), parseFloat(chordAngle), theme);
  }, [panelWidth, chordAngle, panelDepth, pivotOnOutside, canvasSize, theme]);


  return (
    <div ref={containerRef} className="canvas-container">
      <canvas ref={canvasRef} width={canvasSize.width} height={canvasSize.height} />
    </div>
  );
};

export default Visualizer;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrWibVXoHEtVmpxUrrbrajXwQHBMlBRZo",
  authDomain: "pixel-map-generator.firebaseapp.com",
  projectId: "pixel-map-generator",
  storageBucket: "pixel-map-generator.appspot.com",
  messagingSenderId: "216437493862",
  appId: "1:216437493862:web:bcfbe6b12d9c963ed99da7",
  measurementId: "G-Z1NFHFB8FW",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

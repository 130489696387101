import { Row, Col } from "react-bootstrap";
const InfoBox = ({ ledFps, camFps, shutterAngle, refreshRate, scanRate, timeUnit, theme }) => {
     
    

    // Function to convert time based on selected unit
  const convertTime = (time) => {
    switch(timeUnit) {
      case 'milliseconds':
        return time * 1000;
      case 'microseconds':
        return time * 1000000;
        case 'nanoseconds':
            return time * 1000000000;
      default:
        return time;
    }
  };

  const timePrecision = () => {
    switch(timeUnit) {
        case 'nanoseconds':
            return 1;
      case 'milliseconds':
        return 4;
      case 'microseconds':
        return 2;
      default:
        return 6;
    }
  };

    // Calculations
    // Calculations
  let frameTime = convertTime(1 / ledFps);
  let camTime = convertTime(1 / camFps);
  let refreshTime = convertTime(1/refreshRate);
  let shutterTime = camTime * (shutterAngle / 360);
  let shutterTimeSeconds = (1/camFps) * (shutterAngle/360);
  let totalRefreshes = refreshRate * shutterTimeSeconds;
  let scanSwitchesPerSecond = refreshRate * scanRate;
  let scanSwitchTime = convertTime(1 / scanSwitchesPerSecond);
  let totalScanAlternations = shutterTimeSeconds / (1/scanSwitchesPerSecond);
  let scanCycles = totalScanAlternations / scanRate;
  let brighterScanlines = Math.ceil(totalScanAlternations % scanRate);
  let darkerScanlines = scanRate - brighterScanlines;
  
    // Styling for the variables
    let variableStyle = { color: 'blue'};
    let variableBackgroundColor = "black" ;
    if (theme === "dark"){
      variableStyle = { color: 'limegreen' };
      variableBackgroundColor = "black";
    } else {
      variableStyle = { color: 'blue' };
      variableBackgroundColor = "#ddd";
    }
    const timeStyle = { color: 'hotpink' };
    const worWrapStyle = {wordWrap: "break-word"};
    
    return (
      <Row className="mb-3">
        <Col>
        
      <div className="themed-pre">
            <code style={worWrapStyle}>
      
        <p>With video displayed on the LED screen at <span style={variableStyle}>{ledFps}</span> frames per second, a single video frame is displayed for <span style={variableStyle}>{frameTime.toFixed(timePrecision())}</span> <span style={timeStyle}>{timeUnit}</span>.</p>
        <p>Each individual LED turns off and on again <span style={variableStyle}>{refreshRate}</span> times per second.</p>
        <p>An individual refresh takes <span style={variableStyle}>{refreshTime.toFixed(timePrecision())}</span> <span style={timeStyle}>{timeUnit}</span></p>
        <p>Individual scan lines are alternated <span style={variableStyle}>{scanSwitchesPerSecond.toFixed(2)}</span> times per second. Each scan line is only active for <span style={variableStyle}>{scanSwitchTime.toFixed(timePrecision())}</span> <span style={timeStyle}>{timeUnit}</span>. Only 1 line for every <span style={variableStyle}>{scanRate}</span> will be on at any given moment.</p>
        <p>Assuming your camera has a global shutter.</p>
        
        <p>With a camera capturing an image of the LED screen at <span style={variableStyle}>{camFps}</span> frames per second and a shutter angle of  <span style={variableStyle}>{shutterAngle}</span> , the camera shutter is open for <span style={variableStyle}>{shutterTime.toFixed(timePrecision())}</span> <span style={timeStyle}>{timeUnit}</span>.</p>
        
        <p>While the camera shutter is open, it captures a total of <span style={variableStyle}>{totalRefreshes.toFixed(2)}</span> refreshes. If no decimal point, no scan lines visible.</p>
        <p><span style={variableStyle}>{brighterScanlines}</span> lines out of <span style={variableStyle}>{scanRate}</span> will be brighter than the remaining <span style={variableStyle}>{darkerScanlines}</span>.</p>
      
      </code>
      </div>
      </Col>
      </Row>
    );
  };
  
  export default InfoBox;
  
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebaseConfig'; // Correctly use the imported auth instance
import axios from 'axios'; // For HTTP requests

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasAgreedToPrivacyPolicy, setHasAgreedToPrivacyPolicy] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showVerificationLink, setShowVerificationLink] = useState(false);
  const [showResetPasswordLink, setShowResetPasswordLink] = useState(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => { // Use the imported auth instance
      setCurrentUser(user);
      if (user) {
        fetchAgreementStatus(user);
        if (user.emailVerified){
            setIsEmailVerified(true);
          }
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const fetchAgreementStatus = async (user) => {
    try {
      const token = await user.getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_AGREEMENT_STATUS_API}`, {
        params: { userEmail: user.email },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const agreementStatus = response.data.agreementStatus;
      setHasAgreedToPrivacyPolicy(agreementStatus);
      //console.log("user's policy agreement status on loging : ",hasAgreedToPrivacyPolicy);
    // If the user has not agreed to the privacy policy, show the modal
    if (!agreementStatus) {
        setShowPrivacyPolicyModal(true);
      }
      
    } catch (error) {
      console.error("Failed to fetch agreement status:", error);
      // Handle error appropriately
    }
  };

  const signUp = async (email, password, agreementStatus) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password); // Use the imported auth instance
      await sendEmailVerification(userCredential.user);
      setShowAlert(true);
      await updateAgreementStatus(userCredential.user.email, agreementStatus);
      // Consider what to do next, e.g., sign in the user automatically or redirect to a verification page
      signOut(); // force a sign out to change the user state, so upon signIn, the email verification state is checked.
    } catch (error) {
      console.error("Failed to sign up:", error);
      // Handle error appropriately
      console.error(error.message);
      setErrorMessage(error.message);
      setShowAlert(false); // Hide alert in case of error
      setShowError(true);
      setEmail('');
      setPassword('');
    }
  };
  

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // At this point, the sign-in has succeeded, and userCredential contains the signed-in user information
      const user = userCredential.user;
  
      // Now, you can check if the user's email is verified
      if (user.emailVerified) {
        // Email is verified, proceed as normal
        setErrorMessage("");
        setShowVerificationLink(false);
        setShowError(false);
        // Optionally, clear email and password fields here if needed
        // setEmail('');
        // setPassword('');
        
        
      } else {
        // Email is not verified
        setErrorMessage("Please verify your email before signing in.");
        setShowVerificationLink(true); // Show the option to resend the verification email
        setShowResetPasswordLink(false);
        setShowError(true);
        return userCredential.user;
        // Sign out the user since they haven't verified their email yet
        await auth.signOut();
      }
    } catch (error) {
      console.error("Failed to sign in:", error);
      setErrorMessage(error.message);
      setShowError(true);
  
      // Handle specific error cases
      if (error.code === "auth/wrong-password") {
        setShowVerificationLink(false);
        setShowResetPasswordLink(true); // Show reset password only in case of wrong password
      } else {
        setShowResetPasswordLink(false);
        // For other errors, decide if you want to show the verification link or not
        setShowVerificationLink(false);
      }
    }
  };
  

  const signOut = async () => {
    try {
      await auth.signOut();
      setIsEmailVerified(false);
      setHasAgreedToPrivacyPolicy(false);
    } catch (error) {
      console.error("Failed to sign out:", error);
      // Handle error appropriately
    }
  };

  const updateAgreementStatus = async (email, agreementStatus) => {
    try {
      const token = await auth.currentUser.getIdToken();
      await axios.post(`${process.env.REACT_APP_USER_AGREEMENT_API}`, {
        userEmail: email,
        agreementStatus
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setHasAgreedToPrivacyPolicy(agreementStatus);
    } catch (error) {
      console.error("Failed to update agreement status:", error);
      // Handle error appropriately

    }
  };

  const sendEmailVerificationLink = (user) => {
    // Call the firebase function to send a verification link to the user
    // For now, this is just a placeholder function
    sendEmailVerification(user)
      .then(() => {
        // Email verification sent!
        setShowVerificationLink(false);
        setErrorMessage("email sent! please check your inbox.");
        signOut();//sign out to force user state reset
      })
      .catch((error) => {
        console.error(error.message);
        setErrorMessage(error.message);
      });
  };

  const sendPasswordResetLink = (emailAddress) => {
    // Use the provided email address or fall back to the state
    const emailToSend = emailAddress;
    if (emailToSend) {
      sendPasswordResetEmail(auth, emailToSend)
        .then(() => {
          // Password reset link sent!
          setShowResetPasswordLink(false);
          setErrorMessage("Reset password link sent to " + emailToSend + ". Please check your inbox.");
        })
        .catch((error) => {
          console.error(error.message);
          setErrorMessage(error.message);
        });
    } else {
      console.error("No email address provided for password reset.");
      setErrorMessage("Please provide an email address for password reset.");
    }
  };
  
  const value = {
    currentUser,
    hasAgreedToPrivacyPolicy,
    signUp,
    signIn,
    signOut,
    updateAgreementStatus,
    setShowError,
    showError,
    setErrorMessage,
    errorMessage,
    setShowAlert,
    showAlert,
    setEmail,
    email,
    setPassword,
    password,
    isEmailVerified,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    showVerificationLink,
    showResetPasswordLink,
    sendEmailVerificationLink,
    sendPasswordResetLink
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

import React, { useState } from "react";
import { Modal, Form, Button, ButtonGroup, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";



function SignInForm({ hideSignInModal, handleShowSignUpModal }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [unverifiedUser, setUnverifiedUser] = useState(null); // Store the user here
  const navigate = useNavigate(); // Get the navigate function

  const { signIn,setShowError,setErrorMessage, showError, errorMessage, showVerificationLink, showResetPasswordLink, sendPasswordResetLink, sendEmailVerificationLink} = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowError(false); //reset
    try {
      const user = await signIn(email, password);
      // Additional checks or redirects after successful sign-in
      if (!user.emailVerified) {
        // Handle case where email is not verified.
        setUnverifiedUser(user); // Assuming you have a state to hold the unverified user.
        //setShowError(true);
        //setErrorMessage("Your email is not verified. Please verify your email.");
      } else {
        // Handle successful sign-in with verified email.
        // For example, redirect the user or close the modal.
      }
    } catch (error) {
      //console.error(error);
      // Handle sign-in errors
      
    }
  };

  

 

  const resetLogin = () => {
    setErrorMessage("");
    setEmail("");
    setPassword("");
    setShowError(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Sign in to blinking things</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <ButtonGroup className="mb-3">
            <Button variant="outline-secondary" type="submit">
              Log In
            </Button>
          </ButtonGroup>
          <br></br>
          <p>
            Not a registered user?{" "}
             <a href="#" onClick={handleShowSignUpModal}>
              Sign Up
            </a> 
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {showError && ( // Conditionally render the alert
          <Alert variant="danger" onClose={() => resetLogin()} dismissible>
            {errorMessage}
            {showVerificationLink && (
              <p className="authError">
                If you didn't receive the email,{" "}
                 <a href="#" onClick={() => sendEmailVerificationLink(unverifiedUser)}>
                  click here
                </a>{" "} 
                to send again.
              </p>
            )}
            {showResetPasswordLink && (
              <p className="authError">
                Forgot your password?{" "}
                 <a href="#" onClick={() => sendPasswordResetLink(email)}>
                  Click here
                </a>{" "}
                to send a password reset link.
              </p>
            )}
          </Alert>
        )}
      </Modal.Footer>
    </>
  );
}
export default SignInForm;

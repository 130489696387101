import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

const Controls = ({
  onParametersChange,
  togglePivot,
  pivotOnOutside,
  panelCount,
  radius,
  diameter,
  circumference,
  isCompoundCurveMode,
  curveDefinitions,
  addCurveDefinition,
  removeCurveDefinition,
  updateCurveDefinition,
  isCurveValid,
  boundingBoxVisible,
  toggleBoundingBox,
  polygonArea,
  longestDistance,
  widestPointVisible,
  toggleWidestPoint,
  totalCurve
}) => {
  const [panelWidth, setPanelWidth] = useState(500);
  const [chordAngle, setChordAngle] = useState(10);
  const [panelDepth, setPanelDepth] = useState(50); // Default depth in mm
  const [screenWidth, setScreenWidth] = useState(31);

  // Effect to update parent component whenever panelWidth or chordAngle changes
  useEffect(() => {
    onParametersChange(panelWidth, chordAngle, panelDepth, screenWidth);
  }, [panelWidth, chordAngle, panelDepth, screenWidth]);

  //console.log("total curve", totalCurve);
  return (
    <>
    <Row>
      <Col>
      {isCompoundCurveMode ? (
        // Compound Curve Mode Controls
        <div>
          <Row>
          <InputGroup className="mb-3">
            <InputGroup.Text>Screen Width</InputGroup.Text>
            <Form.Control
              type="number"
              value={screenWidth}
              min="1"
              onChange={(e) => setScreenWidth(e.target.value)}
              placeholder="Enter width"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Panel Width (mm)</InputGroup.Text>
            <Form.Control
              type="number"
              value={panelWidth}
              min="1"
              onChange={(e) => setPanelWidth(e.target.value)}
              placeholder="Enter width"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Panel Depth (mm)</InputGroup.Text>
            <Form.Control
              type="number"
              value={panelDepth}
              min="0.1"
              onChange={(e) => setPanelDepth(e.target.value)}
              placeholder="Enter angle"
            />
            </InputGroup>
            </Row>
            
             
          
          {!isCurveValid && <div className="warning">Invalid range in curve definitions.</div>}
          {curveDefinitions.map((definition, index) => (
            <div key={index}>
              <InputGroup className="mb-3">
              <InputGroup.Text>Start</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Start Panel"
                  value={definition.start}
                  onChange={(e) => updateCurveDefinition(index, 'start', e.target.value)}
                />
                <InputGroup.Text>End</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Start Panel"
                  value={definition.end}
                  onChange={(e) => updateCurveDefinition(index, 'end', e.target.value)}
                />
                <InputGroup.Text>Angle</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Start Panel"
                  value={definition.curve}
                  onChange={(e) => updateCurveDefinition(index, 'curve', e.target.value)}
                />
                <Button
                  variant="outline-danger"
                  onClick={() => removeCurveDefinition(index)}
                >
                  -
                </Button>
              </InputGroup>
            </div>
          ))}
          <Button className="mb-3" variant="outline-primary" onClick={addCurveDefinition}>
            Add Curve
          </Button>
          <Row className="mb-3"><Col>
            <Form.Check
            type="switch"
            id="pivot-on-outside-switch"
            label="Pivot on Outside Edge"
            checked={pivotOnOutside}
            onChange={() => togglePivot()}
          />
          <Form.Check
            type="switch"
            id="show-bounding-box"
            label="Show Area"
            checked={boundingBoxVisible}
            onChange={toggleBoundingBox}
          />
          <Form.Check
            type="switch"
            id="show-widest-point"
            label="Show Widest Point"
            checked={widestPointVisible}
            onChange={toggleWidestPoint}
          />
          </Col>
            </Row>
          
        <InputGroup className="mb-3">
            <InputGroup.Text>Area</InputGroup.Text>
            <Form.Control readOnly value={`${(polygonArea/1000000).toFixed(2)} m²`} />
        </InputGroup>
         <InputGroup className="mb-3">
         <InputGroup.Text>Widest Point</InputGroup.Text>
         <Form.Control readOnly value={`${(longestDistance/1000).toFixed(2)} m`} />
     </InputGroup>
     <InputGroup className="mb-3">
         <InputGroup.Text>Total Curve</InputGroup.Text>
         <Form.Control readOnly value={`${totalCurve.toFixed(2)} degrees`} />
     </InputGroup>
    
        
        </div>

      ) : (
        <>
          <InputGroup className="mb-3">
            <InputGroup.Text>Panel Width (mm)</InputGroup.Text>
            <Form.Control
              type="number"
              value={panelWidth}
              min="1"
              onChange={(e) => setPanelWidth(e.target.value)}
              placeholder="Enter width"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Angle Between Panels</InputGroup.Text>
            <Form.Control
              type="number"
              value={chordAngle}
              min="1"
              step="1"
              onChange={(e) => setChordAngle(e.target.value)}
              placeholder="Enter angle"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Panel Depth (mm)</InputGroup.Text>
            <Form.Control
              type="number"
              value={panelDepth}
              min="0.1"
              onChange={(e) => setPanelDepth(e.target.value)}
              placeholder="Enter angle"
            />
          </InputGroup>
          <Form.Check
            type="switch"
            id="pivot-on-outside-switch"
            label="Pivot on Outside Edge"
            checked={pivotOnOutside}
            onChange={() => togglePivot()}
          />
          <InputGroup className="mb-3">
            <InputGroup.Text>Panel Count</InputGroup.Text>
            <Form.Control readOnly value={panelCount} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Circle Radius (m)</InputGroup.Text>
            <Form.Control readOnly value={(radius / 1000).toFixed(2)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Circle Diameter (m)</InputGroup.Text>
            <Form.Control readOnly value={(diameter / 1000).toFixed(2)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Circumference (m)</InputGroup.Text>
            <Form.Control readOnly value={(circumference / 1000).toFixed(2)} />
          </InputGroup>
        </>
      )}
      </Col>
    </Row>
    </>
  );
};

export default Controls;

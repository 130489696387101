import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import { useModal } from "../contexts/ModalContext";





const Plan = (props) => {
    
  return (
    
    <div className="card mb-4 my-2 mx-2 shadow-sm col-md-2 g-0">
      <div className="card-header">
        <h4 className="my-0 font-weight-normal">{props.header}</h4>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          {props.header === "Enterprise" ? (
            <>{`${props.price}`}</>
          ) : (
            <>
              {`$${props.price}`}
              <small className="text-muted">/ yr</small>
            </>
          )}
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {props.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        <button
          className={`btn btn-lg btn-block ${
            props.outline ? "btn-outline-primary" : "btn-primary"
          }`}
          type="button"
          onClick={props.function}
        
        >
          {props.buttonLabel}
        </button>
      </div>
    </div>
    
  );
};

const Pricing = ({ handleShow, handleClose, setIsLogin }) => {

    const {
        handleCloseSignInModal,
        handleCloseSignUpModal,
        handleShowSignInModal,
        handleShowSignUpModal,
        showSignInModal,
        showSignUpModal,
      } = useModal();

      const planContents = [
        {
          header: "Free",
          price: 0,
          features: [
            "Unlimited pixel map generation",
            "Wiring diagram output",
            "Access to community panel library",
            "Scanline visualizer",
            "Curved screen tool"
          ],
          buttonLabel: "Sign up for free",
          outline: true,
          function: handleShowSignUpModal,
        },
        {
          header: "Pro",
          price: 29.99,
          features: [
            "Everything included in the Free plan, plus",
            "Access to processor based limits",
            "Multiple screens per output raster",
            "Multiple output rasters per project",
            "Output projects with rasters, individual screens, CSV screen schedule",
          ],
          buttonLabel: "Get started",
          outline: false,
          function: handleShowSignUpModal,
        },
        {
          header: "Enterprise",
          price: "Custom",
          features: [
            "Everything included in the Pro plan, plus",
            "External API access",
            "Bespoke panel and processor libraries",
            "Admin dashboard",
          ],
          buttonLabel: "Contact us",
          outline: true,
          function: handleShowSignUpModal,
        },
      ];
 
   

  const plans = planContents.map((obj, i) => {
    return (
      <Plan
        key={obj.header}
        header={obj.header}
        price={obj.price}
        features={obj.features}
        buttonLabel={obj.buttonLabel}
        outline={obj.outline}
        function={obj.function}
      />
    );
  });

  return (
    <>
      <Container fluid id="landing-container">
        <Row id="floating-bg">
          {/*  <img
                  id="grid"
                  alt="grid"
                  src={grid}
                /> */}
        </Row>
      
      <div id="everything-on-top">
        <Row className="justify-content-sm-center">
          <Col md="6" sm="8" className="text-white">
            <h2 className="display-2 my-5 mx-5" style={{ fontWeight: "bold" }}>
              A plan for every project.
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">{plans}</Row>
      </div>
      </Container>
    </>
  );
};

export default Pricing;

import { confirmPasswordReset } from "firebase/auth";
import React, { useRef, useEffect } from "react";

const CanvasGrid = ({
  width,
  height,
  gridWidth,
  gridHeight,
  theme,
  scanStyle,
  ledFps,
  camFps,
  shutterAngle,
  refreshRate,
  scanRate,
  showScanGroup,
  shutterSpeed,
  shutterMode
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, width, height);

    const circleRadius = 5;
    const padding = circleRadius * 2;
    const startX = padding;
    const startY = padding;

    if (showScanGroup) {
      // Calculate the size of the rectangle
      const rectWidth = gridWidth * padding * 1.5 - padding;
      const rectHeight = scanRate * padding * 1.5 - padding;

      // Draw the semi-transparent red rectangle
      context.fillStyle = "rgba(255, 0, 0, 0.5)"; // Semi-transparent red
      context.fillRect(
        startX - padding,
        startY - padding,
        rectWidth + padding,
        rectHeight + padding
      );
    }

    const calculateBrightness = () => {
      // Add your logic here to calculate brightness based on the parameters
      // Example: return (refreshRate + scanRate + fps + shutterAngle) / 4;
      let frameTime = 1 / ledFps;
      let camTime = 1 / camFps;
      let shutterTime;
      if (shutterMode === "angle"){
        shutterTime = camTime * (shutterAngle / 360);
      } else {
        shutterTime = 1 / shutterSpeed;
      }
      

      let totalRefreshes = refreshRate * shutterTime;
      //console.log("While the camera shutter is open, it captures a total of ", totalRefreshes," refreshes.")
      let scanSwitchesPerSecond = refreshRate * scanRate;
      let scanSwitchTime = 1 / scanSwitchesPerSecond;
      //console.log("Individual scan lines are alternated ",scanSwitchesPerSecond," times per second. Each scan line is only active for ",scanSwitchTime," seconds.")
      let totalScanAlternations = shutterTime / scanSwitchTime;
      //console.log(totalScanAlternations);
      let scanCycles = totalScanAlternations / scanRate;
      //console.log("Total scan cycles completed ",scanCycles,". If no decimal point, no scan lines visible")
      let brighterScanlines = Math.ceil(totalScanAlternations % scanRate);
      let darkerScanlines = scanRate - brighterScanlines;
      //console.log(brighterScanlines, "lines out of ",scanRate," will be brighter than the remaining ",darkerScanlines);

      return { brighterScanlines, darkerScanlines };
    };
    const { brighterScanlines, darkerScanlines } = calculateBrightness();
    const groupSize = parseInt(scanRate);

    for (let x = 0; x < gridWidth; x++) {
      for (let y = 0; y < gridHeight; y++) {
        let brightness;
        if (brighterScanlines === groupSize || darkerScanlines === groupSize) {
          brightness = 100; // Set all lines to bright
        } else {
          const groupIndex = Math.floor(y / groupSize);
          const positionInGroup = y % groupSize;
          const isBrighterLine = positionInGroup < brighterScanlines;
          brightness = isBrighterLine ? 100 : 50;
        }

        context.beginPath();
        context.arc(
          startX + x * padding * 1.5,
          startY + y * padding * 1.5,
          circleRadius,
          0,
          Math.PI * 2
        );
        context.strokeStyle = theme === "dark" ? "#fff" : "#000";
        context.fillStyle = `rgba(${
          theme === "dark" ? "255, 255, 255" : "0, 0, 0"
        }, ${theme === "dark" ? brightness / 100 : 1 - brightness / 100})`;
        context.stroke();
        context.fill();
      }
    }
  }, [
    width,
    height,
    gridWidth,
    gridHeight,
    theme,
    scanStyle,
    ledFps,
    camFps,
    refreshRate,
    shutterAngle,
    scanRate,
    showScanGroup,
    shutterMode,
    shutterSpeed
  ]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default CanvasGrid;

import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";

const Help = () => {
  return (
    
      <Container fluid>
      <Row className="m-3">
        <Col xxl="6">
          <Card className="mb-3">
            <Card.Header as="h5">How to Use These Tools</Card.Header>
            <Card.Body>
              <Card.Title>
                Step-by-Step Guide: Using the Pixel Map Generator
              </Card.Title>
              <Card.Text>
                <p>The Pixel Map Generator is a versatile tool designed for
                planning and validating LED wall builds. Its workspace is
                divided into three main views: Screen View, Output View, and
                Project View. Here's a step-by-step guide to navigate through
                these views effectively:</p>
                <h5>Screen View</h5>
                <p>
                  Start with Screens: Begin by adding screens to your project.
                  Define the number of panels in height and width for each
                  screen. Panel Sizing: Set the size of individual panels by
                  either entering custom pixel dimensions or selecting from
                  pre-defined types in the panel library. Port Pixel Limit: If
                  you plan to output a wiring diagram, set a port pixel limit as
                  per your processor's specifications. Customization: Choose
                  border options and name your screen. Screen Management: Add or
                  remove screens using the buttons at the bottom of the page.
                  Switch the active screen displayed in the canvas view using
                  the button next to the screen's name field. Further
                  Customization: Adjust panel color, border color, data flow
                  direction, panel coordinates, text fields, and custom logos in
                  the Size, Color, and Overlay sections. Repeat: Follow these
                  steps for each screen until all are defined.
                </p>
                <h5>Output View</h5>
                <p>
                  Set Output Dimensions: Define the size of the first output in
                  pixels (width and height). Add Outputs: Add additional outputs
                  as required for your project. Screen Assignment: In the
                  Screens subsection, assign each screen to an output and set
                  the necessary x and y offsets to avoid overlaps. Positioning
                  Screens: Ensure all outputs are correctly sized and screens
                  are positioned without overlap.
                </p>
                <h5>Project View</h5>
                <p>
                  Review Project: View thumbnail previews of all screens and
                  outputs, along with textual descriptions of sizes and offsets.
                  Export Options: Select elements of the project to export.
                  Click the export button to download files to your browser's
                  download folder. Project JSON File: Export and share the
                  project JSON file for later use or collaboration. Import it
                  back into the tool via the Import subsection in the Project
                  View.
                </p>
                <h5>Advanced Options</h5>
                <p>
                  The Pixel Map Generator also includes advanced options for
                  more detailed project requirements. By following the steps
                  outlined above, you can efficiently generate test patterns for
                  your next project. Whether you're a seasoned professional or
                  new to LED wall configuration, this tool is designed to
                  streamline your planning process.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl="6">
        <Card className="mb-3">
              <Card.Header as="h5">Support Contact</Card.Header>
              <Card.Body>
                <Card.Text>
                <p>If you need supoprt reach out via <a href="mailto:info@blinkingthings.com">email</a> or check the community <a href="https://discord.com/invite/VHabWRVBzU" target="_blank">discord</a>.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          
            <Card>
              <Card.Header as="h5">About blinking things</Card.Header>
              <Card.Body>
                <Card.Text>
                <p>blinking things started its journey in 2016 as a pixel map generation tool, born out of necessity and a passion for innovation. Since then, it has evolved into a comprehensive suite of tools that cater to the needs of professionals working with LED screens. Our offerings have grown to include the original pixel map generator, along with a variety of other resources designed to assist in screen validation and troubleshooting.</p>

<p>One of the core strengths of blinking things lies in its accessibility. All tools are hosted in a browser environment, making them readily available to anyone, anywhere, without the need for specialized software or hardware. This approach ensures that whether you're on-site or planning remotely, you have the necessary tools at your fingertips.</p>

<p>Our mission is to simplify the complexities involved in LED screen management. By providing intuitive, user-friendly tools, we aim to empower users to overcome challenges and optimize their screen setups efficiently. blinking things is more than just a tool; it's a solution partner for everyone in the LED screen industry.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          
        </Col>
      </Row>
      </Container>
    
  );
};

export default Help;

// src/components/PrivacyPolicyModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import PrivacyPolicyText from './PrivacyPolicyText';

const PrivacyPolicyModal = () => {
  const {updateAgreementStatus, setShowPrivacyPolicyModal, showPrivacyPolicyModal, currentUser} = useAuth();

  const handleAgree = async () => {
    // Ensure there is a current user and that the user's email is accessible
    if (currentUser && currentUser.email) {
      await updateAgreementStatus(currentUser.email, true);
      // Assuming the agreement status was successfully updated, close the modal
      setShowPrivacyPolicyModal(false);
    } else {
      console.error("No current user found or user's email is unavailable.");
      // Handle the error state appropriately, maybe show an error message to the user
    }
  };
  
  


  return (
    <Modal show={showPrivacyPolicyModal} onHide={() => {}} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Privacy Policy Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrivacyPolicyText/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAgree}>Agree</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicyModal;

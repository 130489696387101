import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Button, Modal, Container, Table, Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook
} from "@fortawesome/free-solid-svg-icons";

const PanelTypeManager = () => {
  const [authUser, setAuthUser] = useState(null);
  const [panelTypes, setPanelTypes] = useState([]);
  const [newPanelType, setNewPanelType] = useState({
    manufacturer: '',
    name: '',
    panel_width: '',
    panel_height: '',
    width_in_mm: '',
    height_in_mm: '',
    weight_in_kg: '',
    wattage: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [missingFieldsMessage, setMissingFieldsMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
const [showSuccessModal, setShowSuccessModal] = useState(false);


  //environmental vs
  const panelTypeApiUri = process.env.REACT_APP_PANEL_API;

  // Initialize Firebase Auth
  const auth = getAuth();

  // Authorized email addresses
  //const authorizedEmails = ['blinkingthings@gmail.com', 'user2@example.com'];

  // Function to fetch panel types from PostgreSQL via the Node.js backend
  const fetchPanelTypes = async () => {
    setIsLoading(true);
    try {
      // Ensure we have an authenticated user and token
      if (!authUser) {
        console.error('No authenticated user found');
        setIsLoading(false);
        return;
      }
  
      const token = await authUser.getIdToken();
  
      const response = await axios.get(panelTypeApiUri, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      setPanelTypes(response.data);
    } catch (error) {
      console.error('Error fetching panel types:', error);
      // Handle error state here, like showing a message to the user
    } finally {
      setIsLoading(false);
    }
  };
  

  const validateForm = () => {
    const missingFields = [];
    Object.entries(newPanelType).forEach(([key, value]) => {
      if (!value) missingFields.push(key);
    });
  
    if (missingFields.length > 0) {
      setMissingFieldsMessage(`The following fields are missing: ${missingFields.join(', ')}`);
      setShowModal(true);
      return false;
    }
    return true;
  };
  

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        // Set the email in the newPanelType state
        setNewPanelType(prevState => ({ ...prevState, submitted_by: user.email }));
      } else {
        setAuthUser(null);
      }
    });
  }, [auth]);
  
  // New useEffect for fetching panel types when authUser is available
  useEffect(() => {
    if (authUser) {
      fetchPanelTypes();
    }
  }, [authUser]); // Dependency on authUser
  
  // ... rest of your code
  

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPanelType({ ...newPanelType, [name]: value });
  };

  // Handle form submit
  // Handle form submit
const handleFormSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  // Form validation
  if (!validateForm()) {
    setIsLoading(false);
    return;
  }

  // Ensure we have an authenticated user and token
  if (!authUser) {
    console.error('No authenticated user found');
    setIsLoading(false);
    return;
  }

  try {
    const token = await authUser.getIdToken();

    const panelData = {
      ...newPanelType,
      submitted_by: authUser.email // include the email from the authUser state
    };

    const response = await axios.post(panelTypeApiUri, panelData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 201 || response.status === 200) {
      setSuccessMessage(`Panel "${panelData.manufacturer}, ${panelData.name}" was successfully added to the library and is awaiting verification. You can add it to your project now.`);
      setShowSuccessModal(true);
      setNewPanelType({
        manufacturer: '',
        name: '',
        panel_width: '',
        panel_height: '',
        width_in_mm: '',
        height_in_mm: '',
        weight_in_kg: '',
        wattage: ''
      });
      fetchPanelTypes();
    }
  } catch (error) {
    console.error('Error adding new panel type:', error);
    // Handle error state here, like showing a message to the user
  } finally {
    setIsLoading(false);
  }
};

  // Assuming panelTypes is your state variable holding the array of panels
const sortedPanelTypes = panelTypes.sort((a, b) => {
  // First, compare by manufacturer
  const manufacturerCompare = a.manufacturer.localeCompare(b.manufacturer);
  if (manufacturerCompare !== 0) return manufacturerCompare;
  
  // If manufacturers are the same, then compare by panel name
  return a.name.localeCompare(b.name);
});

// Then use sortedPanelTypes for rendering the table rows


  if (!authUser) {
    return <div>You must be logged in and authorized to view this page.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <Row>
        <Col>

      
    
        <h4 className="mt-3 mb-3"><FontAwesomeIcon icon={faBook} />&nbsp;Panel Library</h4>
    <Row>
        <Col xxl="4">
        <Form onSubmit={handleFormSubmit} className = "mb-3">
        {/* Input fields for new panel type */}
       
        <InputGroup className="mb-3">
            <InputGroup.Text>Manufacturer</InputGroup.Text>
            <Form.Control
              type="text"
              name="manufacturer"
              value={newPanelType.manufacturer}
              placeholder="Manufacturer"
              onChange={handleInputChange}
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <InputGroup.Text>Model Name</InputGroup.Text>
            <Form.Control
              type='name'
              name='name'
              value={newPanelType.name}
              placeholder="Model Name"
              onChange={handleInputChange}
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <InputGroup.Text>Width in pixels</InputGroup.Text>
            <Form.Control
              type="number"
              name="panel_width"
              value={newPanelType.panel_width}
              min="1"
              placeholder="Width in Pixels"
              onChange={handleInputChange}
            />
            <InputGroup.Text>Height in pixels</InputGroup.Text>
            <Form.Control
              type="number"
              name="panel_height"
              value={newPanelType.panel_height}
              min="1"
              placeholder="Height in Pixels"
              onChange={handleInputChange}
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <InputGroup.Text>Width in mm</InputGroup.Text>
            <Form.Control
              type="number"
              name="width_in_mm"
              value={newPanelType.width_in_mm}
              min="1"
              placeholder="Width in mm"
              onChange={handleInputChange}
            />
            <InputGroup.Text>Height in mm</InputGroup.Text>
             <Form.Control
              type="number"
              name="height_in_mm"
              value={newPanelType.height_in_mm}
              min="1"
              placeholder="Height in mm"
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Weight in kg</InputGroup.Text>
            <Form.Control
              type="number"
              name="weight_in_kg"
              value={newPanelType.weight_in_kg}
              min="1"
              step="0.01"
              placeholder="Weight in kg"
              onChange={handleInputChange}
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <InputGroup.Text>Max Wattage</InputGroup.Text>
            <Form.Control
              type="number"
              name="wattage"
              value={newPanelType.wattage}
              min="1"
              placeholder="Wattage"
              onChange={handleInputChange}
            />
        </InputGroup>
        <Button variant="primary" type="submit">Add Panel Type</Button>
</Form>
<Card>
      <Card.Body>
        <Card.Title>Verification Status</Card.Title>
        <Card.Text>
          LED panels that have acheived verification status have specifications listed that have been confirmed by site admins to match those in-line with specifications avaialable from the manufacturer. For more information about getting panels verified - please contact site administrator. 
        </Card.Text>
      </Card.Body>
    </Card>

        </Col>
        <Col xxl="8">
            
      <Table striped bordered hover>
        <thead className="text-center">
          <tr>
            <th>Verified?</th>
            <th>Manufacturer</th>
            <th>Name</th>
            <th>Pixel Width</th>
            <th>Pixel Height</th>
            <th>Width (mm)</th>
            <th>Height (mm)</th>
            <th>Weight (kg)</th>
            <th>Wattage</th>
            {/* <th>Submitted By</th>
            <th>Created At</th> */}
          </tr>
        </thead>
        <tbody>
          {sortedPanelTypes.map((type, index) => (
            <tr key={index}>
              <td className="text-center">{type.verified ? <span style={{ color: 'green' }}>✓</span> : ''}</td>
              <td>{type.manufacturer}</td>
              <td>{type.name}</td>
              <td>{type.panel_width}</td>
              <td>{type.panel_height}</td>
              <td>{type.width_in_mm}</td>
              <td>{type.height_in_mm}</td>
              <td>{type.weight_in_kg}</td>
              <td>{type.wattage}</td>
              {/* <td>{type.submitted_by}</td> 
              <td>{type.created_at}</td>*/}
            </tr>
          ))}
        </tbody>
      </Table>
      </Col>
    </Row>

    <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Missing Fields</Modal.Title>
  </Modal.Header>
  <Modal.Body>{missingFieldsMessage}</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
<Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Success</Modal.Title>
  </Modal.Header>
  <Modal.Body>{successMessage}</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
</Col>
</Row>
    </Container>
  );
};

export default PanelTypeManager;

import React from "react";
import { Container, Row, Col, Button, Card, Carousel } from "react-bootstrap";
import screenshot from "../assets/blinkingthings-screenshot.png";
import panel_manager_screenshot from "../assets/bt_panel_manager.png";
import pixel_map_generator_screenshot from "../assets/bt_pixel_map_generator.png";
import scanline_screenshot from "../assets/bt_scanline_calculator.png";
import accurate_pixels from "../assets/led-wall-pixels-accurately-mapped-800px.png";
import { useModal } from "../contexts/ModalContext";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Landing = ({ handleShow, handleClose, setIsLogin }) => {
  const {
    handleCloseSignInModal,
    handleCloseSignUpModal,
    handleShowSignInModal,
    handleShowSignUpModal,
    showSignInModal,
    showSignUpModal,
  } = useModal();
  const { isEmailVerified } = useAuth();
  return (
    <>
      {isEmailVerified ? (
        <Navigate to="/pixel-map-generator"></Navigate>
      ) : (
        <Container fluid id="landing-container">
          <Row id="floating-bg">
            {/*  <img
                  id="grid"
                  alt="grid"
                  src={grid}
                /> */}
          </Row>
          <div id="everything-on-top">
            <Row className="justify-content-sm-center">
              <Col md="6" sm="8" className="text-white">
                <h1 className="display-1 my-5" style={{ fontWeight: "bold" }}>
                  Make every pixel count.
                </h1>
                <h4>
                  Stop wasting time with complicated graphics programs. Generate
                  accurate pixel maps in-browser that will save time & money,
                  and get the job done faster.
                </h4>
              </Col>
            </Row>
            <Row className="justify-content-sm-center my-4">
              <Col md="6" sm="8">
                <Button size="md" onClick={handleShowSignUpModal}>
                  Sign up for free
                </Button>
              </Col>
            </Row>

            <Row className="justify-content-sm-center">
              <Col md="6" sm="8">
                <img
                  id="landingScreenshot"
                  alt="screenshot of pixel mapulator tool"
                  src={screenshot}
                  className="my-3"
                />
              </Col>
            </Row>
            {/*<Row className="align-items-center">
            <Col id="splashLogin" className="bg-body" md={{ span: 3, offset: 9 }}>
                <span ><img height="100px" src={logo}></img> Pixel Mapulator</span>
                
                <h4>blinking things - Pixel Mapulator v3</h4>
                <br></br>
                <h5>Not a member yet?</h5>
                <Button className="mb-3" variant="primary" onClick={showSignUp}>Creat an account</Button>
                <hr></hr>
                <h5>Already have an account?</h5>
                <Button className="mb-3" variant="outline-secondary" onClick={showLogin}>Log In</Button>
            </Col>
              </Row>*/}
            <Row className="justify-content-sm-center my-5">
              <Col md="6" sm="8">
                <h1>
                  A suite of tools to help you plan and validate your LED screen
                  builds.
                </h1>
              </Col>
            </Row>
            {/* the features*/}
            <Row className="justify-content-sm-center my-5">
              <Col md="2"></Col>
              <Col md="3">
                <Card>
                  <Card.Body>
                    <Card.Title>Pixel Map Generator</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Instant Access
                    </Card.Subtitle>
                    <Card.Text>
                    We all know time is money - the blinking things pixel map generator is always available in-browser. Within seconds, you can have multiple screens and millions of pixels accurately laid out and exported for use across your entire video pipeline.
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      Panel Library
                    </Card.Subtitle>
                    <Card.Text>
                    You have access to a community-driven panel library full of the industry's most popular LED panels. If you don't have the manual, you can trust the pixel counts listed for the verified panels in our library.
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      Wiring Diagrams
                    </Card.Subtitle>
                    <Card.Text>
                    These aren't just raster maps! You can generate wiring diagrams to effectively communicate the correct wiring topology of your screen.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="3">
              <img
                      src={accurate_pixels}
                      alt="Accurate pixels"
                      style={{width:"100%"}}
                    />
                {/* carousel goes here */}
                {/* <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={accurate_pixels}
                      alt="First slide"
                    />
                     <Carousel.Caption>
                      <h5>Pixel Perfect Accuracy</h5>
                      <p>
                        Effectively communicate about the size and position of a screen within a raster for your whole team.
                      </p>
                    </Carousel.Caption> 
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={panel_manager_screenshot}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                      <h5>Second slide label</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={scanline_screenshot}
                      alt="Third slide"
                    />
                    <Carousel.Caption>
                      <h5>Third slide label</h5>
                      <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel> */}
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row className="repeat-cta mb-5">
              <Col className="d-flex justify-content-center">
              <Button className="btn-lg m-3" variant="outline-light" onClick={handleShowSignUpModal}>Sign Up For Free</Button>
              </Col>
            </Row>
            <Row className="justify-content-sm-center">
              <Col md="2">
                <Card>
                  <Card.Img
                    variant="top"
                    src={pixel_map_generator_screenshot}
                  />
                  <Card.Body>
                    <Card.Title>Pixel Map Generator</Card.Title>
                    <Card.Text>
                      Generate pixel perfect grids for multiple screens, across
                      multiple outputs.
                    </Card.Text>
                    <Button variant="primary" onClick={handleShowSignUpModal}>
                      Sign up
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2">
                <Card>
                  <Card.Img variant="top" src={scanline_screenshot} />
                  <Card.Body>
                    <Card.Title>Scanline Calculator</Card.Title>
                    <Card.Text>
                      Visualize potential scanline artifacts with variable
                      screen and camera settings.
                    </Card.Text>
                    <Button variant="primary" onClick={handleShowSignUpModal}>
                      Sign up
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2">
                <Card>
                  <Card.Img variant="top" src={panel_manager_screenshot} />
                  <Card.Body>
                    <Card.Title>LED Panel Library</Card.Title>
                    <Card.Text>
                      Access to community driven LED panel library containing
                      the most popular manufacturers and models.
                    </Card.Text>
                    <Button variant="primary" onClick={handleShowSignUpModal}>
                      Sign up
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default Landing;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Accordion,
  Nav
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios"; // Ensure axios is installed and imported
import { auth } from "../firebaseConfig";
import { useAuth } from "../contexts/AuthContext";
import SubscriptionStatus from "./SubscriptionStatus";


const userDataApiUri = process.env.REACT_APP_USER_DATA;
const userRequestDataDeletionApiUri =
  process.env.REACT_APP_REQUEST_DATA_DELETION;

const User = () => {
    const { currentUser  } = useAuth();
  const [userData, setUserData] = useState({
    privacyPolicyAgreement: [],
    panelTypes: [],
    userProjects: [],
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    
      auth.currentUser.getIdToken().then((idToken) => {
        axios
          .get(`${userDataApiUri}/${currentUser.email}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            setUserData(response.data);
          })
          .catch((error) => console.error(error));
      });
    
  }, [currentUser]);

  const handleDeleteRequest = () => {
    auth.currentUser.getIdToken().then((idToken) => {
      axios
        .post(
          userRequestDataDeletionApiUri,
          { email: currentUser.email },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then(() => {
          setShowDeleteModal(true);
        })
        .catch((error) => console.error(error));
    });
  };

  const renderPanelTypesTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Manufacturer</th>
            <th>Dimensions</th>
            <th>Weight</th>
            <th>Wattage</th>
            <th>Creation Date</th>
            <th>Submitted By</th>
          </tr>
        </thead>
        <tbody>
          {userData.panelTypes.map((panel, index) => (
            <tr key={index}>
              <td>{panel.name}</td>
              <td>{panel.manufacturer}</td>
              <td>{`${panel.width_in_mm}mm x ${panel.height_in_mm}mm`}</td>
              <td>{panel.weight_in_kg} kg</td>
              <td>{panel.wattage} W</td>
              <td>{new Date(panel.created_at).toLocaleDateString()}</td>
              <td>{panel.submitted_by}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderUserProjectsTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Creation Date</th>
            <th>User Email</th>
          </tr>
        </thead>
        <tbody>
          {userData.userProjects.map((project, index) => (
            <tr key={index}>
              <td>{project.project_json.projectName}</td>
              <td>{new Date(project.created_at).toLocaleDateString()}</td>
              <td>{project.user_email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      {
        <Container fluid>
          <Row className="justify-content-sm-center m-3">
            <Col md="6" sm="8">
              <h2>User Profile</h2>
              <p>Logged in as <span className="text-secondary">{currentUser.email}</span></p>
              {/* Display User Data */}
              <Accordion defaultActiveKey="4">
              <Accordion.Item eventKey="4">
                  <Accordion.Header>Subscription Status</Accordion.Header>
                  <Accordion.Body>
                    <SubscriptionStatus/>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                  <Accordion.Header variant="primary">Projects</Accordion.Header>
                  <Accordion.Body>
                  <h3>Projects</h3>
                {renderUserProjectsTable()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Panel Submissions</Accordion.Header>
                  <Accordion.Body>
                  <h3>Panel Submissions</h3>
                {renderPanelTypesTable()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Privacy Policy Agreement Status</Accordion.Header>
                  <Accordion.Body>
                  <h3>Privacy Agreement Status</h3>
                <p>
                  {userData.privacyPolicyAgreement.length > 0
                    ? <span className="text-success">Agreed</span>
                    : <span className="text-danger">Not Agreed</span>}
                </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Request Removal of User Data</Accordion.Header>
                  <Accordion.Body>
                  <h2> User Data</h2>
              <h3>Information Stored on Our Servers</h3>

              <p>
                The information displayed above is all the data we currently
                store on our servers associated with your email address, which
                is the only piece of Personally Identifiable Information (PII)
                we directly store. This includes:
              </p>
              <ul>
                <li>
                  Your agreement to our <Nav as={Link} to="/privacy-policy">Privacy Policy</Nav>
                </li>
                <li>Details of any panel types you have submitted.</li>
                <li>
                  Information about your projects created using our services.
                </li>
              </ul>
              <p>
                We are committed to maintaining the privacy and security of your
                data. Your trust is important to us, and we ensure that your
                data is handled responsibly. Feel free to review the{" "}
                <Nav as={Link} to="/privacy-policy">Privacy Policy</Nav> at any time.
              </p>
              <h3>Request Data Deletion</h3>
              <p>
                If you wish to have all information related to your email
                address removed from our servers, simply click the "Request Data
                Deletion" button below. Upon your request, your data will be
                queued for deletion from our system. Please note that this
                action is irreversible and will result in the permanent removal
                of your data from our servers.
              </p>
              <Button variant="danger" onClick={handleDeleteRequest}>
                Request Data Deletion
              </Button>
                  </Accordion.Body>
                </Accordion.Item>
               
              </Accordion>
             
              
            </Col>
          </Row>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Data Deletion Requested</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your request for data deletion has been submitted. Your data will
              be removed within 7 working days.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
     }
    </>
  );
};

export default User;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Controls from "./Controls";
import Visualizer from "./Visualizer";
import CompoundVisualizer from "./CompoundVisualizer";
import { useTheme } from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBezierCurve } from "@fortawesome/free-solid-svg-icons";

// Converts degrees to radians
const toRadians = (degrees) => degrees * (Math.PI / 180);

const CurvingCalculator = () => {
  const [panelWidth, setPanelWidth] = useState(10);
  const [chordAngle, setChordAngle] = useState(10);
  const [panelDepth, setPanelDepth] = useState(50); // Default depth in mm
  const [pivotOnOutside, setPivotOnOutside] = useState(false); // Default pivot
  const [screenWidth, setScreenWidth] = useState(31);

  const { theme, setTheme } = useTheme();


  const onParametersChange = (newWidth, newAngle, newDepth, newScreenWidth) => {
    setPanelWidth(parseFloat(newWidth));
    setChordAngle(parseFloat(newAngle));
    setPanelDepth(parseFloat(newDepth));
    setScreenWidth(parseInt(newScreenWidth));
  };

  const togglePivot = () => {
    setPivotOnOutside((prevState) => !prevState);
  };

  // State for calculated values
  const [panelCount, setPanelCount] = useState(0);
  const [radius, setRadius] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [circumference, setCircumference] = useState(0);

  useEffect(() => {
    // Calculate the values
    const totalAngle = 360 / chordAngle;
    const calculatedPanelCount = Math.round(totalAngle);
    const calculatedRadius =
      panelWidth / 2 / Math.tan(toRadians(chordAngle / 2));
    const calculatedDiameter = calculatedRadius * 2;
    const calculatedCircumference = Math.PI * calculatedDiameter;

    // Update state with calculated values
    setPanelCount(calculatedPanelCount);
    setRadius(calculatedRadius);
    setDiameter(calculatedDiameter);
    setCircumference(calculatedCircumference);
  }, [panelWidth, chordAngle]);



  // New state variable for mode toggle
  const [isCompoundCurveMode, setIsCompoundCurveMode] = useState(false);
  // Toggle switch handler
  const handleModeChange = (event) => {
    setIsCompoundCurveMode(event.target.checked);
  };

  const [curveDefinitions, setCurveDefinitions] = useState([
    { start: 1, end: 3, curve: 0 },
    { start: 4, end: 10, curve: 10 },
    { start: 11, end: 20, curve: 4 },
    { start: 21, end: 27, curve: 10 },
    { start: 28, end: 31, curve: 0 },
  ]);
  // State to track if the current definitions are valid
  const [isCurveValid, setIsCurveValid] = useState(true);

  const isRangeValid = (definitions) => {
    // Check each definition to ensure end is greater than start
    for (const definition of definitions) {
      if (definition.end <= definition.start) {
        return false; // End is not greater than start
      }
    }

    // Check for overlapping ranges
    for (let i = 0; i < definitions.length; i++) {
      for (let j = 0; j < definitions.length; j++) {
        if (i !== j) {
          const range1 = definitions[i];
          const range2 = definitions[j];
          // Check if ranges overlap
          if (range1.end >= range2.start && range1.start <= range2.end) {
            return false; // Found an overlap
          }
        }
      }
    }
    return true; // All ranges are valid
  };
  useEffect(() => {
    setIsCurveValid(isRangeValid(curveDefinitions));
  }, [curveDefinitions]);

  const addCurveDefinition = () => {
    setCurveDefinitions([
      ...curveDefinitions,
      { start: "", end: "", curve: "" },
    ]);
  };

  const removeCurveDefinition = (index) => {
    const newDefinitions = curveDefinitions.filter((_, i) => i !== index);
    setCurveDefinitions(newDefinitions);
  };

  const updateCurveDefinition = (index, field, value) => {
    setCurveDefinitions(
      curveDefinitions.map((definition, i) => {
        if (i === index) {
          return { ...definition, [field]: value };
        }
        return definition;
      })
    );
  };

  const [showBoundingBox, setShowBoundingBox] = useState(false);

  const toggleBoundingBox = () => setShowBoundingBox(!showBoundingBox);

  const [polygonArea, setPolygonArea] = useState(0);

  const handlePolygonAreaChange = (newArea) => {
    setPolygonArea(newArea);
  };

  const [longestDistance, setLongestDistance] = useState(0);

  const handleLongestDistanceChange = (newDistance) => {
    setLongestDistance(newDistance);
  };

  const [showWidestPoint, setShowWidestPoint] = useState(false);

  const toggleWidestPoint = () => setShowWidestPoint(!showWidestPoint);

  const [totalCurve, setTotalCurve] = useState(0);

  useEffect(() => {
    const sumOfAngles = curveDefinitions.reduce((sum, definition) => {
        const junctions = (definition.end - definition.start)+1;
        return sum + (junctions * definition.curve);
    }, 0);
    setTotalCurve(sumOfAngles);
}, [curveDefinitions]); // Recalculate when curveDefinitions change

  return (
    <Container fluid>
      <Row>
        <Col md={3} className="mt-3">
        <h4>
                <FontAwesomeIcon icon={faBezierCurve} /> Curving Calculator
              </h4>
          <Form>
            <Form.Check
              type="switch"
              id="mode-switch"
              label="Compound Curve Mode"
              checked={isCompoundCurveMode}
              onChange={handleModeChange}
            />
          </Form>
          <Controls
            onParametersChange={onParametersChange}
            togglePivot={togglePivot}
            pivotOnOutside={pivotOnOutside}
            panelCount={panelCount}
            radius={radius}
            diameter={diameter}
            circumference={circumference}
            isCompoundCurveMode={isCompoundCurveMode}
            curveDefinitions={curveDefinitions}
            addCurveDefinition={addCurveDefinition}
            removeCurveDefinition={removeCurveDefinition}
            updateCurveDefinition={updateCurveDefinition}
            isCurveValid={isCurveValid}
            toggleBoundingBox={toggleBoundingBox}
            boundingBoxVisible={showBoundingBox}
            polygonArea={polygonArea}
            longestDistance={longestDistance}
            toggleWidestPoint={toggleWidestPoint}
            widestPointVisible={showWidestPoint}
            totalCurve={totalCurve}
          />
        </Col>
        <Col md={9}>
          {isCompoundCurveMode ? (
            <CompoundVisualizer
              panelWidth={panelWidth}
              chordAngle={chordAngle}
              panelDepth={panelDepth}
              screenWidth={screenWidth}
              pivotOnOutside={pivotOnOutside}
              theme={theme}
              isCompoundCurveMode={isCompoundCurveMode}
              curveDefinitions={curveDefinitions}
              boundingBoxVisible={showBoundingBox}
              onPolygonAreaChange={handlePolygonAreaChange}
              onLongestDistanceChange={handleLongestDistanceChange}
              widestPointVisible={showWidestPoint}
            />
          ) : (
            <Visualizer
              panelWidth={panelWidth}
              chordAngle={chordAngle}
              panelDepth={panelDepth}
              pivotOnOutside={pivotOnOutside}
              theme={theme}
              isCompoundCurveMode={isCompoundCurveMode}
              curveDefinitions={curveDefinitions}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CurvingCalculator;

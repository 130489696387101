import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateImage } from '../components/utilities/PixelMapUtils'; // Import the utility functions
import { updateScreenData } from '../actions/index'; // Update this path


function ScreenView({isCanvasScaled}) {
  const dispatch = useDispatch();

  const activeScreenId = useSelector((state) => state.project.activeScreenId);
    const activeScreen = useSelector(state =>
        state.project.screens.find(screen => screen.id === activeScreenId)
    );
  //console.log("active screen id",activeScreenId)
  const canvasRef = useRef(null);





  useEffect(() => {
    if (!activeScreen) return;
    // Initial update with default or current input values
    updateImage(activeScreen, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //console.log("first effect");
  }, [activeScreen.mapWidth,
    activeScreen.mapHeight, 
    activeScreen.panelWidth, 
    activeScreen.panelHeight, 
    activeScreen.name, 
    activeScreen.colors, 
    activeScreen.opacity, 
    activeScreen.borders, 
    activeScreen.dataFlow, 
    activeScreen.coordinateStyle, 
    activeScreen.dataColor, 
    activeScreen.showCoordinates, 
    activeScreen.showPanelBorder, 
    activeScreen.showScreenBorder, 
    activeScreen.showDataFlow, 
    activeScreen.showScreenName, 
    activeScreen.showScalingCard, 
    activeScreen.showScreenSpecs, 
    activeScreen.showLogo, 
    activeScreen.portSize, 
    activeScreen.portStart, 
    activeScreen.logoOffsetX, 
    activeScreen.logoOffsetY, 
    activeScreen.coloringMode,
    activeScreen.halfRow]); // Dependency array left empty to mimic componentDidMount



  useEffect(() => {
    
    if (activeScreen && activeScreen.image) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
            canvas.width = activeScreen.mapWidth * activeScreen.panelWidth;
            canvas.height = (activeScreen.mapHeight * activeScreen.panelHeight) + (activeScreen.halfRow === "" ? 0 : activeScreen.panelHeight / 2);
            context.drawImage(image, 0, 0);
        };
        image.src = `data:image/png;base64,${activeScreen.image}`;
        //console.log("second effect");
      }
    
}, [activeScreen.image]); // Assuming activeScreen.image changes trigger re-render


  return (
    <div>
      
      {/* Render the canvas */}
      <canvas id="screenCanvas" ref={canvasRef} style={{ width: isCanvasScaled ? '100%' : 'auto' }}></canvas>
    </div>
  );
}

export default ScreenView;

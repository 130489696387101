import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Image, Card } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useSubscriber } from "../contexts/SubscriptionContext";
import multi_map_project from "../assets/multi-map-project-1000.png";

const Upgrade = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState("");
  const {currentUser } = useAuth();
  const { isSubscriber, subscriptionDetails } = useSubscriber(); // Use subscription context to check subscription status

  const monthlySubscriptionUrl = process.env.REACT_APP_STRIPE_MONTHLY_URL;
  const annualSubscriptionUrl = process.env.REACT_APP_STRIPE_ANNUAL_URL;
  const lifetimeSubscriptionUrl = process.env.REACT_APP_STRIPE_LIFETIME_URL;
  const manageSubscriptionLink = process.env.REACT_APP_STRIPE_MANAGE_SUBSCRIPTION_URL;

  const subscriptionOptions = [
    { id: "pro_monthly", name: "Pro Monthly", link: monthlySubscriptionUrl+currentUser.email, price: "$3.99/month", variant:"primary" },
    { id: "pro_annual", name: "Pro Annual", link: annualSubscriptionUrl+currentUser.email, price: "$29.99/year", variant:"success" },
    // { id: "pro_lifetime", name: "Pro Lifetime", link: lifetimeSubscriptionUrl+currentUser.email, price: "$299.99 one-time" },
  ];


  // Dummy email for demonstration
  // Replace this with the actual logic to get the user's email
  const userEmail = currentUser.email;

  const handleOpenModal = (link) => {
    setShowModal(true);
    setSelectedLink(link);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleContinue = () => {
    window.location.href = selectedLink; // Navigate to the selected Stripe link
  };
  
  // Assuming subscriptionDetails.planId matches the id in subscriptionOptions
  const currentPlanId = subscriptionDetails?.planId;

  return (
    <>
      <Container fluid>
        <Row className="justify-content-center mt-3">
          <Col md="6" sm="8">
            {!isSubscriber ? (
              <Row>
                <h3>Upgrade Your Subscription</h3>
                {subscriptionOptions.map((option) =>
                  option.id !== currentPlanId ? (
                    <Col className="justify-content-center">
                        <h4>{option.name}</h4>
                        {option.id === "pro_annual" ? (<p>Best deal for frequent users.</p>):(<p>Great for occasional use.</p>)}
                    <Button key={option.id} onClick={() => handleOpenModal(option.link)} variant={option.variant} className="m-2">
                      {option.name} - {option.price}
                    </Button>

                    </Col>
                  ) : null
                )}
              </Row>
            ) : (<>
              <p>You are currently subscribed.</p>
              <Button
          href={`${manageSubscriptionLink}${currentUser.email}`}
          target="_blank"
          variant="success"
        >
          Manage Subscription
        </Button>
        </>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6" sm="8">
            <h3>Features Unlocked with Subscription</h3>
            {/* Example feature card */}
            <Card className="mb-3">
                <Card.Img variant="top" src={multi_map_project}/>
              <Card.Body>
                <Card.Title>Multi-Map Functionality</Card.Title>
                <Card.Text>
                  Unlock the ability to create and manage multiple maps within a single project. Perfect for power users who deal with multi-screen multi-output projects.
                  <ul>
                    <li>Assign screens to outputs at will.</li>
                    <li>Offset screens within outputs</li>
                    <li>Robust project export options, including pixel perfect pngs, screen schedule spreadsheet, and text descriptions.</li>
                    <li>Export project files to share with colleagues.</li>
                  </ul>
                </Card.Text>
                
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Important Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please ensure you use the same email address ({userEmail}) to subscribe on Stripe to avoid any technical difficulties with your subscription status within our app.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            Continue to Stripe
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Upgrade;

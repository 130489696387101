import axios from 'axios';
import { setInputValue, setPixelMapImage } from '../../actions'; // Import your Redux actions
import { auth } from '../../firebaseConfig';


const mapApi = process.env.REACT_APP_MAP_API;

// Function to update the image based on current inputs
export const updateImage = async (screen, dispatch) => {
    const screenDataWithoutImage = { ...screen };
    delete screenDataWithoutImage.image;
    try {
        const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true); // Get the user's ID token
        const response = await axios.post(mapApi, screenDataWithoutImage, {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        // Assuming the backend response includes a property named 'image' with base64 data
        //console.log("receieved screen data from API, setting it to state now");
        dispatch(setPixelMapImage(screen.id, response.data.image));
    } catch (error) {
        console.error('Error generating the pixel map:', error);
    }
};

// Function to handle input changes
export const handleChange = async (e, inputs, dispatch) => {
    //console.log("handle change")
    const { name, value } = e.target;
    let parsedValue = value; // may be parsed below
    let updatedInputs = inputs; // will be updated below
    //for numeric inputs
    if (!isNaN(value) && (value !== '')) {
        // If the value is a valid number and not an empty string, convert it to a number
        parsedValue = parseInt(value); // Use parseFloat for floating-point numbers
      } 
    
    //for color inputs
    const matches = name.match(/colors\[(\d+)\]/); // Extract index from name
    if (matches) {
        //console.log("color input")
        const index = parseInt(matches[1], 10); // Parse the index as an integer
        if (!isNaN(index) && index >= 0 && index < inputs.colors.length) {
            // Check if index is valid
            const updatedColors = [...inputs.colors]; // Create a copy of the colors array
            updatedColors[index] = value; // Update the specific element
            dispatch(setInputValue('colors', updatedColors)); // Dispatch action to update the colors array
            updatedInputs = { ...inputs, 'colors': updatedColors };
        }
    } else {

        //normal dispatch and input update
        dispatch(setInputValue(name, parsedValue));
        updatedInputs = { ...inputs, [name]: parsedValue };

    }

    
    
    //console.log(updatedInputs);
    updateImage(updatedInputs, dispatch);
  };
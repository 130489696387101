import React from "react";

const PrivacyPolicyText = () => {
  return (
    <div>
      <h3>Privacy Policy for blinking things</h3>

      <p>Effective Date: 11/27/2023</p>
      <h4>Introduction</h4>

      <p>
        Welcome to blinking things. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our web
        application. We value your privacy and are committed to protecting your
        personal data.
      </p>

      <h4>Data Collection</h4>

      <p>1. Email Addresses</p>

      <p> We store your email addresses in a secure database.</p>

      <p>2. Passwords</p>

      <p>
        {" "}
        We do not directly store your passwords. User authentication is managed
        through Google Firebase. We strongly encourage users to choose strong,
        complex, secure passwords to protect their user account.
      </p>

      <p>3. Project Files</p>

      <p>
        {" "}
        Users may create and store project files on our platform. These files
        may contain sensitive information related to high-profile entertainment
        productions, such as films or concerts. We reserve the right to store
        this information on our servers and may choose to store and use it in
        the future for marketing and reasearch purposes.
      </p>

      <p>4. Panel Library</p>

      <p>
        {" "}
        Users may create and store LED panel profiles on our platform. These
        profiles will contain the email address of the user that submitted the
        profile. We reserve the right to store this information on our servers
        and may choose to store and use it in the future for marketing and
        reasearch purposes.
      </p>

      <p>5. Automatic Data Collection</p>

      <p>
        Our web application uses local storage in your browser as a part of its
        core functionality. Data related to your project file is stored in your
        browser so that your project can persist across time and multiple
        interrupted visits to the app.
      </p>

      <h4>Use of Data</h4>

      <p>We may use the collected data for the following purposes:</p>

      <p>The improvement of our services, marketing and research purposes.</p>
      <p>
        Your data will be stored on secured cloud servers and accessed
        exclusively by authorized personel.
      </p>
      {/* <h4>Payment Processing</h4>
<p>We use Stripe to process payments made through our web application. While Stripe handles all payment card information, we do not store any payment details related to Stripe transactions on our servers.</p>

 */}
      <h4>User Rights and Choices</h4>

      <p>
        You have the right to access or delete your personal data stored with
        us. Requests for access and/or deletion can be completed by following
        the link in your user profile, or by emailing info@blinkingthings.com.
      </p>
      <h4>Security Measures</h4>

      <p>
        We take the security of your data seriously and use a password protected
        database to protect it from unauthorized access. Access ot the database
        is restricted to authorized personnel only. In the unlikely event of a
        data breach, we will follow an established protocol to manage the
        situation and will notify affected users as required by law.
      </p>
      <h4>Changes to the Privacy Policy</h4>

      <p>
        We reserve the right to make changes to this Privacy Policy at any time.
        Any changes will be posted on this page with an updated revision date.
        We encourage you to review this Privacy Policy periodically to stay
        informed about how we are protecting your data.
      </p>
      <h4>Contact Information</h4>

      <p>
        If you have any questions or complaints about our Privacy Policy, please
        contact us at info@blinkingthings.com.
      </p>
      {/* <p>[Additional Sections as Necessary]</p>

<p>    Compliance with Laws: [Detail how your app complies with relevant data protection laws, such as GDPR or CCPA.]
    Data Retention Policy: [Specify the duration for which the data will be retained.]
    Third-Party Service Providers: [Mention if you use any third-party services and their compliance standards.]
    International Data Transfer: [Include information about cross-border data transfers, if applicable.]
    Age Restrictions: [Clearly state any age restrictions, especially if children could use your app.]</p> */}

      <p>
        By using blinking things, you signify your acceptance of this Privacy
        Policy. If you do not agree to this policy, please do not use our web
        application.
      </p>
    </div>
  );
};

export default PrivacyPolicyText;
